import React, { Component, Fragment } from "react";
import { Button, Upload } from "antd";
import papa from "papaparse";
import PropTypes from "prop-types";

class CsvToJsonUploader extends Component {
  state = {
    fileList: [],
    fileName: false
  };

  handleFileRead = (fileReader, name) => {
    const { onDataFetched, parserOptions, onError } = this.props;
    const content = fileReader.result;
    const csvData = papa.parse(
      content,
      Object.assign(parserOptions, {
        error: onError
      })
    );
    this.setState({ fileName: name });
    onDataFetched(csvData.data, name);
  };
  handleFileChoosen = file => {
    let fileReader = new FileReader();
    fileReader.onloadend = e => this.handleFileRead(fileReader, file.name);
    fileReader.readAsText(file);
  };
  onClearCSV = () => {
    this.setState({ fileName: false });
    this.props.onDataFetched(false, false);
  };

  handleChange = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    this.setState({ fileList });
    if (fileList.length > 0) {
      this.handleFileChoosen(fileList[0].originFileObj);
    }
  };
  render() {
    const { fileList } = this.state;
    const {
      buttonText,
      showUploadList,
      accept,
      loading,
      showFileName
    } = this.props;
    return (
      <div stlye={{ display: "flex" }}>
        <Upload
          customRequest={() => {}}
          onChange={this.handleChange}
          multiple={false}
          fileList={fileList}
          showUploadList={showUploadList}
          beforeUpload={false}
          supportServerRender={false}
          accept={accept}
        >
          <Button
            loading={loading}
            type="primary"
            icon="upload"
            size={"default"}
            onClick={this.handleUploadCSV}
            style={{ marginRight: 10 }}
          >
            {buttonText}
          </Button>
        </Upload>
        {showFileName && this.state.fileName}
        {showFileName && this.state.fileName && (
          <Button
            type="link"
            size="small"
            style={{ marginLeft: 10 }}
            onClick={this.onClearCSV}
          >
            X
          </Button>
        )}
      </div>
    );
  }
}

CsvToJsonUploader.propTypes = {
  buttonText: PropTypes.string,
  showUploadList: PropTypes.bool,
  parserOptions: PropTypes.object,
  loading: PropTypes.bool,
  showFileName: PropTypes.bool
};

CsvToJsonUploader.defaultProps = {
  buttonText: "Upload CSV",
  showUploadList: false,
  accept: ".csv,.xls,.xlsx",
  loading: false,
  showFileName: false,
  parserOptions: {}
};

export default CsvToJsonUploader;
