const validator = require("validator");
const isEmpty = require("./is-empty");

module.exports = data => {
	let errors = {};

	data.wardNumber = !isEmpty(data.wardNumber) ? data.wardNumber : "";
	// data.gharNumber = !isEmpty(data.gharNumber) ? data.gharNumber : null;
	data.sabikGabisa = !isEmpty(data.sabikGabisa) ? data.sabikGabisa : "";
	// data.sabikWard = !isEmpty(data.sabikWard) ? data.sabikWard : null;
	data.answererRelation = !isEmpty(data.answererRelation)
		? data.answererRelation
		: "";
	data.answererName = !isEmpty(data.answererName) ? data.answererName : "";
	data.familyMale = !isEmpty(data.familyMale) ? data.familyMale : null;
	data.familyFemale = !isEmpty(data.familyFemale) ? data.familyFemale : null;

	if (validator.isEmpty(data.wardNumber)) {
		errors.wardNumber = "Ward Number field is required";
	}

	if (isNaN(data.gharNumber)) {
		errors.gharNumber = "Ghar Number field is required";
	}

	if (validator.isEmpty(data.sabikGabisa)) {
		errors.sabikGabisa = "Sabik Gabisa field is required";
	}

	// if (!validator.isLength(data.password, { min: 4, max: 30 })) {
	// 	errors.password = "Password must be at least 4 characters";
	// }

	if (isNaN(data.sabikWard)) {
		errors.sabikWard = "Sabik Ward field is required";
	}

	// if (validator.isEmpty(data.password2)) {
	// 	errors.password2 = "Confirm Password field is required";
	// }

	if (validator.isEmpty(data.answererName)) {
		errors.answererName = "Answerer Name field is required";
	}
	if (validator.isEmpty(data.answererRelation)) {
		errors.answererRelation = "Answerer Relation field is required";
	}

	if (isNaN(data.familyMale)) {
		errors.familyMale = "Family Male field is required";
	}

	if (isNaN(data.familyFemale)) {
		errors.familyFemale = "Family Female field is required";
	}

	// if (!validator.isLength(data.firstname, { min: 6, max: 30 })) {
	// 	errors.firstname = "firstname must be at least 6 characters";
	// }
	// if (!validator.isLength(data.lastname, { min: 6, max: 30 })) {
	// 	errors.lastname = "lastname must be at least 6 characters";
	// }

	return {
		errors,
		isValid: isEmpty(errors)
	};
};
