import {
	GET_ANALYSIS,
	GET_COUNT,
	SET_ANALYSIS_LOADING,
	SET_PROGRESS_LOAD,
	GET_PROGRESS,
	GET_ANALYSIS_OVERALL,
	GET_SCHOOL_LEVEL,
	GET_SCHOOL_STUDENT
} from "../actions/types";

const initialState = {
	populationCount: [],
	institutionCount: {},
	chart: null,
	overall: [],
	progress: []
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ANALYSIS_LOADING:
			return {
				...state,
				loading: true
			};
		case SET_PROGRESS_LOAD:
			return {
				...state,
				progressLoad: true
			};
		case GET_COUNT:
			return {
				...state,
				populationCount: (action.payload && action.payload.population) || [],
				institutionCount: (action.payload && action.payload.institution) || {},
				loading: false
			};
		case GET_PROGRESS:
			return {
				...state,
				progress: action.payload,
				progressLoad: false
			};
		case GET_ANALYSIS:
			return {
				...state,
				chart: action.payload && action.payload,
				loading: false
			};
		case GET_ANALYSIS_OVERALL:
			return {
				...state,
				overall: action.payload && action.payload,
				loading: false
			};
		case GET_SCHOOL_LEVEL:
			return {
				...state,
				level: action.payload,
				loading: false
			};
		case GET_SCHOOL_STUDENT:
			return {
				...state,
				student: action.payload,
				loading: false
			};

		default:
			return state;
	}
}
