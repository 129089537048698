import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
// import { Typography } from "@material-ui/core";
import FullScreen from "@material-ui/icons/Fullscreen";
import randomMC from "random-material-color";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardHeader from "../../../../common/Card/CardHeader";
import CardIcon from "../../../../common/Card/CardIcon";

class ChartList extends Component {
  handleClickOpen = () => {
    this.props.handleClickOpen(this.props.name);
  };

  render() {
    const {
      classes,
      chartData,
      user,
      label,
      isFullScreen,
      noTitle
    } = this.props;
    return (
      <Card chart color="info">
        <CardHeader color="success" stats icon>
          <Grid
            style={{ marginBottom: 20 }}
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            {/* {!noTitle && ( */}
            {/* <Grid item> */}
            {/* <CardIcon color="success" className={classes.iconWidth}>
									{chartData &&
										chartData.title &&
										(label ? (
											<p style={{ fontSize: "1rem" }}>{label}</p>
										) : (
											<p style={{ fontSize: "1rem" }}>
												{` ${chartData.title}`}
											</p>
										))}
								</CardIcon> */}
            {/* </Grid> */}
            {/* )} */}
            {isFullScreen && (
              <Grid item>
                <FullScreen
                  onClick={this.handleClickOpen}
                  className={classes.fullIcon}
                />
              </Grid>
            )}
            {/* {this.props.match && (
							<Grid item className={classes.mt15}>
								<Link
									to={`${this.props.match.url}${chartData.route}`}
									className={classes.link}
								>
									<Button color="primary" className={classes.button}>
										विवरण हेर्नुहोस
									</Button>
								</Link>
							</Grid>
						)} */}
          </Grid>

          {chartData && (
            <Grid item xs="auto">
              <Bar
                type="bar"
                data={{
                  labels: chartData.map(each =>
                    each.user.map(users => users.name.first)
                  ),
                  datasets: [
                    {
                      label: "संख्या",
                      data: chartData.map(chart => chart.total),
                      backgroundColor: [
                        ...Array(chartData.length).keys()
                      ].map(() => randomMC.getColor()),
                      borderWidth: 2
                    }
                  ]
                }}
                width={chartData.length * 100}
                height={250}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: false
                  },
                  tooltips: {
                    callbacks: {
                      label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                      }
                    }
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          autoSkip: false,
                          beginAtZero: true
                        }
                      }
                    ],
                    xAxes: [
                      {
                        ticks: {
                          autoSkip: false,
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }}
              />
            </Grid>
          )}
        </CardHeader>
      </Card>
    );
  }
}

export default withStyles(dashboardStyle)(ChartList);
