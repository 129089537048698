import { Card, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import React from "react";
import {
	selectedLanguage,
	deathReasonLists,
	gaupalikaWard,
	genderList,
	ward
} from "../../../../variable//global";
import Can from "../../../casl/Can";
class SearchOption extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ward: parseInt(sessionStorage.getItem("ward")),
			role: parseInt(sessionStorage.getItem("role"))
		};
	}
	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.value);
	};

	handleSliderChange = (event, val) => {
		this.props.handleSliderChange(val);
	};

	mapReason = reason => {
		if (reason === "epidimics") return "माहामारी";
		if (reason === "tranferable") return "सर्नेरोग ( टीबी) जण्डिस) आदि)";
		if (reason === "aged") return "काल गति";
		if (reason === "aids") return "HIVAIDS";
		if (reason === "cancer") return "क्यान्सर";
		if (reason === "diabetes") return "मधुमेह";
		if (reason === "asthma") return "दम";
		if (reason === "blood_pressure") return "रक्तचाप";
		if (reason === "accident") return "दुर्घटना";
		if (reason === "suicide") return "आत्महत्या";
		if (reason === "death_other") return "अन्य";
	};
	render() {
		const { classes, wardNumber, gender, deathReason, ageRange } = this.props;
		const ITEM_HEIGHT = 48;
		const ITEM_PADDING_TOP = 8;
		const MenuProps = {
			PaperProps: {
				style: {
					maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
					width: 250
				}
			}
		};

		return (
			<div>
				<Card className={classes.card} fullwidth>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						<Can
							do="show"
							on={{
								__type: "Home",
								ward: this.state.ward
							}}
						>
							<Grid item xs={6} sm={3}>
								<Typography variant="h6">वडा नं</Typography>
								<FormControl className={classes.formControl}>
									<Select
										className={classes.ward}
										name="ward"
										value={wardNumber}
										onChange={this.handleChange}
									>
										<MenuItem value={0}>
											{gaupalikaWard[selectedLanguage]}
										</MenuItem>
										{ward.map(each => (
											<MenuItem key={each.key} value={each.key}>
												{each.ward}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Can>

						<Grid item xs={6} sm={3} container className={classes.flexColumn}>
							<Typography variant="h6" style={{ marginBottom: 10 }}>
								उमेर
							</Typography>
							<Grid
								style={{ padding: "0px 30px 0px 10px" }}
								item
								container
								direction="row"
								justify="space-between"
								alignItems="flex-start"
							>
								<Grid item>{ageRange[0]}</Grid>
								<Grid item>{ageRange[1]}</Grid>
							</Grid>

							<Grid item style={{ padding: "0px 30px 0px 10px" }}>
								<Slider
									min={0}
									max={120}
									color="#bf4040"
									defaultValue={ageRange}
									valueLabelDisplay="auto"
									aria-labelledby="range-slider"
									range
									onChange={this.handleSliderChange}
								/>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Typography variant="h6">लिंग</Typography>
							<FormControl className={classes.formControl}>
								<Select
									className={classes.ward}
									name="gender"
									value={gender}
									onChange={this.handleChange}
								>
									<MenuItem value={0}>सबै</MenuItem>
									{genderList.map(gender => (
										<MenuItem key={gender.key} value={gender.key}>
											{gender.value}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Typography variant="h6">मृत्यु को कारण</Typography>
							<Select
								multiple
								name="deathReason"
								value={deathReason}
								className={classes.ward}
								onChange={this.handleChange}
								input={<Input id="select-multiple-checkbox" />}
								renderValue={selected => (
									<div className={classes.chips}>
										{selected.map(value => (
											<Chip
												key={value}
												label={this.mapReason(value)}
												className={classes.chip}
											/>
										))}
									</div>
								)}
								MenuProps={MenuProps}
							>
								{deathReasonLists.map(({ name, value }) => (
									<MenuItem key={name} value={value}>
										<Checkbox checked={deathReason.indexOf(value) > -1} />
										<ListItemText primary={name} />
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
				</Card>
			</div>
		);
	}
}

const styles = theme => ({
	card: {
		display: "flex",
		padding: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	},
	ward: {
		width: "80%",
		marginTop: theme.spacing.unit * 2
	},
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing.unit,
		width: "80%"
		// minWidth: 120,
		// maxWidth: 300
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

export default withStyles(styles)(SearchOption);
