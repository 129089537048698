import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Table from "@material-ui/core/Table";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
export default class ScrollDialog extends React.Component {
	state = {
		families: []
	};
	componentDidMount() {}
	componentDidUpdate(prevprops) {
		if (this.props.open && this.props.args !== prevprops.args) {
			const { args, functions } = this.props;
			console.log("args", args, functions);
			axios
				.post("/api/analysis/generic/family", {
					function: functions,
					args
				})
				.then(res => {
					this.setState({ families: res.data });
				});
		}
	}
	render() {
		const { open, handleClose } = this.props;

		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					scroll="paper"
					aria-labelledby="scroll-dialog-title"
				>
					<DialogTitle id="scroll-dialog-title">Family Detail</DialogTitle>
					<DialogContent dividers={true}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>S.N</TableCell>
									<TableCell>fullName</TableCell>
									<TableCell>gender</TableCell>
									<TableCell>religion</TableCell>
									<TableCell>houseNumber</TableCell>
									<TableCell>toleName</TableCell>
									<TableCell>wardNumber</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{this.state.families.map((each, i) => (
									<TableRow>
										<TableCell>{i + 1}</TableCell>
										<TableCell>{each.fullName}</TableCell>
										<TableCell>{each.gender}</TableCell>
										<TableCell>{each.religion}</TableCell>
										<TableCell>{each.house.houseNumber}</TableCell>
										<TableCell>{each.house.toleName}</TableCell>
										<TableCell>{each.house.wardNumber}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
