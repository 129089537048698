import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { selectedLanguage } from "../../../../../variable/global";
import { Title } from "../../../../common/frequent/QuestionAnswer";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import {
	businessQ,
	businessType,
	businessTypeName,
	businessProfit,
	businessName,
	herbName,
} from "../../../../../variable/houseSurvey";

class SearchByHouseNumber extends Component {
	render() {
		const { classes, business } = this.props;
		return (
			<Fragment>
				{business &&
					(business.home || business.herb) &&
					(business.home.anyHomeBusiness || business.herb.anyHerbBusiness) && (
						<Grid
							item
							container
							direction="column"
							className={classes.container}
						>
							<Title title={businessQ} />

							<Grid item xs={12}>
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											<TableCell>{businessType[selectedLanguage]}</TableCell>
											<TableCell align="left">
												{businessTypeName[selectedLanguage]}
											</TableCell>
											<TableCell align="left">
												{businessProfit[selectedLanguage]}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{business.home.anyHomeBusiness === true && (
											<TableRow>
												<TableCell align="left">
													{businessName[selectedLanguage]}
												</TableCell>
												<TableCell align="left">
													{business.home.homeBusinessName.join(", ")}
												</TableCell>
												<TableCell align="left">
													{getNepalNumber(business.home.homeBusinessIncome)}
												</TableCell>
											</TableRow>
										)}
										{business.herb.anyHerbBusiness === true && (
											<TableRow>
												<TableCell align="left">
													{herbName[selectedLanguage]}
												</TableCell>
												<TableCell align="left">
													{business &&
														business.herb &&
														business.herb.homeBusinessName &&
														business.herb.homeBusinessName.join(", ")}
												</TableCell>
												<TableCell align="left">
													{business.herb &&
														business.herb.homeBusinessIncome &&
														getNepalNumber(business.herb.homeBusinessIncome)}
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Grid>
						</Grid>
					)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%"
	},
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
