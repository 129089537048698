import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { registerUser, editUser } from "../../../actions/authAction";
import { privilege, selectedLanguage } from "../../../variable/global";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import EditProfile from "./EditProfile";
import Axios from "axios";
class AddUser extends Component {
	state = {
		email: "",
		username: "",
		firstname: "",
		lastname: "",
		gender: "",
		errors: {},
		snack: false,
		snackVariant: "",
		snackMessage: ""
	};

	handleChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	setErrorNull = () => {
		this.setState({
			errors: {}
		});
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	getPrivilege = () => {
		return privilege.filter(each => each.value === this.state.privilege)[0][
			selectedLanguage
		];
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ snack: false });
	};

	componentDidMount() {
		let userId = this.props.user.id;
		Axios.get(`/api/users/user/${userId}`)
			.then(res => {
				this.setState({
					email: res.data.pri.user.email,
					username: res.data.pri.user.username,
					tempUsername: res.data.pri.user.username,
					firstname: res.data.pri.user.name.first,
					lastname: res.data.pri.user.name.last,
					gender: res.data.pri.user.gender
					// privilege: res.data.pri.role,
					// wardRole: res.data.pri.ward
				});
			})
			.catch(err => {});
	}
	// fetchUser = () => {};

	onSubmit = event => {
		let userId = this.props.user.id;
		event.preventDefault();
		const editUser = {
			id: userId,
			email: this.state.email,
			// password: this.state.password,
			firstname: this.state.firstname,
			username: this.state.username,
			lastname: this.state.lastname,
			gender: this.state.gender
			// privilege: this.state.privilege,
			// wardRole: this.state.wardRole
		};
		this.props.editUser(editUser).then(res => {
			if (res.type === "UPDATE_USER_SUCCESS") {
				this.props.history.push("/myprofile");
				this.setState({
					snack: true,
					snackVariant: "success",
					snackMessage: "प्रोफाइल अद्यावधिक भयो"
				});
			}
		});
	};
	render() {
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={8}>
					<EditProfile
						errors={this.props.errors}
						email={this.state.email}
						username={this.state.username}
						firstname={this.state.firstname}
						lastname={this.state.lastname}
						gender={this.state.gender}
						handleChange={this.handleChange}
						title={{
							english: "Edit Profile",
							nepali: "प्रयोगकर्ता सच्यानुहोस"
						}}
						onSubmit={this.onSubmit}
						handleClose={this.handleClose}
						snack={this.state.snack}
						snackVariant={this.state.snackVariant}
						snackMessage={this.state.snackMessage}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	userProfile: {
		fontSize: theme.spacing.unit * 12,
		margin: theme.spacing.unit
	},
	textSize: {
		display: "flex"
	}
});

AddUser.propTypes = {
	classes: PropTypes.object.isRequired,
	RegisterUser: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { errors, user } }) => ({ errors, user });

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ registerUser, editUser }
	)(withRouter(AddUser))
);
