import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import getNepaliNumber from "../../../../utils/getNepaliNumber";
import React, { Component, Fragment } from "react";
// import Table from "../../../common/Table/Table.jsx";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import GridContainer from "../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../common/Grid/GridItem.jsx";
import TablePaginationActionsWrapped from "../../search/death/TablePaginationActions";

class DetailAnalysisTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			rowsPerPage: 10,
			sn: ""
		};
		console.log("demo", this.props.chartData);
	}
	handleChangePage = (event, page) => {
		this.setState({ page });
	};
	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};

	mapLevel = level => {
		// const body = {
		// 	"kinder_garden": "बाल विकास कक्षा"
		// }
		// return body[level];
		if (level === "kinder_garden") return "बाल विकास कक्षा";
		if (level === "pre_primary") return "पूर्व प्राथमिक";
		if (level === "primary") return "आधारभूत तह";
		if (level === "secondary") return "माध्यमिक तह";
		if (level === "slc") return "प्राविधिक एस.एल.सी. (Overseer)";
		if (level === "bachelor") return "स्नातक तह";
	};

	render() {
		const { classes, title, tableHeader, chartData, total } = this.props;
		const { page, rowsPerPage } = this.state;

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card style={{ width: "auto" }}>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								{title}
							</Typography>
						</CardHeader>
						<CardBody>
							{/* Table here */}
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell component="th">क्र.स</TableCell>
										{tableHeader &&
											tableHeader.map(each => (
												<TableCell component="th">{each}</TableCell>
											))}
									</TableRow>
								</TableHead>

								<TableBody>
									{chartData && chartData ? (
										<Fragment>
											{chartData
												.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((data, id) => {
													console.log("chart data", this.props.fields);
													return (
														<TableRow key={id}>
															<TableCell
																scope="row"
																style={{ padding: "0px !important" }}
															>
																<Typography variant="body1">
																	{getNepaliNumber(
																		page * rowsPerPage + (id + 1)
																	)}
																</Typography>
															</TableCell>
															{this.props.fields.map(eachField => (
																<TableCell>
																	{eachField == "schoolLevel"
																		? this.mapLevel(data[eachField])
																		: eachField == "name"
																		? data[eachField]
																		: getNepaliNumber(data[eachField])}
																</TableCell>
															))}

															{/* {data &&
																data.map((eachData, i) => {
																	this.props.fields.map(eachFields => (
																		<TableCell>
																			{eachFields === "schoolLevel"
																				? this.mapLevel(eachData)
																				: getNepaliNumber(eachData)}
																		</TableCell>
																	));
																})} */}
														</TableRow>
													);
												})}
											{total && (
												<TableRow>
													<TableCell colspan={this.props.colSpan}>
														जम्मा
													</TableCell>

													{this.props.fields.map((eachField, i) =>
														i >= this.props.totalFields ? (
															<TableCell>
																{getNepaliNumber(total[eachField])}
															</TableCell>
														) : null
													)}
												</TableRow>
											)}
										</Fragment>
									) : (
										<TableRow>No data</TableRow>
									)}
								</TableBody>
								{chartData && (
									<TableFooter>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
												colSpan={7}
												count={chartData.length}
												rowsPerPage={rowsPerPage}
												page={page}
												SelectProps={{
													native: true
												}}
												onChangePage={this.handleChangePage}
												onChangeRowsPerPage={this.handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActionsWrapped}
											/>
										</TableRow>
									</TableFooter>
								)}
							</Table>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "auto",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		width: "1500"
	},
	tableWrapper: {
		overflowX: "auto"
	}
});

export default withStyles(styles)(DetailAnalysisTable);
