import React from "react";
import { GeoJSON, Marker,Popup } from "react-leaflet";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import L from "leaflet";
import { Grid } from "@material-ui/core/es";
import PopupHouse from "./PopupHouse";

import iconUrl from "../../../image/pin24.png";

var myIcon = L.icon({
	iconUrl,
	// iconSize: [25, 41],
	iconAnchor: [12, 24],
	// popupAnchor: [-10, -90]
});

export default function GeoJSONWithLayer(props) {
	const onHouseClick = houseNumber => event => {
		props.houseClick(houseNumber)
	}
	const handleOnEachFeature = (feature, layer) => {
		let popupContent = null;
		if (props.popupContent.length) {
			if (props.popupContent == 'ghar') {
				popupContent = `<img src={/api/imageUpload/image/${feature.properties.houseNumer}_house.jpg} alt={properties.houseNumer} width={200} height={200}/>`
				// popupContent = "<strong>House Number :</strong> "+feature.properties.houseNumber+"<br/><strong>Owner Name :</strong> "+feature.properties.ownerName;
				// popupContent = <PopupHouse properties = {feature.properties}/>
			} else {
				popupContent = props.popupContent;
			}
		} else if (feature.properties && feature.properties.popupContent) {
			popupContent = feature.properties.name;
		}

		if (props.type) layer.bindPopup(`${popupContent}`,{
			maxWidth: 700
		});
		else layer.bindPopup(`${popupContent}`,{
			maxWidth: 700
		});

		layer.on({
			mouseover: e => {
				layer.openPopup();
			},
			mouseout: e => {
				// layer.closePopup();
			}
		});
	};
	// console.log(JSON.stringify(props.data));
	if (props.data.features.findIndex(each => each.geometry.type === "Point") !== -1) {
		return props.data.features.map(eachFeature => {
			// euta euta points aaune eta bata ho;
			const properties = eachFeature.properties;
			const eachCoordinates = eachFeature.geometry.coordinates;
				return <Marker position={[eachCoordinates[1], eachCoordinates[0]]} icon={myIcon}>
					<Popup>
						<img src={`http://localhost:5000/api/imageUpload/image/${properties.houseNumber}_house.jpg`} alt={properties.houseNumer} width={300} height={200} style={{objectFit: 'cover'}}/><br/><br/>
								<b>House Number:</b> {properties.houseNumber}<br/>
								<b>Ward Number:</b> {properties.wardNumber}<br/>
								<b>Owner Name:</b> {properties.ownerName}<br/>
								<b>Tole Name:</b> {properties.toleName}<br/>
								{properties.contactNumber && <span><b>Contact:</b> {properties.contactNumber}</span>} <br/>
								<Button color="primary" onClick={onHouseClick(properties.houseNumber)}>View Detail</Button>
					</Popup>
				</Marker>
			
		})
	}

	return <GeoJSON {...props} onEachFeature={handleOnEachFeature} />;
}
