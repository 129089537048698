import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import { deleteUser } from "../../../actions/authAction";
import AlertDialog from "../../../common/frequent/dialog/AlertDialog";
// import Grid from "antd/lib/card/Grid";
import { Empty, Avatar } from "antd";
import Axios from "axios";
var randomMC = require("random-material-color");

// import PreviewModal from "../../common/frequent/dialog/PreviewModal";

class ListUser extends Component {
  state = {
    id: "",
    content: "",
    negButton: "",
    posButton: "",
    currentUserId: null,
    deleteDialog: false,
    open: false
  };

  removeUser = () => {
    const userId = this.state.currentUserId;

    Axios.delete(`/api/sms/group/delete/${userId}`)
      .then(res => {
        res.data.success && this.handleClose();
        this.props.fetchGroup();
      })
      .catch(err => {});
  };

  handleDeleteOpen = userId => event => {
    this.setState({ deleteDialog: true, currentUserId: userId });
  };

  handleClickOpen = () => {
    this.setState({ deleteDialog: true });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handlePreviewOpen = event => {
    this.setState({ open: true, id: event.target.id });
  };

  handlePreviewClose = () => {
    this.setState({ open: false, id: null });
  };
  getInitialLetter = name => {
    if (name)
      return name
        .split(" ")
        .map(each => each[0])
        .join("");
    else return "";
  };
  render() {
    const { classes, groups } = this.props;
    return (
      <Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>Role</TableCell> */}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <AlertDialog
            open={this.state.deleteDialog}
            handleClose={this.handleClose}
            negButtonClick={this.handleClose}
            posButtonClick={this.removeUser}
            title="Are you sure you want to delete the group ?"
            posButton="agree"
            negButton="disagree"
          />

          <TableBody>
            {groups &&
              groups.map((group, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {id + 1}
                  </TableCell>
                  <TableCell align="left">
                    <Avatar
                      style={{
                        backgroundColor: randomMC.getColor(),
                        verticalAlign: "middle"
                      }}
                      size="large"
                    >
                      {this.getInitialLetter(group.name)}
                    </Avatar>
                  </TableCell>
                  <TableCell align="left">{group.name}</TableCell>

                  <TableCell align="left">
                    {/* <VisibilityIcon
											id={group._id}
											onClick={this.handlePreviewOpen}
											className={classnames(classes.preview, classes.icon)}
										/> */}
                    <Link to={`/sms/group/edit/${group._id}`}>
                      <Icon
                        // onClick={this.handleEdit(user.user_id)}
                        className={classnames(classes.edit, classes.icon)}
                      />
                    </Link>
                    <DeleteIcon
                      onClick={this.handleDeleteOpen(group._id)}
                      className={classnames(classes.delete, classes.icon)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {groups.length === 0 && (
          <Grid
            style={{ marginTop: 20 }}
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
              <Empty />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  fab: {
    float: "right"
  },
  icon: {
    margin: theme.spacing.unit * 1,
    cursor: "pointer"
  },
  preview: {
    color: lightBlue[500]
  },
  edit: {
    color: green[700]
  },
  delete: {
    color: red[500]
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  }
});

export default withStyles(styles)(ListUser);
