import {
	GET_DEATHSEARCH,
	GET_HOUSE_DETAIL,
	SET_LOADING,
	GET_FAMILY_BY_PHONE,
	GET_MEMBER,
	HOUSE_LOADING,
	ADVANCE_SEARCH,
	ADVANCE_LOAD,
	CLEAR_DATA_ADVANCE
} from "../actions/types";

const initialState = {
	death: [],
	isHouse: null
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: true
			};
		case ADVANCE_LOAD:
			return {
				...state,
				advanceLoading: true
			};
		case CLEAR_DATA_ADVANCE:
			return {
				...state,
				data: []
			};

		case HOUSE_LOADING:
			return {
				...state,
				load: true
			};
		case GET_DEATHSEARCH:
			return {
				...state,
				loading: false,
				death: action.payload
			};
		case GET_MEMBER:
			return {
				...state,
				loading: false,
				member: action.payload
			};
		case GET_FAMILY_BY_PHONE:
			return {
				...state,
				loading: false,
				memberList: action.payload
			};

		case GET_HOUSE_DETAIL:
			return {
				...state,
				loading: false,
				load: false,
				house: action.payload,
				houseFound: action.payload.success
			};
		case ADVANCE_SEARCH:
			return {
				...state,
				loading: false,
				isHouse: action.isHouse,
				advanceLoading: false,
				data: action.payload
			};

		default:
			return state;
	}
}
