import React, { Component } from "react";
import PeopleProfile from "./PeopleProfile";
import Axios from "axios";
import isEmpty from "../.././../../utils/validation/is-empty";
export default class AddPeople extends Component {
  state = {
    errors: {},
    customDesignation: "",
    name: "",
    email: "",
    designation: "none",
    designations: [],
    gender: "",
    primaryPhone: 0,
    address: "",
    phone: [],
    maxPhoneIndex: 1
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  addPhone = () => {
    this.setState(prevState => ({
      maxPhoneIndex: prevState.maxPhoneIndex + 1
    }));
  };

  handlePhoneContact = index => event => {
    // arr.splice(2, 1, "Lene");
    // event.persist();

    const contact = event.target.value;
    this.setState(
      prevState => {
        const phone = [...prevState.phone];

        const data = {};
        data.contact = contact;
        data.phoneType = isEmpty(phone[index])
          ? "Phone"
          : phone[index].phoneType;
        phone.splice(index, 1, data);
        return { phone };
      },
      () => {}
    );
  };

  handlePhoneType = (index, value) => {
    this.setState(
      prevState => {
        const phone = [...prevState.phone];
        const data = {};
        data.phoneType = value;
        data.contact = isEmpty(phone[index]) ? "" : phone[index].contact;
        phone.splice(index, 1, data);
        return { phone };
      },
      () => {}
    );
  };

  handlePrimaryPhone = index => event => {
    this.setState({ primaryPhone: index });
  };

  removePhone = index => () => {
    this.setState(prevState => {
      const phone = [...prevState.phone];
      phone.splice(index, 1);

      if (prevState.maxPhoneIndex > 1)
        return {
          primaryPhone:
            prevState.primaryPhone == index
              ? prevState.primaryPhone - 1
              : prevState.primaryPhone,
          phone,
          maxPhoneIndex: prevState.maxPhoneIndex - 1
        };
      return null;
    });
  };

  onSubmit = values => {
    const { phone, maxPhoneIndex } = this.state;
    let errors = {};

    [...Array(maxPhoneIndex).keys()].map(each => {
      if (phone.length === 0) {
        errors[`contact${each}`] = "Please fill contact number";
        errors[`phoneType${each}`] = "Please fill contact number";
      }
    });
    if (!isEmpty(errors)) {
      this.setState({ errors });
    } else {
      const { primaryPhone } = this.state;
      let body = {};
      body.phone = phone;
      body.email = values.email;
      body.primaryPhone = phone[primaryPhone].contact;
      body.name = values.name;
      body.address = values.address;
      if (values.designation !== "none") body.designation = values.designation;
      body.gender = values.gender;
      // console.log("b", body);
      Axios.post("/api/sms/person/", body)
        .then(res => {
          res.data.success && this.props.history.push("/sms/people");
        })
        .catch(err => {});
    }
  };

  clearError = () => {
    this.setState({ errors: {} });
  };

  render() {
    return (
      <PeopleProfile
        title="Add People"
        clearError={this.clearError}
        errors={this.state.errors}
        primaryPhone={this.state.primaryPhone}
        maxPhoneIndex={this.state.maxPhoneIndex}
        addPhone={this.addPhone}
        handlePhoneContact={this.handlePhoneContact}
        handlePhoneType={this.handlePhoneType}
        handlePrimaryPhone={this.handlePrimaryPhone}
        removePhone={this.removePhone}
        custom={this.state.customDesignation}
        designations={this.state.designations}
        submitText="Save"
        onSubmit={this.onSubmit}
        handleChange={this.handleChange}
        name={this.state.name}
        email={this.state.email}
        designation={this.state.designation}
        gender={this.state.gender}
        address={this.state.address}
        phone={this.state.phone}
      />
    );
  }
}
