import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	getAnalaysisFamilyDetailChartData,
	getAnalaysisHouseReportDetailChartData
} from "../../../actions/analysisAction";
import { reportList } from "../../../variable/report";
import DetailHouseAnalysisTable from "./reportHouseTable";
import DetailAnalysisTable from "./reportTable";
import SearchOption from "./SearchOption";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
import { Fab } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wardData: 0,
			analysisList: [],
			chart: [],
			loading: false,
			beforePrint: false,
			component: null
		};
	}

	fetchData(ward, option, isLastElement) {
		let factor = reportList.find(each => each.value === option).title;
		let apiURL = factor === "family" ? "family-detail" : "report/detail";
		Axios.get(`/api/analysis/${apiURL}/${option}/${ward}`)
			.then(res => {
				let { chart, analysisList } = this.state;
				let appendData = { ...res.data, table: factor };
				chart.push(appendData);
				this.setState({ chart, loading: analysisList.length !== chart.length });
			})
			.catch(err => {
				console.error(err);
				// dispatch({
				// 	type: GET_ERRORS,
				// 	payload: err.response.data
				// });
			});
	}

	handleChange = (name, value) => {
		this.setState({ [name]: value });
	};

	clearData = () => this.setState({ chart: [], loading: true });

	fetchReport = () => {
		this.clearData();
		const { analysisList, wardData } = this.state;
		// const ward = wardData === 0 ? {} : { wardNumber: wardData };

		analysisList.map((analysis, i) => {
			this.fetchData(wardData, analysis, analysisList.length - 1 === i);
		});
	};

	handleAfterPrint = () => {
		this.setState({ beforePrint: false });
	};

	handleBeforePrint = () => {
		this.setState({ beforePrint: true });
	};

	getContent = () => {
		return this.setState(
			{ beforePrint: true, component: this.printRef },
			() => {
				return this.state.component;
			}
		);
	};

	render() {
		const { classes } = this.props;
		const { analysisList, wardData, loading, chart } = this.state;

		return (
			<div>
				<SearchOption
					analysisList={analysisList}
					wardData={wardData}
					handleChange={this.handleChange}
					onSubmit={this.fetchReport}
				/>

				{chart.length !== 0 && (
					<ReactToPrint
						style={{ marginTop: 200 }}
						onBeforePrint={this.handleBeforePrint}
						onAfterPrint={this.handleAfterPrint}
						trigger={() => (
							<Fab
								variant="extended"
								aria-label="Delete"
								className={classes.fab}
							>
								<PrintIcon className={classes.printIcon} />
							</Fab>
						)}
						data={this.state.component}
						content={this.getContent}
					/>
				)}
				<Grid container ref={el => (this.printRef = el)}>
					{chart.length !== 0 &&
						chart.map(each => (
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
							>
								<Grid item xs={8} className="print-break-after">
									{each.table === "family" ? (
										<DetailAnalysisTable
											beforePrint={this.state.beforePrint}
											subtitle=""
											data={each}
										/>
									) : (
										<DetailHouseAnalysisTable
											beforePrint={this.state.beforePrint}
											subtitle=""
											data={each}
											ward={this.state.wardData}
										/>
									)}
								</Grid>
							</Grid>
						))}
				</Grid>

				{loading && (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={true}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}
const styles = theme => ({
	card: {
		display: "flex",
		padding: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	},
	button: {
		margin: theme.spacing.unit
	},
	ward: {
		width: "80%",
		marginTop: theme.spacing.unit * 2
	},
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing.unit,
		width: "80%"
		// minWidth: 120,
		// maxWidth: 300
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

SearchOption.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ analysis: { loading, chart } }) => ({
	chart,
	loading
});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{
			getAnalaysisFamilyDetailChartData,
			getAnalaysisHouseReportDetailChartData
		}
	)(index)
);
