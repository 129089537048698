import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FullScreen from "@material-ui/icons/Fullscreen";
import randomMC from "random-material-color";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { gender, selectedLanguage } from "../../../../../variable/global";
// import { Typography } from "@material-ui/core";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardHeader from "../../../../common/Card/CardHeader";
import CardIcon from "../../../../common/Card/CardIcon";

class ChartList extends Component {
	handleClickOpen = () => {
		this.props.handleClickOpen(this.props.name);
	};
	render() {
		const { classes, male, female, third, isFullScreen, noTitle } = this.props;
		const maleColor = randomMC.getColor();
		const femaleColor = randomMC.getColor();
		const thirdColor = randomMC.getColor();
		return (
			<Card chart color="info">
				<CardHeader color="success" stats icon>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						{!noTitle && (
							<Grid item>
								<CardIcon color="success" className={classes.iconWidth}>
									{/* {male && male.title && <p>{`लिङ्ग अनुसार ${male.title}`}</p>} */}
									<p>लिङ्ग अनुसार</p>
								</CardIcon>
							</Grid>
						)}
						{isFullScreen && (
							<Grid item>
								<FullScreen
									onClick={this.handleClickOpen}
									className={classes.fullIcon}
								/>
							</Grid>
						)}
					</Grid>
					<Bar
						type="bar"
						data={{
							labels: male.map(each => each.label),
							datasets: [
								{
									label: gender[0][selectedLanguage],
									data: female.map(each => each.value),
									backgroundColor: [...Array(female.length).keys()].map(
										() => femaleColor
									),
									borderWidth: 2
								},
								{
									label: gender[1][selectedLanguage],
									data: male.map(each => each.value),
									backgroundColor: [...Array(male.length).keys()].map(
										() => maleColor
									),
									borderWidth: 2
								},
								{
									label: gender[2][selectedLanguage],
									data: third.map(each => each.value),
									backgroundColor: [...Array(third.length).keys()].map(
										() => thirdColor
									),
									borderWidth: 2
								}
							]
						}}
						options={{
							scales: {
								yAxes: [
									{
										ticks: {
											autoSkip: false,
											beginAtZero: true
										}
									}
								],
								xAxes: [
									{
										ticks: {
											autoSkip: false,
											beginAtZero: true
										}
									}
								]
							}
						}}
					/>
				</CardHeader>
			</Card>
		);
	}
}
ChartList.defaultProps = {
	isFullScreen: false,
	noTitle: false
};
export default withStyles(dashboardStyle)(ChartList);
