import { Card, Typography, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	gaupalikaWard,
	selectedLanguage,
	ward
} from "../../../variable/global";
import { reportList } from "../../../variable/report";
import MultipleSelect from "../../common/frequent/MultipleSelect";

class SearchOption extends Component {
	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.value);
	};

	render() {
		const { classes, wardData, analysisList } = this.props;
		return (
			<div>
				<Card className={classes.card} fullwidth>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						<Grid item xs={6} sm={3}>
							<Typography variant="h6">वडा नं</Typography>
							<FormControl className={classes.formControl}>
								<Select
									className={classes.ward}
									name="wardData"
									value={wardData}
									onChange={this.handleChange}
								>
									<MenuItem value={0}>
										{gaupalikaWard[selectedLanguage]}
									</MenuItem>
									{ward.map(each => (
										<MenuItem key={each.key} value={each.key}>
											{each.ward}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Typography variant="h6">रिपोर्ट</Typography>
							<FormControl className={classes.formControl}>
								{/* <Select
									className={classes.ward}
									name="analysisList"
									value={analysisList}
									onChange={this.handleChange}
								>
									{reportList.map(each => (
										<MenuItem key={each.key} value={each.value}>
											{each.nepali}
										</MenuItem>
									))}
								</Select> */}
								<MultipleSelect
									className={classes.ward}
									name="analysisList"
									value={analysisList}
									tagLists={reportList}
									isVisible={true}
									handleChange={this.props.handleChange}
								/>
							</FormControl>
						</Grid>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							type="submit"
							onClick={this.props.onSubmit}
						>
							Submit
						</Button>
					</Grid>
				</Card>
			</div>
		);
	}
}

const styles = theme => ({
	card: {
		display: "flex",
		padding: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2
	},
	button: {
		margin: theme.spacing.unit
	},
	ward: {
		width: "80%",
		marginTop: theme.spacing.unit * 2
	},
	root: {
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing.unit,
		width: "100%"
		// minWidth: 120,
		// maxWidth: 300
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

SearchOption.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withStyles(styles)(SearchOption);
