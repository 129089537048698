import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import PropTypes from "prop-types";
import React from "react";
import EachMember from "../../../../common/frequent/FamilyMember";
import { Title } from "../../../../common/frequent/QuestionAnswer";
import "./antdTabs.css";

const TabPane = Tabs.TabPane;

function mapValue(value, obj) {
	return obj[value];
}

class FamilyMember extends React.Component {
	state = {
		member: [],
		active: 1
	};

	getTabValue = (name, relation, isActive) => {
		return (
			<div>
				<Typography
					variant="body1"
					className={isActive ? this.props.classes.activeTab : ""}
				>
					{name}
				</Typography>
				{relation && (
					<Typography
						variant="caption"
						className={isActive ? this.props.classes.activeTab : ""}
					>
						(
						{mapValue(relation, {
							self_house_own: "घरमुली",
							husband: "श्रीमान",
							wife: "श्रीमती",
							mother: "आमा",
							father: "बुवा",
							son: "छोरा",
							daughter: "छोरी",
							buhari: "बुहारी",
							jwai: "ज्वाई",
							sashu: "सासु",
							sasura: "ससुरा",
							kaka: "काका",
							kaki: "काकी",
							fupu: "फुपू",
							fupaju: "फुपाजु",
							mama: "ममा",
							maiju: "माइजु",
							nati: "नाती",
							natini: "नातिनी",
							daju: "दाजु",
							bhai: "भाई",
							didi: "दिदि",
							bahini: "बहिनि",
							bhauju: "भाउजु",
							bhai_buhari: "भाई बुहारी",
							jethan: "जेठान",
							thulo_bbuwa: "ठुलो बुवा",
							thulo_aama: "ठुलो आमा",
							grand_father: "हजुर बुवा",
							grand_mother: "हजुर आमा"
						})}
						)
					</Typography>
				)}
			</div>
		);
	};

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.member) {
			const order = [
				"self_house_own",
				"husband",
				"wife",
				"mother",
				"father",
				"son",
				"daughter",
				"buhari",
				"jwai",
				"sashu",
				"sasura",
				"kaka",
				"kaki",
				"fupu",
				"fupaju",
				"mama",
				"maiju",
				"nati",
				"natini",
				"daju",
				"bhai",
				"didi",
				"bahini",
				"bhauju",
				"bhai_buhari",
				"jethan",
				"thulo_bbuwa",
				"thulo_aama",
				"grand_father",
				"grand_mother "
			];
			var member = nextProps.member;
			member.sort(
				(a, b) =>
					order.indexOf((a.detail && a.detail.answererRelation) || "") -
					order.indexOf((b.detail && b.detail.answererRelation) || "")
			);
			// console.log(order, member,);

			return {
				member
			};
		}
		return null;
	}
	onTabChange = active => {
		this.setState({ active });
	};
	render() {
		const { classes } = this.props;
		const { member } = this.state;
		const familyMember = { nepali: "परिवार सदस्य", english: "Family Member" };
		console.log(member);
		return (
			<Grid container direction="column" className={classes.container}>
				<Grid item container>
					<Title title={familyMember} />
				</Grid>

				<Grid item container>
					<Tabs
						defaultActiveKey="1"
						tabPosition={"left"}
						style={{ display: "flex", width: "100%" }}
						onChange={this.onTabChange}
					>
						{member.map((each, key) => (
							<TabPane
								tab={this.getTabValue(
									each.detail.fullName,
									each.detail.answererRelation,
									Boolean(this.state.active == key + 1)
								)}
								key={key + 1}
							>
								<Grid item container>
									<EachMember member={each} />
								</Grid>
							</TabPane>
						))}
					</Tabs>
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	container: {
		// paddingLeft: theme.spacing.unit * 5,
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	eachQnAns: {
		// marginTop: theme.spacing.unit * 1
	},
	activeTab: {
		color: "#1890ff"
	}
});

FamilyMember.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FamilyMember);
