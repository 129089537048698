import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updatePassword } from "../../../actions/authAction";
import TextInput from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
	Grid,
	IconButton,
	InputAdornment,
	Typography
} from "@material-ui/core";
import {
	passwordChangeHeader,
	privilege,
	selectedLanguage,
	oldPassword,
	currentPassword
} from "../../../variable/global";
import { add } from "../../../variable/institute";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardFooter from "../../common/Card/CardFooter.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import Button from "../../common/CustomButtons/Button.jsx";
import Axios from "axios";
class ChangePassword extends Component {
	state = {
		currentPassword: "",
		showPassword: false,
		showCurrentPassword: false,
		newPassword: "",
		errors: {}
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	setErrorNull = () => {
		this.setState({
			errors: {}
		});
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	handleClickShowCurrentPassword = () => {
		this.setState(state => ({
			showCurrentPassword: !state.showCurrentPassword
		}));
	};

	getPrivilege = () => {
		return privilege.filter(each => each.value === this.state.privilege)[0][
			selectedLanguage
		];
	};

	onSubmit = event => {
		event.preventDefault();
		const newUser = {
			currentPassword: this.state.currentPassword,
			newPassword: this.state.newPassword
		};
		this.props.updatePassword(newUser);
	};
	render() {
		const { classes, isPasswordVisible } = this.props;
		const { errors } = this.state;
		return (
			<Card>
				<CardHeader color="primary">
					<Typography variant="h5" className={classes.cardTitleWhite}>
						{passwordChangeHeader[selectedLanguage]}
					</Typography>
					{/* <Typography variant="h6" className={classes.cardCategoryWhite}>
						{passwordChangeHeader[selectedLanguage]}
					</Typography> */}
				</CardHeader>
				<CardBody>
					<form className={classes.form}>
						<Grid container spacing={24}>
							<Grid item xs={4}>
								<TextInput
									id="passwordS"
									label={oldPassword[selectedLanguage]}
									onFocusCapture={this.setErrorNull}
									type={this.state.showCurrentPassword ? "text" : "password"}
									name="currentPassword"
									autoComplete="current-password"
									margin="normal"
									variant="outlined"
									disabled={false}
									error={errors.password}
									className={classes.textField}
									// error={this.props.errors.password}
									// helperText={this.props.errors.password}
									value={this.state.currentPassword}
									onChange={this.handleChange}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowCurrentPassword}
												>
													{this.state.showCurrentPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextInput
									id="password"
									label={currentPassword[selectedLanguage]}
									onFocusCapture={this.setErrorNull}
									type={this.state.showPassword ? "text" : "password"}
									name="newPassword"
									autoComplete="current-password"
									margin="normal"
									variant="outlined"
									disabled={false}
									error={errors.password}
									className={classes.textField}
									// error={this.props.errors.password}
									// helperText={this.props.errors.password}
									value={this.state.newPassword}
									onChange={this.handleChange}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={this.handleClickShowPassword}
												>
													{this.state.showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
					</form>
				</CardBody>
				<CardFooter>
					<Button
						color="primary"
						style={{ fontSize: "1rem" }}
						onClick={this.onSubmit}
					>
						{add[selectedLanguage]}
					</Button>
				</CardFooter>
			</Card>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	textSize: {
		display: "flex"
	}
});

ChangePassword.defaultProps = {
	isPasswordVisible: true
};

ChangePassword.propTypes = {
	classes: PropTypes.object.isRequired,
	RegisterUser: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth: { errors } }) => ({ errors });

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ updatePassword }
	)(withRouter(ChangePassword))
);
