const validator = require("validator");
const isEmpty = require("./is-empty");

const primaryHouse = data => {
	let errors = {};

	data.wardNumber = !isEmpty(data.wardNumber) ? data.wardNumber : "";
	// data.gharNumber = !isEmpty(data.gharNumber) ? data.gharNumber : null;
	data.sabikGabisa = !isEmpty(data.sabikGabisa) ? data.sabikGabisa : "";
	// data.sabikWard = !isEmpty(data.sabikWard) ? data.sabikWard : null;
	data.answererRelation = !isEmpty(data.answererRelation)
		? data.answererRelation
		: "";
	data.answererName = !isEmpty(data.answererName) ? data.answererName : "";

	if (validator.isEmpty(data.wardNumber)) {
		errors.wardNumber = "Ward Number field is required";
	}

	if (isNaN(data.gharNumber)) {
		errors.gharNumber = "Ghar Number field is required";
	}

	if (validator.isEmpty(data.sabikGabisa)) {
		errors.sabikGabisa = "Sabik Gabisa field is required";
	}

	// if (!validator.isLength(data.password, { min: 4, max: 30 })) {
	// 	errors.password = "Password must be at least 4 characters";
	// }

	if (isNaN(data.sabikWard)) {
		errors.sabikWard = "Sabik Ward field is required";
	}

	// if (validator.isEmpty(data.password2)) {
	// 	errors.password2 = "Confirm Password field is required";
	// }

	if (validator.isEmpty(data.answererName)) {
		errors.answererName = "Answerer Name field is required";
	}
	console.log("answerRelation", data.answererRelation);
	if (validator.isEmpty(data.answererRelation)) {
		errors.answererRelation = "Answerer Relation field is required";
	}
	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const familyDetail = data => {
	let errors = {};

	if (isEmpty(data.totalMember) || data.totalMember < 1) {
		errors.totalMember = "Must be greater than 1";

		if (isNaN(data.familyMale)) {
			errors.familyMale = "Family Male field is required";
		}
		if (isNaN(data.familyFemale)) {
			errors.familyFemale = "Family female field is required";
		}
	} else {
		errors.family = [];
		Array.from(Array(parseInt(data.totalMember)).keys()).map(eachCount => {
			data[`fullName${eachCount}`] = !isEmpty(data[`fullName${eachCount}`])
				? data[`fullName${eachCount}`]
				: "";
			data[`answererRelation${eachCount}`] = !isEmpty(
				data[`answererRelation${eachCount}`]
			)
				? data[`answererRelation${eachCount}`]
				: "";

			data[`answererName${eachCount}`] = !isEmpty(
				data[`answererName${eachCount}`]
			)
				? data[`answererName${eachCount}`]
				: "";
			data[`gender${eachCount}`] = !isEmpty(data[`gender${eachCount}`])
				? data[`gender${eachCount}`]
				: "";
			data[`dob${eachCount}`] = !isEmpty(data[`dob${eachCount}`])
				? data[`dob${eachCount}`]
				: null;
			// data[`age${eachCount}`] = !isEmpty(data[`age${eachCount}`]) ? data[`age${eachCount}`] : null;
			data[`abroadReason${eachCount}`] = !isEmpty(
				data[`abroadReason${eachCount}`]
			)
				? data[`abroadReason${eachCount}`]
				: "";
			data[`abroadReasonOther${eachCount}`] = !isEmpty(
				data[`abroadReasonOther${eachCount}`]
			)
				? data[`abroadReasonOther${eachCount}`]
				: "";
			data[`abroadCountry${eachCount}`] = !isEmpty(
				data[`abroadCountry${eachCount}`]
			)
				? data[`abroadCountry${eachCount}`]
				: "";
			data[`abroadCountryOther${eachCount}`] = !isEmpty(
				data[`abroadCountryOther${eachCount}`]
			)
				? data[`abroadCountryOther${eachCount}`]
				: "";
			data[`casteList${eachCount}`] = !isEmpty(data[`casteList${eachCount}`])
				? data[`casteList${eachCount}`]
				: "";
			data[`education${eachCount}`] = !isEmpty(data[`education${eachCount}`])
				? data[`education${eachCount}`]
				: "";
			data[`occupation${eachCount}`] = !isEmpty(data[`occupation${eachCount}`])
				? data[`occupation${eachCount}`]
				: "";
			const temp = {};
			if (validator.isEmpty(data[`fullName${eachCount}`])) {
				temp.fullName = "FullName field is required";
			}

			if (validator.isEmpty(data[`answererRelation${eachCount}`])) {
				temp.answererRelation = "AnswererRelation is required";
			}

			if (validator.isEmpty(data[`gender${eachCount}`])) {
				temp.gender = "Sabik Gabisa field is required";
			}

			// if (validator.isEmpty(data[`dob${eachCount}`])) {
			// 	temp.dob = {
			// 		nepali: "",
			// 		english: "Sabik Ward field is required"
			// 	};
			// }

			if (validator.isEmpty(data[`answererName${eachCount}`])) {
				temp.answererName = "Answerer Name field is required";
			}
			// if (isNaN(data[`age${eachCount}`])) {
			// 	temp.age = {
			// 		nepali: "",
			// 		english: "Age field is required"
			// 	};
			// }

			if (
				data[`abroadReason${eachCount}`] === "other" &&
				validator.isEmpty(data[`abroadReasonOther${eachCount}`])
			) {
				temp.abroadReasonOther = "Abroad Reasson field is required";
			}

			if (
				data[`abroadCountry${eachCount}`] === "other" &&
				validator.isEmpty(data[`abroadCountryOther${eachCount}`])
			) {
				temp.abroadCountryOther = "Abroad Country Other field is required";
			}

			if (validator.isEmpty(data[`casteList${eachCount}`])) {
				temp.casteList = "Caste field is required";
			}

			if (
				data[`casteList${eachCount}`] === "other" &&
				validator.isEmpty(data[`casteListOther${eachCount}`])
			) {
				temp.casteListOther = "Caste Other field is required";
			}

			if (validator.isEmpty(data[`education${eachCount}`])) {
				temp.education = "Education field is required";
			}

			if (validator.isEmpty(data[`occupation${eachCount}`])) {
				temp.occupation = "Occupation field is required";
			}

			if (
				data[`occupation${eachCount}`] === "other" &&
				validator.isEmpty(data[`occupationOther${eachCount}`])
			) {
				temp.occupationOther = "Occupation Other field is required";
			}

			errors.family.isOk = isEmpty(temp);

			errors.family.push(temp);
		});
	}
	console.log("errors FD", errors);
	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const secondaryHouse = data => {
	let errors = {};

	data.waterSource = !isEmpty(data.waterSource) ? data.waterSource : "";
	data.cookingSource = !isEmpty(data.cookingSource) ? data.cookingSource : "";
	data.electricSource = !isEmpty(data.electricSource)
		? data.electricSource
		: "";

	if (validator.isEmpty(data.waterSource)) {
		errors.waterSource = "WaterSource field is required";
	}

	if (
		data.waterSource === "other" &&
		validator.isEmpty(data.waterSourceOther)
	) {
		errors.waterSourceOther = "WaterSource Other field is required";
	}

	if (validator.isEmpty(data.cookingSource)) {
		errors.cookingSource = "CookingSource is required";
	}

	if (
		data.cookingSource === "other" &&
		validator.isEmpty(data.cookingSourceOther)
	) {
		errors.cookingSourceOther = "CookingSource Other field is required";
	}

	if (validator.isEmpty(data.electricSource)) {
		errors.electricSource = "ElectricSource field is required";
	}

	if (
		data.electricSource === "other" &&
		validator.isEmpty(data.electricSourceOther)
	) {
		errors.electricSourceOther = "ElectricSource Other field is required";
	}

	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const houseDetail = data => {
	let errors = {};
	// console.log("called")

	// data.houseCount = !isEmpty(data.houseCount) ? data.houseCount : "";
	data.familyMemberAnotherHouse = !isEmpty(data.familyMemberAnotherHouse)
		? data.familyMemberAnotherHouse
		: "";
	// data.totalRoom = !isEmpty(data.totalRoom) ? data.totalRoom : null;
	// data.totalStorey = !isEmpty(data.totalStorey) ? data.totalStorey : null;
	data.rentType = !isEmpty(data.rentType) ? data.rentType : [];
	data.storey = !isEmpty(data.storey) ? data.storey : null;
	data.room = !isEmpty(data.room) ? data.room : null;
	data.matanCount = !isEmpty(data.matanCount) ? data.matanCount : null;

	if (
		data.building &&
		data.building.includes("ghar") &&
		isNaN(data.houseCount)
	) {
		errors.houseCount = "HouseCount field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		isNaN(data.totalRoom)
	) {
		errors.totalRoom = "TotalRoom field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		isNaN(data.totalStorey)
	) {
		errors.totalStorey = "TotalStorey field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.familyMemberAnotherHouse === "house_yes" &&
		validator.isEmpty(data.familyMemberAnotherHouse)
	) {
		errors.familyMemberAnotherHouse = "familyMemberAnotherHouse is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.isHouseOnRent === "rent" &&
		validator.isEmpty(data.rentType)
	) {
		errors.rentType = "RentType field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.isHouseOnRent === "rent" &&
		data.rentType === "other" &&
		validator.isEmpty(data.rentType)
	) {
		errors.rentType = "RentType other field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.isHouseOnRent === "rent" &&
		data.rentDetail === "storey" &&
		validator.isEmpty(data.storey)
	) {
		errors.storey = "Storey field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.isHouseOnRent === "rent" &&
		data.rentDetail === "room" &&
		validator.isEmpty(data.room)
	) {
		errors.room = "Room field is required";
	}

	if (
		data.building &&
		data.building.includes("ghar") &&
		data.isHouseOnRent === "rent" &&
		data.rentDetail === "both" &&
		validator.isEmpty(data.room) &&
		validator.isEmpty(data.storey)
	) {
		errors.room = "Room field is required";
		errors.storey = "Storey field is required";
	}

	if (
		data.landUsedLiving === "other" &&
		validator.isEmpty(data.landUsedLivingOther)
	) {
		errors.landUsedLivingOther = "Other field is required";
	}

	if (data.houseType === "other" && validator.isEmpty(data.houseTypeOther)) {
		errors.houseTypeOther = "Other field is required";
	}

	if (data.roofType === "other" && validator.isEmpty(data.roofTypeOther)) {
		errors.roofTypeOther = "Other field is required";
	}

	if (data.building === "matan" && validator.isEmpty(data.matanCount)) {
		errors.matanCount = "Matan Count  field is required";
	}

	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const economics = data => {
	let errors = {};

	// data.mainIncomeSource = !isEmpty(data.mainIncomeSource) ? data.mainIncomeSource : "";
	data.foodForMonth = !isEmpty(data.foodForMonth) ? data.foodAlternate : [];
	data.familyInvolvementLocalOrgan = !isEmpty(data.familyInvolvementLocalOrgan)
		? data.familyLocalGovInvolve
		: [];

	if (
		data.mainIncomeSource === "other" &&
		validator.isEmpty(data.mainIncomeSourceOther)
	) {
		errors.mainIncomeSourceOther = "Other field is required";
	}

	if (
		data.foodForMonth === "3_month" ||
		"4_6_month" ||
		("7_9_month" && data.foodForMonth.length == 0)
	) {
		errors.foodAlternate = "Food For Monnth is required";
	}

	if (
		data.familyInvolvementLocalOrgan === "local_yes" &&
		validator.isEmpty(data.familyLocalGovInvolve)
	) {
		errors.familyLocalGovInvolve = "Organization Field is required";
	}
	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const business = data => {
	let errors = {};
	data.business = !isEmpty(data.business) ? data.business : [];
	data.herbs = !isEmpty(data.herbs) ? data.herbs : [];

	if (
		data.anyBusiness &&
		data.anyBusiness === "home_busi_yes" &&
		data.business.length == 0
	) {
		errors.business = "Business Field is required";
	}

	if (
		data.anyBusiness &&
		data.business === "other" &&
		validator.isEmpty(data.businessOther)
	) {
		errors.businessOther = "Business Field is required";
	}

	if (data.anyHerb && data.anyHerb === "herbs_yes" && data.herbs.length == 0) {
		errors.herbs = "Herb Field is required";
	}
	if (
		data.anyHerb &&
		data.herbs === "other" &&
		validator.isEmpty(data.herbsOther)
	) {
		errors.herbsOther = "Business Field is required";
	}
	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const socialWelfare = data => {
	let errors = {};
	data.bhattaLists = !isEmpty(data.bhattaLists) ? data.bhattaLists : [];
	data.oldcitizenMale = !isEmpty(data.oldcitizenMale)
		? data.oldcitizenMale
		: "";
	data.oldcitizenFemale = !isEmpty(data.oldcitizenFemale)
		? data.oldcitizenFemale
		: "";
	data.singleWomenFemale = !isEmpty(data.singleWomenFemale)
		? data.singleWomenFemale
		: "";
	data.disableMale = !isEmpty(data.disableMale) ? data.disableMale : "";
	data.disableFemale = !isEmpty(data.disableFemale) ? data.disableFemale : "";
	data.halfdisableMale = !isEmpty(data.halfdisableMale)
		? data.halfdisableMale
		: "";
	data.halfdisableFemale = !isEmpty(data.halfdisableFemale)
		? data.halfdisableFemale
		: "";
	data.childsecurityMale = !isEmpty(data.childsecurityMale)
		? data.childsecurityMale
		: "";
	data.childsecurityFemale = !isEmpty(data.childsecurityFemale)
		? data.childsecurityFemale
		: "";
	data.dalitMale = !isEmpty(data.dalitMale) ? data.dalitMale : "";
	data.dalitFemale = !isEmpty(data.dalitFemale) ? data.dalitFemale : "";
	if (
		data.welfareYesNo &&
		data.welfareYesNo === "social_yes" &&
		data.bhattaLists.length == 0
	) {
		errors.bhattaLists = "Bhatta Field is required";
	}

	if (data.bhattaLists.includes("old_citizen")) {
		if (data.oldcitizenFemale + data.oldcitizenMale <= 0) {
			errors.oldcitizenMale = "sum must be 1 or greater than 1";
			errors.oldcitizenFemale = "sum must be 1 or greater than 1";
		}
	}
	if (data.bhattaLists.includes("single_women")) {
		if (isEmpty(data.singleWomenFemale) || data.singleWomenFemale <= 0) {
			errors.singleWomenFemale = "Must be 1 or greater than 1";
		}
	}
	if (data.bhattaLists.includes("full_disable")) {
		if (data.disableMale + data.disableFemale <= 0) {
			errors.disableMale = "sum must be 1 or greater than 1";
			errors.disableFemale = "sum must be 1 or greater than 1";
		}
	}
	if (data.bhattaLists.includes("half_disable")) {
		if (data.halfdisableMale + data.halfdisableFemale <= 0) {
			errors.halfdisableMale = "sum must be 1 or greater than 1";
			errors.halfdisableFemale = "sum must be 1 or greater than 1";
		}
	}
	if (data.bhattaLists.includes("child_security")) {
		if (data.childsecurityMale + data.childsecurityFemale <= 0) {
			errors.childsecurityMale = "sum must be 1 or greater than 1";
			errors.childsecurityFemale = "sum must be 1 or greater than 1";
		}
	}
	if (data.bhattaLists.includes("dalit")) {
		if (data.dalitMale + data.dalitFemale <= 0) {
			errors.dalitMale = "sum must be 1 or greater than 1";
			errors.dalitFemale = "sum must be 1 or greater than 1";
		}
	}
	// if (isEmpty(data.oldcitizenMale) || data.oldcitizenMale < 0) {
	// 	errors.oldcitizenMale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.oldcitizenFemale) || data.oldcitizenFemale < 0) {
	// 	errors.oldcitizenFemale = "Must be 1 or greater than 1";
	// }
	//
	// if ((data.oldcitizenFemale + data.oldcitizenMale) <= 0) {
	// 	errors.oldcitizenMale = "sum must be 1 or greater than 1";
	// 	errors.oldcitizenFemale = "sum must be 1 or greater than 1";
	// }

	// if (isEmpty(data.singleWomenFemale) || data.singleWomenFemale <0) {
	// 	errors.singleWomenFemale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.disableMale) || data.disableMale < 0) {
	// 	errors.disableMale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.disableFemale) || data.disableFemale < 0) {
	// 	errors.disableFemale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.halfdisableMale) || data.halfdisableMale < 0) {
	// 	errors.halfdisableMale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.halfdisableFemale) || data.halfdisableFemale < 0) {
	// 	errors.halfdisableFemale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.childsecurityMale) || data.childsecurityMale < 0) {
	// 	errors.childsecurityMale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.childsecurityFemale) || data.childsecurityFemale < 0) {
	// 	errors.childsecurityFemale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.dalitMale) || data.dalitMale < 0) {
	// 	errors.dalitMale = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.dalitFemale) || data.dalitFemale < 0) {
	// 	errors.dalitFemale = "Must be 1 or greater than 1";
	// }

	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const insurance = data => {
	let errors = {};
	data.insuranceLists = !isEmpty(data.insuranceLists)
		? data.insuranceLists
		: [];
	data.lifeInsurance = !isEmpty(data.lifeInsurance) ? data.lifeInsurance : "";
	data.healthInsurance = !isEmpty(data.healthInsurance)
		? data.healthInsurance
		: "";
	data.nonlifeInsurance = !isEmpty(data.nonlifeInsurance)
		? data.nonlifeInsurance
		: "";
	data.animalInsurance = !isEmpty(data.animalInsurance)
		? data.animalInsurance
		: "";

	if (
		data.insuranceYesNo &&
		data.insuranceYesNo === "insurance" &&
		data.insuranceLists.length == 0
	) {
		errors.insuranceLists = "Insurance List field is required";
	}

	// if (isNaN(data.lifeInsurance)) {
	// 	errors.lifeInsurance = "lifeInsurance Number  is required";
	// }
	// if (isNaN(data.healthInsurance)) {
	// 	errors.healthInsurance = "healthInsurance Number  is required";
	// }
	// if (isNaN(data.nonlifeInsurance)) {
	// 	errors.nonlifeInsurance = "nonlifeInsurance Number  is required";
	// }
	// if (isNaN(data.animalInsurance)) {
	// 	errors.animalInsurance = "animalInsurance Number  is required";
	// }
	// if (isEmpty(data.lifeInsurance) || data.lifeInsurance < 1) {
	// 	errors.lifeInsurance = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.healthInsurance) || data.healthInsurance < 1) {
	// 	errors.healthInsurance = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.nonlifeInsurance) || data.nonlifeInsurance < 1) {
	// 	errors.nonlifeInsurance = "Must be 1 or greater than 1";
	// }
	// if (isEmpty(data.animalInsurance) || data.animalInsurance < 1) {
	// 	errors.animalInsurance = "Must be 1 or greater than 1";
	// }

	if (data.insuranceLists.includes("life_insurance")) {
		if (isEmpty(data.lifeInsurance) || data.lifeInsurance <= 0) {
			errors.lifeInsurance = "Must be 1 or greater than 1";
		}
	}

	if (data.insuranceLists.includes("health_insuran")) {
		if (isEmpty(data.healthInsurance) || data.healthInsurance <= 0) {
			errors.healthInsurance = "Must be 1 or greater than 1";
		}
	}

	if (data.insuranceLists.includes("non_life_insur")) {
		if (isEmpty(data.nonlifeInsurance) || data.nonlifeInsurance <= 0) {
			errors.nonlifeInsurance = "Must be 1 or greater than 1";
		}
	}

	if (data.insuranceLists.includes("animal_insuran")) {
		if (isEmpty(data.animalInsurance) || data.animalInsurance <= 0) {
			errors.animalInsurance = "Must be 1 or greater than 1";
		}
	}

	return {
		errors,
		isValid: isEmpty(errors)
	};
};
const agriLivestock = data => {
	let errors = {};
	data.agriProductionType = !isEmpty(data.agriProductionType)
		? data.agriProductionType
		: [];
	data.agriCropIncome = !isEmpty(data.agriCropIncome)
		? data.agriCropIncome
		: "";
	data.agriOilIncome = !isEmpty(data.agriOilIncome) ? data.agriOilIncome : "";
	data.agriDaalIncome = !isEmpty(data.agriDaalIncome)
		? data.agriDaalIncome
		: "";
	data.agriVegetableIncome = !isEmpty(data.agriVegetableIncome)
		? data.agriVegetableIncome
		: "";
	data.agriFruitIncome = !isEmpty(data.agriFruitIncome)
		? data.agriFruitIncome
		: "";
	data.agriCashCropIncome = !isEmpty(data.agriCashCropIncome)
		? data.agriCashCropIncome
		: "";

	if (
		data.agriYesNo &&
		data.agriYesNo === "agri_sales_yes" &&
		data.agriProductionType.length == 0
	) {
		errors.agriProductionType = "Agriculture List field is required";
	}

	// if(data.agriProductionType && data.agriProductionType === "crops" && data.agriCropType.includes("cropIncome")){
	// 		if (isEmpty(data.agriCropIncome) || data.agriCropIncome <=0) {
	// 			errors.agriCropIncome = "Must be 1 or greater than 1";
	// 		}
	// 	}

	if (
		data.agriProductionType &&
		data.agriProductionType.includes("crops") &&
		data.agriCropIncome.includes("cropIncome")
	) {
		if (isEmpty(data.agriCropIncome) || data.agriCropIncome <= 0) {
			errors.agriCropIncome = "Must be 1 or greater than 1";
		}
	}
	//
	// if(data.insuranceLists.includes("animal_insuran") ){
	// 	if (isEmpty(data.animalInsurance) || data.animalInsurance <=0) {
	// 		errors.animalInsurance = "Must be 1 or greater than 1";
	// 	}
	// }

	// if(data.insuranceLists.includes("health_insuran") ){
	// 	if (isEmpty(data.healthInsurance) || data.healthInsurance <=0) {
	// 		errors.healthInsurance = "Must be 1 or greater than 1";
	// 	}
	// }
	//
	// if(data.insuranceLists.includes("non_life_insur") ){
	// 	if (isEmpty(data.nonlifeInsurance) || data.nonlifeInsurance <=0) {
	// 		errors.nonlifeInsurance = "Must be 1 or greater than 1";
	// 	}
	// }
	//
	// if(data.insuranceLists.includes("animal_insuran") ){
	// 	if (isEmpty(data.animalInsurance) || data.animalInsurance <=0) {
	// 		errors.animalInsurance = "Must be 1 or greater than 1";
	// 	}
	// }

	return {
		errors,
		isValid: isEmpty(errors)
	};
};

module.exports = [
	primaryHouse,
	familyDetail,
	0,
	insurance,
	secondaryHouse,
	houseDetail,
	agriLivestock,
	economics,
	business,
	3,
	socialWelfare
];
