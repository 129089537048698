import axios from "axios";

import {
  GET_SMS_ANALYSIS,
  SET_SMS_ANALYSIS_LOADING,
  GET_CAMPAIGN_ANALYSIS
} from "./types";

export const smsAnalysisData = timespan => dispatch => {
  dispatch({
    type: SET_SMS_ANALYSIS_LOADING
  });
  axios
    .get(`/api/sms/analysis/no-of-sms/${timespan}`)
    .then(res => {
      dispatch({
        type: GET_SMS_ANALYSIS,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const campaignAnalysisData = timespan => dispatch => {
  dispatch({
    type: SET_SMS_ANALYSIS_LOADING
  });
  axios
    .get(`/api/sms/analysis/campaign/${timespan}`)
    .then(res => {
      dispatch({
        type: GET_CAMPAIGN_ANALYSIS,
        payload: res.data
      });
    })
    .catch(err => {});
};
