import { Grid, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
	Button,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Upload
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addProjectSurvey } from "../../../actions/instituteAction";
import { selectedLanguage, ward } from "../../../variable/global";
import {
	add,
	budget,
	dartachalani,
	description,
	effectedHouse,
	fiscal,
	name,
	pdf,
	timespan,
	wardQuestion
} from "../../../variable/institute";

const { TextArea } = Input;
const { Option } = Select;

class Project extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		errors: {},
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			instituteImage: []
		},
		loaded: 0,
		//other
		name: "",
		ward: "",
		fiscalYear: "",
		dartaChalaniNo: "",
		budget: "",
		timeSpan: "",
		effectedHouseHold: "",
		description: "",
		pdf: "",
		isView: this.props.location.view
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".pdf"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".pdf";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".pdf";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	componentDidMount() {
		let projectId = this.props.match.params.id;
		Axios.get(`/api/project/fetch/${projectId}`)
			.then(res => {
				this.setState({
					name: res.data.name,
					category: res.data.category,
					ward: res.data.ward,
					description: res.data.description,
					fiscalYear: res.data.fiscalYear,
					dartaChalaniNo: res.data.dartaChalaniNo,
					budget: res.data.budget,
					timeSpan: res.data.timeSpan,
					effectedHouseHold: res.data.effectedHouseHold,
					// image
					fileList: {
						tourism: {
							fileName: res.data.uniqueIdentifier + "project.pdf",
							image: [
								{
									uid: "-1",
									name: `${res.data.uniqueIdentifier}_project.pdf`,
									status: "done",
									url: `/api/imageUpload/image/${
										res.data.uniqueIdentifier
									}_project.pdf`
								}
							]
						}
					}
				});
			})
			.catch(err => {});
	}

	onSubmit = event => {
		event.preventDefault();
		const newProject = {
			name: this.state.name,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			description: this.state.description,
			fiscalYear: this.state.fiscalYear,
			dartaChalaniNo: this.state.dartaChalaniNo,
			budget: this.state.budget,
			timeSpan: this.state.timeSpan,
			effectedHouseHold: this.state.effectedHouseHold,
			category: this.props.category
		};
		if (this.props.isUpdate) newProject.id = this.props.match.params.id;
		this.props
			.addProjectSurvey(newProject)
			.then(res => {
				if (res.type === "SAVE_PROJECT") {
					message.success("Sucessfully saved");
					this.props.history.push(
						`/survey-by-institution/show/${this.props.match.params.category}`
					);
				}
			})
			.catch(err => {
				message.error("Cannot Saved at the moment");
				console.log("err", err.response.data);
				if (err) this.setState({ errors: err.response.data });
			});

	};

	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value
		});
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage, errors } = this.state;
		
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item validateStatus={errors.name ? "error" : ""}
							help={errors.name || ""} label={name[selectedLanguage]}>
							<Input
								required
								onChange={this.handleChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item validateStatus={errors.ward ? "error" : ""}
							help={errors.ward || ""} label={wardQuestion[selectedLanguage]}>
							<Select
							required
								placeholder="------------"
								onChange={val => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map(woda => (
									<Option value={woda.value}>{woda[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={fiscal[selectedLanguage]}>
							<Input
								onChange={this.handleChange("fiscalYear")}
								value={this.state.fiscalYear}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={dartachalani[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("dartaChalaniNo")}
								value={this.state.dartaChalaniNo}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={budget[selectedLanguage]}>
							<Input
								onChange={this.handleChange("budget")}
								value={this.state.budget}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={timespan[selectedLanguage]}>
							<Input
								onChange={this.handleChange("timeSpan")}
								value={this.state.timeSpan}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={effectedHouse[selectedLanguage]}>
							<Input
								onChange={this.handleChange("effectedHouseHold")}
								value={this.state.effectedHouseHold}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={description[selectedLanguage]}>
							<TextArea
								onChange={this.handleChange("description")}
								value={this.state.description}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={pdf[selectedLanguage]}>
							<Fragment>
								{!this.state.isView ? (
									<Upload
										name="file"
										// beforeUpload={this.props.beforeUpload}
										multiple={false}
										// action="/api/imageUpload/upload"
										accept=".pdf"
										fileList={
											fileList.instituteImage && fileList.instituteImage.image
										}
										onPreview={this.handlePreview}
										onChange={fileList =>
											this.handleChangeFile(
												"instituteImage",
												fileList,
												"institute"
											)
										}
										disabled={this.state.isView}
									>
										<Button>
											<Icon type="upload" /> Upload
										</Button>
									</Upload>
								) : (
									<Modal
										visible={previewVisible}
										footer={null}
										onCancel={this.handleCancel}
									>
										<img
											alt="example"
											style={{ width: "100%" }}
											src={previewImage}
										/>
									</Modal>
								)}
							</Fragment>
						</Form.Item>
					</Grid>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}
const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ addProjectSurvey }
	)(withRouter(Project))
);
