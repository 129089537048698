var nums = {
	"०": 0,
	"१": 1,
	"२": 2,
	"३": 3,
	"४": 4,
	"५": 5,
	"६": 6,
	"७": 7,
	"८": 8,
	"९": 9
};

module.exports = function(strNum) {
	if (strNum) {
		var arrNumNe = strNum
			.toString()
			.split("")
			.map(ch =>
				ch === "-" || ch === "." || ch === "," || nums[ch] === undefined
					? ch
					: nums[ch]
			);
		return arrNumNe.join("");
	} else return strNum;
};
