import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { deleteUser } from "../../../actions/authAction";
import { privilege, selectedLanguage } from "../../../variable/global";
import Can from "../../casl/Can";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import PreviewModal from "../../common/frequent/dialog/PreviewModal";

class ListUser extends Component {
	constructor(props) {
		super(props);
		const role = parseInt(sessionStorage.getItem("role"));
		const ward = parseInt(sessionStorage.getItem("ward"));
		this.state = {
			role,
			ward,
			id: "",
			content: "",
			negButton: "",
			posButton: "",
			currentUserId: null,
			deleteDialog: false,
			open: false
		};
	}

	removeUser = () => {
		const userId = this.state.currentUserId;
		deleteUser(userId).then(res => {
			console.log(res);
			this.handleClose();
			this.props.fetchUser();
		});
	};

	getPrivilege = data => {
		return privilege.filter(each => each.value === data)[0][selectedLanguage];
	};

	handleDeleteOpen = userId => event => {
		this.setState({ deleteDialog: true, currentUserId: userId });
	};

	handleClickOpen = () => {
		this.setState({ deleteDialog: true });
	};
	handleClose = () => {
		this.setState({ deleteDialog: false });
	};

	handlePreviewOpen = id => () => {
		this.setState({ id, open: true });
	};

	handlePreviewClose = () => {
		this.setState({ open: false, id: null });
	};

	render() {
		const { classes, users } = this.props;
		return (
			<div className={classes.tableWrapper}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>क्र.श</TableCell>
							<TableCell>नाम</TableCell>
							<TableCell>ईमेल</TableCell>
							<TableCell>भूमिका</TableCell>
							<TableCell>गतिविधि</TableCell>
						</TableRow>
					</TableHead>
					<AlertDialog
						open={this.state.deleteDialog}
						handleClose={this.handleClose}
						negButtonClick={this.handleClose}
						posButtonClick={this.removeUser}
						title="Are you sure you want to delete the user ?"
						posButton="agree"
						negButton="disagree"
					/>

					<PreviewModal
						open={this.state.open}
						handleClose={this.handlePreviewClose}
						userId={this.state.id}
						fetchUser={this.fetchUser}
					/>

					<TableBody>
						{users.map((user, id) => (
							<TableRow key={id}>
								<TableCell component="th" scope="row">
									{getNepaliNumber(id + 1)}
								</TableCell>
								<TableCell align="left">{user.user.name.first}</TableCell>
								<TableCell align="left">{user.user.email}</TableCell>
								<TableCell align="left">
									{this.getPrivilege(user.role)}
								</TableCell>
								<TableCell align="left" style={{ display: "flex" }}>
									<VisibilityIcon
										onClick={this.handlePreviewOpen(user.user._id)}
										className={classnames(classes.preview, classes.icon)}
									/>

									<Can
										do="delete"
										on={{
											__type: "User",
											canDelete: this.state.role <= user.role
										}}
									>
										<Link to={`/users/edit/${user.user._id}`}>
											<Icon
												// onClick={this.handleEdit(user.user_id)}
												className={classnames(classes.edit, classes.icon)}
											/>
										</Link>
									</Can>

									<Can
										do="edit"
										on={{
											__type: "User",
											canDelete: this.state.role < user.role
										}}
									>
										<DeleteIcon
											onClick={this.handleDeleteOpen(user.user._id)}
											className={classnames(classes.delete, classes.icon)}
										/>
									</Can>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	},
	icon: {
		margin: theme.spacing.unit * 1,
		cursor: "pointer"
	},
	preview: {
		color: lightBlue[500]
	},
	edit: {
		color: green[700]
	},
	delete: {
		color: red[500]
	},
	paper: {
		position: "absolute",
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: "none"
	}
});

export default withStyles(styles)(ListUser);
