import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import DetailAnalysis from "./DetailAnalysis";
import Home from "./Home";

class Index extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path={`${this.props.match.url}/:option`}
						render={props => <DetailAnalysis {...props} />}
					/>
					<Route path={`${this.props.match.url}`} component={Home} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(Index);
