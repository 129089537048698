import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import { tuple } from "antd/lib/_util/type";
import getNepaliNumber from "get-nepali-number";
import React, { Component, Fragment } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	getAnalaysisInstituteChartData,
	getAnalaysisInstituteDetailData
} from "../../../../actions/analysisAction";
import { totalWard } from "../../../../config";
import {
	gaupalikaWard,
	selectedLanguage,
	wadaBibaranName,
	ward
} from "../../../../variable/global";
import Can from "../../../casl/Can";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import CardIcon from "../../../common/Card/CardIcon";
import FullScreenDialog from "../../../common/frequent/dialog/FullScreen";
import GridItem from "../../../common/Grid/GridItem.jsx";
import WardInfo from "../../home/WardInfo";
import TablePaginationActionsWrapped from "../../search/death/TablePaginationActions";
import BarGraph from "../pariwarikBibaran/charts/ChartList";
import DetailAnalysisTable from "./DetailAnalysisTable";

class DetailAnalysis extends Component {
	constructor(props) {
		super(props);

		const role = parseInt(sessionStorage.getItem("role"));
		const ward = parseInt(sessionStorage.getItem("ward"));
		var wardData;
		if (ward === 0) wardData = {};
		else wardData = { wardNumber: ward };

		this.state = {
			wardData,
			multipleFull: false,
			barFull: false,
			pieFull: false,
			doghnutFull: false,
			polarFull: false,
			selected: `ward${ward}`,
			isFullScreen: tuple,
			role,
			ward,
			page: 0,
			rowsPerPage: 10,
			sn: ""
		};
	}
	componentDidMount() {
		this.fetchData();
		this.props.getAnalaysisInstituteDetailData();
	}

	fetchData = () => {
		const option = ["level", "student"];
		option.map(each => {
			this.props.getAnalaysisInstituteChartData(this.state.wardData, each);
		});
	};

	onWardClick = wardData => {
		const option = ["level", "student"];
		this.state.wardData.wardNumber != wardData.wardNumber &&
			this.setState({ wardData }, () => {
				this.fetchData();
			});
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};
	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};

	handleClickOpen = name => {
		this.setState({ [name]: true });
	};

	handleClose = name => {
		this.setState({ [name]: false });
	};

	getBarChart = () => {
		if (this.props.chart) {
			const single = this.props.chart;
			return (
				<FullScreenDialog
					name="barFull"
					open={this.props.barFull}
					title="बार ग्राफ"
					handleClose={this.handleClose}
				>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						{single && (
							<Grid item xs={4}>
								<BarGraph
									noTitle
									chartData={{
										label: [single.reportTitle],
										data: [single.data]
									}}
								/>
							</Grid>
						)}
					</Grid>
				</FullScreenDialog>
			);
		}
	};

	mapLevel = level => {
		if (level === "kinder_garden") return "बाल विकास कक्षा";
		if (level === "pre_primary") return "पूर्व प्राथमिक";
		if (level === "primary") return "आधारभूत तह";
		if (level === "secondary") return "माध्यमिक तह";
		if (level === "slc") return "प्राविधिक एस.एल.सी. (Overseer)";
		if (level === "bachelor") return "स्नातक तह";
	};

	getChartData = data => {
		const label = [
			{
				english: "government",
				nepali: "सरकारी"
			},
			{
				english: "private",
				nepali: "निजी"
			},
			{
				english: "community",
				nepali: "सामुदायिक"
			}
		];

		let allData = [1, 2, 3].map(each => {
			return {
				label: label[each - 1][selectedLanguage],
				data: data.map(eachC => eachC[label[each - 1].english]),
				backgroundColor: ["#5EA", "#50A", "#AD0"],
				borderWidth: 2
			};
		});
		return allData;
	};
	render() {
		const { loading, classes, level, student, overall } = this.props;
		const { wardData, page, rowsPerPage } = this.state;
		const gaupalika = gaupalikaWard[selectedLanguage].split(" ");
		return (
			<div style={{ marginBottom: 50 }}>
				<Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
					<Typography variant="h5" gutterBottom>
						{wadaBibaranName[selectedLanguage]}
					</Typography>
					<div style={{ marginBottom: 25, marginTop: 20 }}>
						<Grid container spacing={16}>
							{ward.map((each, key) => (
								<Grid item>
									<WardInfo
										ward={this.state.ward}
										role={this.state.role}
										isActive={key + 1 == this.state.wardData.wardNumber}
										backgroundColor={each.backgroundColor}
										onWardClick={this.onWardClick}
										unique={key + 1}
										key={key}
										wardNumber={each[selectedLanguage]}
										wardName={each.ward}
									/>
								</Grid>
							))}
							<Grid item>
								<WardInfo
									ward={this.state.ward}
									role={this.state.role}
									isActive={undefined == this.state.wardData.wardNumber}
									backgroundColor={"#00ACC1"}
									unique={0}
									wardNumber={gaupalika[0]}
									wardName={gaupalika[1]}
									onWardClick={this.onWardClick}
								/>
							</Grid>
						</Grid>
					</div>
				</Can>

				<Grid container>
					{loading ? (
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid item>
								<ClipLoader
									sizeUnit={"px"}
									size={84}
									color={"#00ACC1"}
									loading={loading}
								/>
							</Grid>
						</Grid>
					) : (
						<Grid
							container
							direction="column"
							ref={el => (this.componentRef = el)}
						>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="flex-start"
							>
								<Grid item xs={12} md={9}>
									<Card chart color="info">
										<CardHeader color="success" stats icon>
											<Grid
												container
												direction="row"
												justify="space-between"
												alignItems="flex-start"
											>
												<Grid item>
													<CardIcon
														color="success"
														className={classes.iconWidth}
													>
														{level && <p>{level.label}</p>}
													</CardIcon>
												</Grid>
											</Grid>
											<Grid
												container
												spacing={24}
												justify="center"
												alignItems="flex-start"
											>
												{level && level.data && (
													<Grid item xs={6}>
														<Bar
															height={200}
															type="bar"
															data={{
																labels: level.data.map((d, i) =>
																	this.mapLevel(d.schoolLevel)
																),
																datasets: this.getChartData(level.data)
															}}
															options={{
																scales: {
																	yAxes: [
																		{
																			ticks: {
																				autoSkip: false,
																				beginAtZero: true
																			}
																		}
																	],
																	xAxes: [
																		{
																			ticks: {
																				autoSkip: false,
																				beginAtZero: true
																			}
																		}
																	]
																}
															}}
														/>
													</Grid>
												)}
											</Grid>
										</CardHeader>
									</Card>
								</Grid>
							</Grid>

							<Grid
								container
								direction="row"
								justify="center"
								alignItems="flex-start"
							>
								{student && (
									<Grid item xs={12}>
										<DetailAnalysisTable
											unique={1}
											title={student.label}
											subtitle=""
											tableHeader={student.reportTitle}
											chartData={student.data}
											ward={this.state.ward}
											barfull={this.state.barFull}
											total={student.total}
											fields={[
												"name",
												"schoolLevel",
												"male",
												"female",
												"total"
											]}
											totalFields={2}
											colSpan={3}
										/>
									</Grid>
								)}

								{level && (
									<Grid item xs={12}>
										<DetailAnalysisTable
											unique={2}
											title={level.label}
											subtitle=""
											tableHeader={level.reportTitle}
											chartData={level.data}
											ward={this.state.ward}
											barfull={this.state.barFull}
											total={level.total}
											totalFields={1}
											fields={[
												"schoolLevel",
												"government",
												"private",
												"community",
												"total"
											]}
											colSpan={2}
										/>
									</Grid>
								)}

								{overall && (
									<GridItem xs={12} sm={12} md={12}>
										<Card style={{ width: "auto" }}>
											<CardBody>
												<CardHeader color="primary">
													<Typography
														variant="h6"
														className={classes.cardTitleWhite}
													>
														{overall.label}
													</Typography>
												</CardHeader>

												<Table className={classes.table}>
													<TableHead>
														<TableRow>
															<TableCell component="th">क्र.स</TableCell>
															{overall.reportTitle &&
																overall.reportTitle.map(eachhead => (
																	<TableCell component="th">
																		{eachhead}
																	</TableCell>
																))}
														</TableRow>
													</TableHead>
													<TableBody>
														{overall && overall.data ? (
															<Fragment>
																{overall.data
																	.slice(
																		page * rowsPerPage,
																		page * rowsPerPage + rowsPerPage
																	)
																	.map((d, id) => {
																		return (
																			<TableRow key={id}>
																				<TableCell
																					scope="row"
																					style={{ padding: "0px !important" }}
																				>
																					<Typography variant="body1">
																						{getNepaliNumber(
																							page * rowsPerPage + (id + 1)
																						)}
																					</Typography>
																				</TableCell>
																				<TableCell>{d.name}</TableCell>
																				{[...Array(totalWard).keys()].map(i => (
																					<TableCell>
																						{getNepaliNumber(
																							d[`ward${i + 1}`].length
																						)}
																					</TableCell>
																				))}

																				<TableCell>
																					{getNepaliNumber(
																						[...Array(totalWard).keys()].reduce(
																							(c, a) =>
																								(c += d[`ward${a + 1}`].length),
																							0
																						)
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																<TableRow>
																	<TableCell colspan={2}>जम्मा</TableCell>

																	{overall &&
																		[...Array(totalWard).keys()].map(each => (
																			<TableCell>
																				{getNepaliNumber(
																					overall.data.reduce(
																						(c, a) =>
																							(c +=
																								a[`ward${each + 1}`].length),
																						0
																					)
																				)}
																			</TableCell>
																		))}
																	<TableCell>
																		{overall &&
																			getNepaliNumber(
																				[...Array(totalWard).keys()]
																					.map(each =>
																						overall.data.reduce(
																							(c, a) =>
																								(c +=
																									a[`ward${each + 1}`].length),
																							0
																						)
																					)
																					.reduce((c, a, i) => (c += a), 0)
																			)}
																	</TableCell>
																</TableRow>
															</Fragment>
														) : (
															<TableRow>No data</TableRow>
														)}
													</TableBody>
													{overall && overall.data && (
														<TableFooter>
															<TableRow>
																<TablePagination
																	rowsPerPageOptions={[
																		10,
																		20,
																		50,
																		100,
																		200,
																		500
																	]}
																	colSpan={7}
																	count={overall.data.length}
																	rowsPerPage={rowsPerPage}
																	page={page}
																	SelectProps={{
																		native: true
																	}}
																	onChangePage={this.handleChangePage}
																	onChangeRowsPerPage={
																		this.handleChangeRowsPerPage
																	}
																	ActionsComponent={
																		TablePaginationActionsWrapped
																	}
																/>
															</TableRow>
														</TableFooter>
													)}
												</Table>
											</CardBody>
										</Card>
									</GridItem>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			</div>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "auto",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		width: "auto"
	},
	tableWrapper: {
		overflowX: "auto"
	}
});

const mapStateToProps = ({
	analysis: { loading, level, student, overall }
}) => ({
	loading,
	level,
	student,
	overall
});
export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getAnalaysisInstituteChartData, getAnalaysisInstituteDetailData }
	)(DetailAnalysis)
);
