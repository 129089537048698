import axios from "axios";
import { SAVE_HOUSE, SHOW_HOUSE } from "./types";

export const addHouseSurvey = data => dispatch => {
	axios.post(`/api/housesurvey/survey`, data).then(res => {
		console.log(res.data, "house");
		dispatch({
			type: SAVE_HOUSE,
			payload: res.data
		});
	});
};

export const editHouseSurvey = id => dispatch => {
	console.log("editHouseSurvey", id);
	axios
		.get(`/api/house/listhouse/${id}`)
		.then(res => {
			console.log(res);
			dispatch({
				type: SHOW_HOUSE,
				payload: res.data
			});
		})
		.catch(err => console.log(err, "ac"));
};
