import { Grid, IconButton, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Select,
	Upload
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addItemSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { selectedLanguage, ward } from "../../../variable/global";
import {
	add,
	count,
	name,
	photo,
	specName,
	specTitle,
	specValue,
	wardQuestion,
	latitude,
	longitude
} from "../../../variable/institute";

const { Option } = Select;

class Item extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		errors: {},
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			itemImage: []
		},
		loaded: 0,
		// other
		name: "",
		count: "",
		ward: "",
		latitude: "",
		longitude: "",
		specification: [],
		maxItemIndex: 1,
		isView: this.props.location.view
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	componentDidMount() {
		let itemId = this.props.match.params.id;
		if (this.props.isUpdate)
			Axios.get(`/api/item/fetch/${itemId}`)
				.then(res => {
					this.setState({
						uniqueIdentifier: res.data.uniqueIdentifier,
						name: res.data.name,
						count: res.data.count,
						category: res.data.category,
						ward: res.data.ward,
						specification: res.data.specification,
						latitude: res.data.geo && res.data.geo.lat,
						longitude: res.data.geo && res.data.geo.long,
						maxItemIndex:
							res.data.specification && res.data.specification.length,
						// image
						fileList: {
							itemImage: {
								fileName: res.data.uniqueIdentifier + "_item.jpg",
								image: [
									{
										uid: "-1",
										name: `${res.data.uniqueIdentifier}_item.jpg`,
										status: "done",
										url: `/api/imageUpload/image/${
											res.data.uniqueIdentifier
										}_item.jpg`
									}
								]
							}
						}
					});
				})
				.catch(err => {});
	}

	onSubmit = event => {
		event.preventDefault();

		const newItem = {
			name: this.state.name,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			count: this.state.count,
			long: this.state.longitude,
			lat: this.state.latitude,
			category: this.props.category,
			specification: this.state.specification
		};
		if (this.props.isUpdate) newItem.id = this.props.match.params.id;
		this.props
			.addItemSurvey(newItem)
			.then(res => {
				if (res.type === "SAVE_ITEM") {
					message.success("Sucessfully saved");
					this.props.history.push(
						`/survey-by-institution/show/${this.props.match.params.category}`
					);
				}
			})
			.catch(err => {
				message.error("Cannot Saved at the moment");
				console.log("err", err.response.data);
				if (err) this.setState({ errors: err.response.data });
			});
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	addItem = () => {
		this.setState(prevState => ({
			maxItemIndex: prevState.maxItemIndex + 1
		}));
	};

	handleNameSpec = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const specification = [...prevState.specification];

			const data = {};
			data.name = name;
			data.value = isEmpty(specification[index])
				? ""
				: specification[index].value;
			specification.splice(index, 1, data);
			return { specification };
		});
	};

	handleValueSpec = index => event => {
		const value = event.target.value;
		this.setState(prevState => {
			const specification = [...prevState.specification];

			const data = {};
			data.value = value;
			data.name = isEmpty(specification[index])
				? ""
				: specification[index].name;
			specification.splice(index, 1, data);
			return { specification };
		});
	};

	removeSpec = index => () => {
		this.setState(prevState => {
			const specification = [...prevState.specification];
			specification.splice(index, 1);
			return { specification, maxItemIndex: prevState.maxItemIndex - 1 };
		});
	};

	getSpecUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxItemIndex, specification } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxItemIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={
											specification &&
											specification[each] &&
											specification[each].name
										}
										onChange={this.handleNameSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={specValue[selectedLanguage]}>
									<Input
										name="value"
										type="text"
										value={
											specification &&
											specification[each] &&
											specification[each].value
										}
										onChange={this.handleValueSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeSpec(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addItem}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage, errors } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		console.log("errors", errors);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item
							label={name[selectedLanguage]}
							validateStatus={errors.name ? "error" : ""}
							help={errors.name || ""}
						>
							<Input
								required
								name="name"
								onChange={this.handleChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={count[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("count")}
								value={this.state.count}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item
							label={wardQuestion[selectedLanguage]}
							validateStatus={errors.ward ? "error" : ""}
							help={errors.ward || ""}
						>
							<Select
								required
								placeholder="------------"
								onChange={val => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map(woda => (
									<Option value={woda.value}>{woda[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={latitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("latitude")}
								value={this.state.latitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={longitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("longitude")}
								value={this.state.longitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={photo[selectedLanguage]}>
							<Fragment>
								{!this.state.isView && (
									<Upload
										name="file"
										// beforeUpload={this.props.beforeUpload}
										multiple={false}
										// action="/api/imageUpload/upload"
										accept="image/*"
										listType="picture-card"
										fileList={fileList.itemImage && fileList.itemImage.image}
										onPreview={this.handlePreview}
										onChange={fileList =>
											this.handleChangeFile("itemImage", fileList, "item")
										}
										disabled={this.state.isView}
									>
										{fileList &&
										fileList.itemImage &&
										fileList.itemImage.image &&
										fileList.itemImage.image.length === 1
											? null
											: uploadButton}
									</Upload>
								)}
								<Modal
									visible={previewVisible}
									footer={null}
									onCancel={this.handleCancel}
								>
									<img
										alt="example"
										style={{ width: "100%" }}
										src={previewImage}
									/>
								</Modal>
							</Fragment>
						</Form.Item>
					</Grid>

					<Divider orientation="left">{specTitle[selectedLanguage]}</Divider>
					{this.getSpecUI()}

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ addItemSurvey }
	)(withRouter(Item))
);
