import Club from "./Club";
import Institute from "./Institute";
import Item from "./Item";
import School from "./School";
import Project from "./Project";
import TourismPlace from "./TourismPlace";
import ProdEducation from "./ProdEducation";
import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import Place from "@material-ui/icons/Place";
import Star from "@material-ui/icons/Star";

export const instituteFormName = {
	institute: [
		"ngo",
		"police",
		"bank",
		"hotel",
		"communityHall",
		"postOffice",
		"healthPost",
		"animalVeterinary"
	],
	club: [
		"footballClub",
		"youthClub",
		"farmerClub",
		"mothersClub",
		"socialClub",
		"businessClub",
		"savingClub",
		"farmerBusiness",
		"traditionalClub"
	],
	item: [
		"communication",
		"fm",
		"reporter",
		"mainBusiness",
		"mainImport",
		"temple",
		"mainExport",
		"festival",
		"mainFestival",
		"busPark",
		"publicToilet",
		"graveYard",
		"publicLand",
		"incidentLoss",
		"riskManagement",
		"animal",
		"animalPlace",
		"slum",
		"trainningInstitute",
		"electricSituation",
		"powerHouse"
	],
	school: ["school", "college"],
	place: [
		"monastery",
		"touristPlace",
		"ancientPlace",
		"park",
		"picnic",
		"hotel",
		"lake",
		"mine",
		"forest",
		"herbs",
		"bridge",
		"ground",
		"market",
		"flora",
		"waterResource"
	],
	project: ["waterProject", "irrigationProject"],
	prodEducation: ["prodEducation"]
};

export const addcategoryList = [
	"school",
	"college",
	"prodEducation",
	"ngo",
	"police",
	"bank",
	"communityHall",
	"postOffice",
	"healthPost",
	"animalVeterinary",
	"touristPlace",
	"ancientPlace",
	"park",
	"picnic",
	"hotel",
	"lake",
	"mine",
	"forest",
	"herbs",
	"bridge",
	"ground",
	"market",
	"flora",
	"waterResource",
	"communication",
	"fm",
	"reporter",
	"temple",
	"monastery",
	"mainBusiness",
	"mainImport",
	"mainExport",
	"festival",
	"mainFestival",
	"busPark",
	"publicToilet",
	"graveYard",
	"publicLand",
	"incidentLoss",
	"riskManagement",
	"animal",
	"animalPlace",
	"slum",
	"trainningInstitute",
	"electricSituation",
	"powerHouse",
	"footballClub",
	"youthClub",
	"farmerClub",
	"mothersClub",
	"socialClub",
	"businessClub",
	"savingClub",
	"farmerBusiness",
	"traditionalClub",
	"waterProject",
	"irrigationProject"
];

export var formControlData = [
	{
		comp: School
	},
	{
		comp: School
	},
	{
		comp: ProdEducation
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: Institute
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: TourismPlace
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Item
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Club
	},
	{
		comp: Project
	},
	{
		comp: Project
	}
];

export const instituteRoute = [
	{
		title: {
			english: "Institute",
			nepali: "संस्था"
		},
		child: [
			{
				name: "सरकारी संस्था / गैर सरकारी संस्था",
				route: "/survey-by-institution/show/ngo",
				count: 2
			},
			{
				name: "प्रहरी चौकी",
				route: "/survey-by-institution/show/police",
				count: 2
			},
			{
				name: "बैंक र वित्तीय संस्था",
				route: "/survey-by-institution/show/bank",
				count: 2
			},
			{
				name: "सामुदायिक भवन",
				route: "/survey-by-institution/show/communityHall",
				count: 2
			},
			{
				name: "हुलाक",
				route: "/survey-by-institution/show/postOffice",
				count: 2
			}
		],
		icon: Business
	},
	{
		title: { english: "Club", nepali: "क्लब समूह" },
		child: [
			{
				name: "फुटबल क्लब",
				route: "/survey-by-institution/show/footballClub",
				count: 2
			},
			{
				name: "युथ क्लब / युवा क्लब",
				route: "/survey-by-institution/show/youthClub",
				count: 2
			},
			{
				name: "कृषक समूह",
				route: "/survey-by-institution/show/farmerClub",
				count: 2
			},
			{
				name: "आमा समूह",
				route: "/survey-by-institution/show/mothersClub",
				count: 2
			},
			{
				name: "सामाजिक समूह",
				route: "/survey-by-institution/show/socialClub",
				count: 2
			},
			{
				name: "उधमी समूह",
				route: "/survey-by-institution/show/businessClub",
				count: 2
			},
			{
				name: "वचत समूह",
				route: "/survey-by-institution/show/savingClub",
				count: 2
			},
			{
				name: "व्यवसायिक कृषक समूह",
				route: "/survey-by-institution/show/farmerBusiness",
				count: 2
			},
			{
				name: "परम्परागत समूह",
				route: "/survey-by-institution/show/traditionalClub",
				count: 2
			}
		],
		icon: Group
	},
	{
		title: { english: "Education", nepali: "शिक्षा" },
		child: [
			{
				name: "स्कुल",
				route: "/survey-by-institution/show/school",
				count: 2
			},
			{
				name: "कलेज",
				route: "/survey-by-institution/show/college",
				count: 2
			},
			{
				name: "तालिम केन्द्र",
				route: "/survey-by-institution/show/trainningInstitute",
				count: 2
			},
			{
				name: "प्रोड्शिक्षा",
				route: "/survey-by-institution/show/prodEducation",
				count: 2
			}
		],
		icon: SchoolIcon
	},
	{
		title: { english: "Tourism Place", nepali: "पर्यटन सम्बन्धी" },
		child: [
			{
				name: "पर्यटकीय क्षेत्र",
				route: "/survey-by-institution/show/touristPlace",
				count: 2
			},
			{
				name: "ऐतिहासिक स्थल",
				route: "/survey-by-institution/show/ancientPlace",
				count: 2
			},
			{
				name: "पार्क",
				route: "/survey-by-institution/show/park",
				count: 2
			},
			{
				name: "पिकनिक स्पट",
				route: "/survey-by-institution/show/picnic",
				count: 2
			},
			{
				name: "होटल लज",
				route: "/survey-by-institution/show/hotel",
				count: 2
			},
			{
				name: "ताल",
				route: "/survey-by-institution/show/lake",
				count: 2
			}
		],
		icon: Place
	},
	{
		title: { english: "Resources", nepali: "स्रोत" },
		child: [
			{
				name: "खानी",
				route: "/survey-by-institution/show/mine",
				count: 2
			},
			{
				name: "वनक्षेत्र",
				route: "/survey-by-institution/show/forest",
				count: 2
			},
			{
				name: "जडीबुटी",
				route: "/survey-by-institution/show/herbs",
				count: 2
			},
			{
				name: "पुल",
				route: "/survey-by-institution/show/bridge",
				count: 2
			},
			{
				name: "मैदान",
				route: "/survey-by-institution/show/ground",
				count: 2
			},
			{
				name: "हाट बजार मार्केट",
				route: "/survey-by-institution/show/market",
				count: 2
			},
			{
				name: "वनस्पती",
				route: "/survey-by-institution/show/flora",
				count: 2
			},
			{
				name: "पानीको मुहान",
				route: "/survey-by-institution/show/waterResource",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Item", nepali: "संचार" },
		child: [
			{
				name: "संचार टावर",
				route: "/survey-by-institution/show/communication",
				count: 2
			},
			{
				name: "एफ.एम",
				route: "/survey-by-institution/show/fm",
				count: 2
			},
			{
				name: "पत्र पत्रिका",
				route: "/survey-by-institution/show/reporter",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Religious Place", nepali: "धार्मिक स्थल" },
		child: [
			{
				name: "मठ मन्दिर",
				route: "/survey-by-institution/show/temple",
				count: 2
			},
			{
				name: "गुम्बा",
				route: "/survey-by-institution/show/monastery",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Business", nepali: "व्यापार तथा व्यवसाय सम्बन्धी" },
		child: [
			{
				name: "मुख्य व्यवसाय",
				route: "/survey-by-institution/show/mainBusiness",
				count: 2
			},
			{
				name: "मुख्य आयात",
				route: "/survey-by-institution/show/mainImport",
				count: 2
			},
			{
				name: "मुख्य निर्यात",
				route: "/survey-by-institution/show/mainExport",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Festival", nepali: "चाड पर्व" },
		child: [
			{
				name: "जात्रा",
				route: "/survey-by-institution/show/festival",
				count: 2
			},
			{
				name: "मुख्य पर्वहरु",
				route: "/survey-by-institution/show/mainFestival",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Public Place", nepali: "सार्वजनिक स्थल" },
		child: [
			{
				name: "सवारी रुट (बसपार्क स्थल)",
				route: "/survey-by-institution/show/busPark",
				count: 2
			},
			{
				name: "सार्वजनिक शौचालय",
				route: "/survey-by-institution/show/publicToilet",
				count: 2
			},
			{
				name: "समाधि स्थल",
				route: "/survey-by-institution/show/graveYard",
				count: 2
			},
			{
				name: "सार्वजनिक जग्गा",
				route: "/survey-by-institution/show/publicLand",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Health Related", nepali: "स्वास्थ्य सम्बन्धी" },
		child: [
			{
				name: "स्वास्थ्य चौकी",
				route: "/survey-by-institution/show/healthPost",
				count: 2
			},
			{
				name: "पशु (भेटेनरी)/उपचार केन्द्र",
				route: "/survey-by-institution/show/animalVeterinary",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Natural Disaster", nepali: "प्राकृतिक प्रकोप" },
		child: [
			{
				name: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
				route: "/survey-by-institution/show/incidentLoss",
				count: 2
			},
			{
				name: "विपद जोखिम व्यवस्थापन",
				route: "/survey-by-institution/show/riskManagement",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Animal Livestock", nepali: "पशुपन्छी तथा चरण क्षेत्र" },
		child: [
			{
				name: "पशुपन्छी",
				route: "/survey-by-institution/show/animal",
				count: 2
			},
			{
				name: "चरण क्षेत्र",
				route: "/survey-by-institution/show/animalPlace",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Others", nepali: "अन्य" },
		child: [
			{
				name: "सुकुम्बासी",
				route: "/survey-by-institution/show/slum",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Project", nepali: "परियोजना" },
		child: [
			{
				name: "खानेपानी परियोजना",
				route: "/survey-by-institution/show/waterProject",
				count: 2
			},
			{
				name: "सिचाई परियोजना",
				route: "/survey-by-institution/show/irrigationProject",
				count: 2
			}
		],
		icon: Star
	},
	{
		title: { english: "Electicity", nepali: "विधुत" },
		child: [
			{
				name: "विधुतिकरणको अवस्था",
				route: "/survey-by-institution/show/electricSituation",
				count: 2
			},
			{
				name: "विधुत गृह",
				route: "/survey-by-institution/show/powerHouse",
				count: 2
			}
		],
		icon: Star
	}
];

// exports = { instituteFormName, addcategoryList, formControlData };
