import React, { Component } from "react";
import ListLog from "./ListLog";

export default class Home extends Component {
	state = {
		logs: []
	};

	render() {
		return (
			<div>
				<ListLog />
			</div>
		);
	}
}
