import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FullScreen from "@material-ui/icons/Fullscreen";
import randomMC from "random-material-color";
import React, { Component } from "react";
import { Polar } from "react-chartjs-2";
// import { Typography } from "@material-ui/core";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardHeader from "../../../../common/Card/CardHeader";
import CardIcon from "../../../../common/Card/CardIcon";

class ChartList extends Component {
	handleClickOpen = () => {
		this.props.handleClickOpen(this.props.name);
	};
	render() {
		const { classes, chartData, label, isFullScreen, noTitle } = this.props;
		return (
			<Card chart color="info" style={{ paddingBottom: 20 }}>
				<CardHeader color="success" stats icon>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						{!noTitle && (
							<Grid item>
								<CardIcon color="success" className={classes.iconWidth}>
									{chartData &&
										chartData.title &&
										(label ? <p>{label}</p> : <p>{chartData.title}</p>)}
								</CardIcon>
							</Grid>
						)}
						{isFullScreen && (
							<Grid item>
								<FullScreen
									onClick={this.handleClickOpen}
									className={classes.fullIcon}
								/>
							</Grid>
						)}
					</Grid>
					<Polar
						type="polar"
						data={{
							labels: chartData.label,
							datasets: [
								{
									label: "संख्या",
									data: chartData.data,
									backgroundColor: [...Array(chartData.data.length).keys()].map(
										() => randomMC.getColor()
									),
									borderWidth: 2
								}
							]
						}}
						options={{
							scales: {}
						}}
					/>
				</CardHeader>

				{/* {this.props.match && (
						<Link
							to={`${this.props.match.url}${chartData.route}`}
							className={classes.link}
						>
							View Details
						</Link>
					)} */}
			</Card>
		);
	}
}
ChartList.defaultProps = {
	isFullScreen: false,
	noTitle: false
};
export default withStyles(dashboardStyle)(ChartList);
