import { SET_LANGUAGE } from "../actions/types";
import isEmpty from "../utils/validation/is-empty";

const initialState = {
	language: "nepali",
	flag: ""
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SET_LANGUAGE:
			return {
				...state,
				language: action.payload,
				flag: ""
			};
		default:
			return state;
	}
}
