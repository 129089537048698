import React, { Component } from 'react'

class PopupHouse extends Component {
    render() {
        const {properties} = this.props; 
        return (
        <p>ghar</p>
            // <div>
            //     <img src={`/api/imageUpload/image/${properties.houseNumer}_house.jpg`} alt={properties.houseNumer} width={200} height={200}/>
            // </div>
        )
    }
}
export default PopupHouse;
