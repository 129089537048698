import { Grid, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { searchByNameTableHeader } from "../../../../../variable/global";
import NamePagination from "./NamePagination";

class GetFamilyMemberDetail extends Component {
	state = {
		page: 0,
		rowsPerPage: 10
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};

	render() {
		const { rowsPerPage, page } = this.state;
		const { memberList, name, classes } = this.props;
		return (
			<Grid container direction="column" style={{ marginTop: 20 }}>
				<NamePagination
					rows={memberList}
					rowsPerPage={rowsPerPage}
					page={page}
					title={this.props.title}
					subtitle=""
					tableHeader={searchByNameTableHeader}
					handleChangeRowsPerPage={this.handleChangeRowsPerPage}
					handleChangePage={this.handleChangePage}
				/>
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		padding: theme.spacing.unit * 4,
		marginTop: theme.spacing.unit * 5
	},
	centerText: {
		textAlign: "center"
	}
});

GetFamilyMemberDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GetFamilyMemberDetail);
