import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { addcategoryList } from "./config";
import InstitutionForm from "./FormController";

class EditInstitutionSurvey extends Component {
	state = {
		category: -1
	};

	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value
		});
	};

	render() {
		const { classes } = this.props;
		const categoryList = addcategoryList;
		return (
			<div>
				{/* <InstitutionSurvey
					handleSelectChange={this.handleSelectChange}
					category={this.state.category}
				/> */}

				<InstitutionForm
					isUpdate={true}
					category={categoryList.findIndex(
						each => each == this.props.match.params.category
					)}
				/>
			</div>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

export default withStyles(styles)(EditInstitutionSurvey);
