import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	bhattaLists,
	bhattaQuestion,
	childsecurityFemale,
	childsecurityMale,
	dalitFemale,
	dalitMale,
	rareCasteMale,
	rareCasteFemale,
	disableMen,
	disableWomen,
	halfdisableMen,
	halfdisableWomen,
	oldcitizenFemale,
	oldcitizenMale,
	singleWomen,
	welfareYesNo,
	welfareYesNoQuestion
} from "../../../../variable/houseSurvey";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";
import CheckBox from "../../../common/frequent/CheckBox";
import BoxBorder from "../../../common/frequent/BoxBorder";
class SocialWelfare extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`socialWelfare.${name}`, value);
	};
	render() {
		const { socialWelfare } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container direction="column">
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={welfareYesNoQuestion}
						name="welfareYesNo"
						radioLists={welfareYesNo}
						value={socialWelfare.welfareYesNo}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						socialWelfare.welfareYesNo &&
						socialWelfare.welfareYesNo.includes("social_yes")
					}
				>
					<Grid container>
						<CheckBox
							error={errors && errors.bhattaLists}
							question={bhattaQuestion}
							name="bhattaLists"
							value={socialWelfare.bhattaLists}
							checkList={bhattaLists}
							isVisible
							handleCompChange={this.handleChange}
							other={socialWelfare.socialWelfareOther}
						/>
					</Grid>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("old_citizen")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.oldcitizenMale}
								question={oldcitizenMale}
								name="oldcitizenMale"
								type="number"
								require
								placeholder="ज्येष्ठ नागरिक पुरुष संख्या"
								value={socialWelfare.oldcitizenMale}
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("old_citizen")
								}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.oldcitizenFemale}
								question={oldcitizenFemale}
								name="oldcitizenFemale"
								type="number"
								require
								placeholder="ज्येष्ठ नागरिक महिला संख्या"
								value={socialWelfare.oldcitizenFemale}
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("old_citizen")
								}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("single_women")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.singleWomenFemale}
								question={singleWomen}
								name="singleWomenFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("single_women")
								}
								placeholder="एकल महिला संख्या"
								value={socialWelfare.singleWomenFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("full_disable")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.disableMale}
								question={disableMen}
								name="disableMale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("full_disable")
								}
								placeholder="पूर्ण अपाङ्ग पुरुष संख्या"
								value={socialWelfare.disableMale}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.disableFemale}
								question={disableWomen}
								name="disableFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("full_disable")
								}
								placeholder="पूर्ण अपाङ्ग महिला संख्या"
								value={socialWelfare.disableFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("half_disable")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.halfdisableMale}
								question={halfdisableMen}
								name="halfdisableMale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("half_disable")
								}
								placeholder="आंसिक अपाङ्ग पुरुष संख्या"
								value={socialWelfare.halfdisableMale}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.halfdisableFemale}
								question={halfdisableWomen}
								name="halfdisableFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("half_disable")
								}
								placeholder="आंसिक अपाङ्ग महिला संख्या"
								value={socialWelfare.halfdisableFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("child_security")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.childsecurityMale}
								question={childsecurityMale}
								name="childsecurityMale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("child_security")
								}
								placeholder="बाल सुरक्षा अनुदान पुरुष संख्या"
								value={socialWelfare.childsecurityMale}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.childsecurityFemale}
								question={childsecurityFemale}
								name="childsecurityFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("child_security")
								}
								placeholder="बाल सुरक्षा अनुदान महिला संख्या"
								value={socialWelfare.childsecurityFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("dalit")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.dalitMale}
								question={dalitMale}
								name="dalitMale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("dalit")
								}
								placeholder="दलित वर्ग पुरुष संख्या"
								value={socialWelfare.dalitMale}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.dalitFemale}
								question={dalitFemale}
								name="dalitFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("dalit")
								}
								placeholder="दलित वर्ग महिला संख्या"
								value={socialWelfare.dalitFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							socialWelfare.bhattaLists &&
							socialWelfare.bhattaLists.includes("rare_caste")
						}
					>
						<Grid container>
							<TextField
								error={errors && errors.rareCasteMale}
								question={rareCasteMale}
								name="rareCasteMale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("rare_caste")
								}
								placeholder="लोपोन्मुख पुरुष संख्या"
								value={socialWelfare.rareCasteMale}
								handleChange={this.handleChange}
							/>
							<TextField
								error={errors && errors.rareCasteFemale}
								question={rareCasteFemale}
								name="rareCasteFemale"
								require
								isVisible={
									socialWelfare.bhattaLists &&
									socialWelfare.bhattaLists.includes("rare_caste")
								}
								placeholder="लोपोन्मुख महिला संख्या"
								value={socialWelfare.rareCasteFemale}
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>

				{/* <Grid container>
					<CheckBox
						question={herbListQuestion}
						name="herbs"
						checkValue={business.check}
						textValue={business.text}
						checkList={loan}
						textList={rate}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid> */}

				{/* <Grid container>
					<TextField
						question={herbListQuestion}
						name="text"
						require
						placeholder="19"
						value={text}
						isVisible={anyHerb === "herbs_yes"}
						handleChange={this.handleChange}
					/>
				</Grid>

				<Grid container>
					<Selection
						question={herbListQuestion}
						name="text"
						selectList={herbLists}
						value={text}
						isVisible={anyHerb === "herbs_yes"}
						handleChange={this.handleChange}
					/>
				</Grid> */}
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing.unit * 3
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
		display: "flex"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

SocialWelfare.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SocialWelfare);
