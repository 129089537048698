import { withStyles } from "@material-ui/core/styles";
import React, { Component, Fragment } from "react";
import "./bibran.css";
import DetailAnalysis from "./DetailAnalysis";

class Home extends Component {
	render() {
		const { classes } = this.props;
		return (
			<Fragment>
				<DetailAnalysis />
			</Fragment>
		);
	}
}

const styles = theme => ({
	infoRow: {
		display: "flex",
		alignItems: "left",
		paddingTop: 10,
		paddingBottom: 10
	},
	border: {
		borderBottom: "1px solid #ddd"
	},
	title: {
		fontSize: "1rem",
		fontWeight: 500,
		marginLeft: 10,
		marginRight: 10,
		cursor: "pointer",
		color: "#2196f3"
	}
});

export default withStyles(styles)(Home);
