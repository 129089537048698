import { Grid, IconButton, Modal } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Select,
	Typography,
	Upload
} from "antd";
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstituteSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { selectedLanguage, ward } from "../../../variable/global";
import {
	add,
	address,
	area,
	buildingCount,
	consumedUser,
	count,
	description,
	emergencyNo,
	instituteTitle,
	instituteType,
	latitude,
	longitude,
	memberTitle,
	name,
	phone,
	photo,
	position,
	specName,
	specTitle,
	specValue,
	staffTitle,
	vehicleTitle,
	wardQuestion
} from "../../../variable/institute";

const { TextArea } = Input;
const { Option } = Select;

class Institute extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};
	state = {
		errors: {},
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			instituteImage: []
		},
		loaded: 0,
		// other
		name: "",
		address: "",
		totalStaff: "",
		emergencyNumber: "",
		phoneNumber: "",
		consumedUser: "",
		area: "",
		latitude: "",
		longitude: "",
		photo: "",
		ward: "",
		description: "",
		numberOfBuilding: "",
		institutionType: "",
		spec: [],
		maxSpecIndex: 1,
		vehicle: [],
		maxVehicleIndex: 1,
		member: [],
		maxMemberIndex: 1,
		isView: this.props.location.view
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => message.error("upload failed."));
	};

	componentDidMount() {
		let instituteId = this.props.match.params.id;
		if (instituteId)
			Axios.get(`/api/institute/fetch/${instituteId}`)
				.then(res => {
					this.setState({
						name: res.data.name,
						address: res.data.address,
						category: res.data.category,
						totalStaff: res.data.totalStaff,
						emergencyNumber: res.data.emergencyNumber,
						phoneNumber: res.data.phoneNumber,
						ward: res.data.ward,
						consumedUser: res.data.consumedUser,
						area: res.data.area,
						numberOfBuilding: res.data.numberOfBuilding,
						maxVehicleIndex: res.data.vehicle.length,
						vehicle: res.data.vehicle,
						maxSpecIndex:
							res.data.specification && res.data.specificationlength,
						spec: res.data.specification,
						latitude: res.data.geoLocation && res.data.geoLocation.latitude,
						longitude: res.data.geoLocation && res.data.geoLocation.longitude,
						member: res.data.member,
						description: res.data.description,
						institutionType: res.data.institutionType,
						// image
						fileList: {
							tourism: {
								fileName: res.data.uniqueIdentifier + "_institute.jpg",
								instituteImage: [
									{
										uid: "-1",
										name: `${res.data.uniqueIdentifier}_institute.jpg`,
										status: "done",
										url: `/api/imageUpload/image/${
											res.data.uniqueIdentifier
										}_institute.jpg`
									}
								]
							}
						}
					});
				})
				.catch(err => {});
	}

	onSubmit = event => {
		event.preventDefault();
		const newInstitute = {
			name: this.state.name,
			uniqueIdentifier: this.state.uniqueIdentifier,
			ward: this.state.ward,
			address: this.state.address,
			totalStaff: this.state.totalStaff,
			emergencyNumber: this.state.emergencyNumber,
			phoneNumber: this.state.phoneNumber,
			consumedUser: this.state.consumedUser,
			area: this.state.area,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			description: this.state.description,
			numberOfBuilding: this.state.numberOfBuilding,
			institutionType: this.state.institutionType,
			specification: this.state.spec,
			vehicle: this.state.vehicle,
			member: this.state.member,
			category: this.props.category
		};

		if (this.props.isUpdate) newInstitute.id = this.props.match.params.id;
		this.props
			.addInstituteSurvey(newInstitute)
			.then(res => {
				if (res.type === "SAVE_INSTITUTE") {
					message.success("Sucessfully saved");
					this.props.history.push(
						`/survey-by-institution/show/${this.props.match.params.category}`
					);
				}
			})
			.catch(err => {
				message.error("Cannot Saved at the moment");
				if (err) console.log(err.response.data);
				this.setState({ errors: err.response.data });
			});
	};
	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};
	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value
		});
	};
	addSpec = () => {
		this.setState(prevState => ({
			maxSpecIndex: prevState.maxSpecIndex + 1
		}));
	};

	addVehicle = () => {
		this.setState(prevState => ({
			maxVehicleIndex: prevState.maxVehicleIndex + 1
		}));
	};

	addMember = () => {
		this.setState(prevState => ({
			maxMemberIndex: prevState.maxMemberIndex + 1
		}));
	};

	handleNameSpec = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.name = name;
			data.value = isEmpty(spec[index]) ? "" : spec[index].value;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleValueSpec = index => event => {
		const value = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.value = value;
			data.name = isEmpty(spec[index]) ? "" : spec[index].name;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleNameVehicle = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const vehicle = [...prevState.vehicle];

			const data = {};
			data.name = name;
			data.count = isEmpty(vehicle[index]) ? "" : vehicle[index].count;
			vehicle.splice(index, 1, data);
			return { vehicle };
		});
	};

	handleCountVehicle = index => event => {
		const count = event.target.value;
		console.log("value", count);
		this.setState(prevState => {
			const vehicle = [...prevState.vehicle];

			const data = {};
			data.count = count;
			data.name = isEmpty(vehicle[index]) ? "" : vehicle[index].name;
			vehicle.splice(index, 1, data);
			return { vehicle };
		});
	};

	handleNameMember = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const member = [...prevState.member];

			const data = {};
			data.name = name;
			data.phone = isEmpty(member[index]) ? "" : member[index].phone;
			data.position = isEmpty(member[index]) ? "" : member[index].position;
			member.splice(index, 1, data);
			return { member };
		});
	};

	handlePhoneMember = index => event => {
		const phone = event.target.value;
		this.setState(prevState => {
			const member = [...prevState.member];

			const data = {};
			data.phone = phone;
			data.name = isEmpty(member[index]) ? "" : member[index].name;
			data.position = isEmpty(member[index]) ? "" : member[index].position;
			member.splice(index, 1, data);
			return { member };
		});
	};

	handlePositionMember = index => event => {
		const position = event.target.value;
		this.setState(prevState => {
			const member = [...prevState.member];

			const data = {};
			data.position = position;
			data.name = isEmpty(member[index]) ? "" : member[index].name;
			data.phone = isEmpty(member[index]) ? "" : member[index].phone;
			member.splice(index, 1, data);
			return { member };
		});
	};

	removeSpec = index => () => {
		this.setState(prevState => {
			const spec = [...prevState.spec];
			spec.splice(index, 1);
			return { spec, maxSpecIndex: prevState.maxSpecIndex - 1 };
		});
	};

	removeVehicle = index => () => {
		this.setState(prevState => {
			const vehicle = [...prevState.vehicle];
			vehicle.splice(index, 1);
			return { vehicle, maxVehicleIndex: prevState.maxVehicleIndex - 1 };
		});
	};

	removeMember = index => () => {
		this.setState(prevState => {
			const member = [...prevState.member];
			member.splice(index, 1);
			return { member, maxMemberIndex: prevState.maxMemberIndex - 1 };
		});
	};

	getSpecUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxSpecIndex, spec } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxSpecIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={spec && spec[each] && spec[each].name}
										onChange={this.handleNameSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={specValue[selectedLanguage]}>
									<Input
										name="value"
										type="text"
										value={spec && spec[each] && spec[each].value}
										onChange={this.handleValueSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeSpec(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addSpec}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	getVehicle = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxVehicleIndex, vehicle } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxVehicleIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={vehicle && vehicle[each] && vehicle[each].name}
										onChange={this.handleNameVehicle(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={count[selectedLanguage]}>
									<Input
										name="count"
										type="number"
										value={vehicle && vehicle[each] && vehicle[each].count}
										onChange={this.handleCountVehicle(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeVehicle(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addVehicle}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	getMember = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxMemberIndex, member } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxMemberIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={5}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={member && member[each] && member[each].name}
										onChange={this.handleNameMember(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={5}>
								<Form.Item label={phone[selectedLanguage]}>
									<Input
										name="phone"
										type="number"
										value={member && member[each] && member[each].phone}
										onChange={this.handlePhoneMember(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={5}>
								<Form.Item label={position[selectedLanguage]}>
									<Input
										name="position"
										type="text"
										value={member && member[each] && member[each].position}
										onChange={this.handlePositionMember(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeMember(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addMember}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage, errors } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item
							validateStatus={errors.name ? "error" : ""}
							help={errors.name || ""}
							label={name[selectedLanguage]}
						>
							<Input
								required
								onChange={this.handleChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={address[selectedLanguage]}>
							<Input
								onChange={this.handleChange("address")}
								value={this.state.address}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={staffTitle[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("totalStaff")}
								value={this.state.totalStaff}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={phone[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("phoneNumber")}
								value={this.state.phoneNumber}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={consumedUser[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("consumedUser")}
								value={this.state.consumedUser}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={emergencyNo[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("emergencyNumber")}
								value={this.state.emergencyNumber}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={area[selectedLanguage]}>
							<Input
								type="number"
								addonAfter="square meter"
								onChange={this.handleChange("area")}
								value={this.state.area}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={latitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("latitude")}
								value={this.state.latitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={longitude[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("longitude")}
								value={this.state.longitude}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={photo[selectedLanguage]}>
							<Fragment>
								{!this.state.isView ? (
									<Upload
										name="file"
										// beforeUpload={this.props.beforeUpload}
										multiple={false}
										// action="/api/imageUpload/upload"
										accept="image/*"
										listType="picture-card"
										fileList={
											fileList.instituteImage && fileList.instituteImage.image
										}
										onPreview={this.handlePreview}
										onChange={fileList =>
											this.handleChangeFile(
												"instituteImage",
												fileList,
												"institute"
											)
										}
										disabled={this.state.isView}
									>
										{fileList &&
										fileList.instituteImage &&
										fileList.instituteImage.image &&
										fileList.instituteImage.image.length === 1
											? null
											: uploadButton}
									</Upload>
								) : (
									<Modal
										visible={previewVisible}
										footer={null}
										onCancel={this.handleCancel}
									>
										<img
											alt="example"
											style={{ width: "100%" }}
											src={previewImage}
										/>
									</Modal>
								)}
							</Fragment>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item
							validateStatus={errors.ward ? "error" : ""}
							help={errors.ward || ""}
							label={wardQuestion[selectedLanguage]}
						>
							<Select
								required
								placeholder="------------"
								onChange={val => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map(woda => (
									<Option value={woda.value}>{woda[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={buildingCount[selectedLanguage]}>
							<Input
								type="number"
								onChange={this.handleChange("numberOfBuilding")}
								value={this.state.numberOfBuilding}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Divider orientation="left">{vehicleTitle[selectedLanguage]}</Divider>
					{this.getVehicle()}
					<Divider orientation="left">{memberTitle[selectedLanguage]}</Divider>
					{this.getMember()}
					<Divider orientation="left">{specTitle[selectedLanguage]}</Divider>
					{this.getSpecUI()}
					<Divider />

					<Grid xs={6}>
						<Form.Item label={description[selectedLanguage]}>
							<TextArea
								onChange={this.handleChange("description")}
								value={this.state.description}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item label={instituteTitle[selectedLanguage]}>
							<Select
								placeholder="------------"
								onChange={val =>
									this.handleSelectChange("institutionType", val)
								}
								value={this.state.institutionType}
								disabled={this.state.isView}
							>
								{instituteType.map(type => (
									<Option value={type.value}>{type[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ addInstituteSurvey }
	)(withRouter(Institute))
);
