import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clearHouseDetail,
	getHouseByHouseNumber
} from "../../../actions/searchAction";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import GetHouseDetail from "../search/advancedHouseSearch/houseNumber/GetHouseDetail";
import TablePaginationActionsWrapped from "../search/death/TablePaginationActions";

class ListHouse extends Component {
	state = {
		page: 0,
		house: {},
		rowsPerPage: 100,
		houseOpen: false
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value });
	};

	onHouseNumberClick = event => {
		const houseNumber = event.target.id;
		this.setState({ houseOpen: true, houseNumber }, () => {
			this.props.getHouseByHouseNumber(houseNumber);
		});
	};

	handleCloseHouse = name => {
		this.setState({ [name]: false, house: {} });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.house !== this.props.house) {
			this.setState({
				house: this.props.house
			});
		}
	}

	render() {
		console.error("each house", this.state.house);
		const { classes, houses } = this.props;
		const { page, rowsPerPage } = this.state;
		return (
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>
							<Typography variant="h6">क्र.श</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">वडा न.</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">घर नम्बर</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">डेटा सङ्कलन कोड</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">उत्तरदाताको नाम</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">उत्तरदाताको घरमुलीसंगको नाता</Typography>
						</TableCell>
						<TableCell>
							<Typography variant="h6">क्रियाकलाप</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{houses
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((house, id) => {
							return (
								<TableRow key={id}>
									<TableCell component="th" scope="row">
										<Typography variant="body1">
											{getNepaliNumber(page * rowsPerPage + (id + 1))}
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography variant="body1">
											{getNepaliNumber(house.wardNumber)}
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography variant="body1">
											{getNepaliNumber(house.houseNumber)}
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography variant="body1">
											{house.dataCollectionCode}
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography variant="body1">
											{house.answererName}
										</Typography>
									</TableCell>
									<TableCell align="left">
										<Typography variant="body1">
											{house.answererRelation}
										</Typography>
									</TableCell>

									<TableCell align="left">
										<VisibilityIcon
											id={house.houseNumber}
											onClick={this.onHouseNumberClick}
											className={classnames(classes.preview, classes.icon)}
										/>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
							colSpan={7}
							count={houses.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								native: true
							}}
							onChangePage={this.handleChangePage}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActionsWrapped}
						/>
					</TableRow>
				</TableFooter>
				{this.state.houseOpen && (
					<FullScreenDialog
						name="houseOpen"
						open={this.state.houseOpen}
						title={`House Detail `}
						handleClose={this.handleCloseHouse}
					>
						<Grid container spacing={24}>
							{this.props.load ? (
								<Grid
									style={{ marginTop: "40vh" }}
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={this.props.load}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="flex-start"
								>
									{this.state.house && (
										<Grid item xs={9}>
											<GetHouseDetail
												houseNumber={this.state.houseNumber}
												house={this.state.house}
											/>
										</Grid>
									)}
								</Grid>
							)}
						</Grid>
					</FullScreenDialog>
				)}
			</Table>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	},
	icon: {
		margin: theme.spacing.unit * 1,
		cursor: "pointer"
	},
	preview: {
		color: lightBlue[500]
	},
	edit: {
		color: green[700]
	},
	delete: {
		color: red[500]
	}
});

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load
});
export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getHouseByHouseNumber, clearHouseDetail }
	)(ListHouse)
);
