const reportList = [
	{
		nepali: "शिक्षा सम्बन्धी विवरण",
		english: "Education detail",
		value: "education",
		title: "family"
	},
	{
		nepali: "अपाङ्गता सम्बन्धी विवरण र जनसंख्या",
		english: "Disable detail and population",
		value: "disable",
		title: "family"
	},
	{
		nepali: "अपाङ्गता परिचय पत्रको आधारमा",
		english: "Disable according to cards",
		value: "disableCards",
		title: "family"
	},
	{
		nepali: "धर्म सम्बन्धी विवरण",
		english: "Religion detail",
		value: "religion",
		title: "family"
	},
	{
		nepali: "बैवाहिक अवस्था सम्बन्धी विवरण",
		english: "Martial detail",
		value: "martial",
		title: "family"
	},
	{
		nepali: "जातजाती सम्बन्धी विवरण",
		english: "Caste detail",
		value: "ethnics",
		title: "family"
	},
	{
		nepali: "पेशा सम्बन्धी विवरण",
		english: "Occupation detail",
		value: "occupation",
		title: "family"
	},
	{
		nepali: "स्वास्थ्य सम्बन्धी विवरण",
		english: "Health detail",
		value: "health",
		title: "family"
	},
	{
		nepali: "मातृभाषा / बोलिने भाषा सम्बन्धी विवरण",
		english: "Mothertongue",
		value: "mothertongue",
		title: "family"
	},
	{
		nepali: "बसोवास स्थान सम्बन्धी विवरण",
		english: "Living place",
		value: "livingtype",
		title: "family"
	},
	{
		nepali: "मतदाता परिचय पत्र",
		english: "Voter card",
		value: "voterCard",
		title: "family"
	},
	{
		nepali: "विदेश गएको विवरण",
		english: "Aboard",
		value: "abroad",
		title: "family"
	},
	{
		nepali: "उमेर अनुसारको विवरण",
		english: "Age",
		value: "age",
		title: "family"
	},
	{
		nepali: "रगत समुहको विवरण",
		english: "Blood Group",
		value: "bloodGroup",
		title: "family"
	},
	{
		nepali: "मृत्यु हुनेको उमेर अनुसारको विवरण",
		english: "Death Age",
		value: "deathAge",
		title: "family"
	},
	{
		nepali: "मृत्यु हुनेको कारण अनुसारको विवरण",
		english: "Death Reason",
		value: "deathDisease",
		title: "family"
	},
	{
		nepali: "शौचालय सम्बन्धी विवरण",
		english: "Toilet",
		value: "toilet",
		title: "house"
	},
	{
		nepali: "खानेपानीको मुख्य श्रोत विवरण",
		english: "Water resource",
		value: "waterresource",
		title: "house"
	},
	{
		nepali: "वालवालिकामा पोषण सम्बन्धी विवरण",
		english: "Child nitrution",
		value: "childNutrition",
		title: "house"
	},
	{
		nepali: "परिवारमा सामाजिक सुरक्षा भत्ता विवरण",
		english: "Social security",
		value: "bhatta",
		title: "house"
	},
	{
		nepali: "परिवारलाई कति महिना खान पुग्छ विवरण",
		english: "Food",
		value: "foodformonth",
		title: "house"
	},
	{
		nepali: "सिंचाईको माध्यम विवरण",
		english: "Irriagtion detail",
		value: "irrigation",
		title: "house"
	},
	{
		nepali: "बीमा विवरण",
		english: "Insurance detail",
		value: "insurance",
		title: "house"
	},
	{
		nepali: "घरको छानाको बनौट विवरण",
		english: "Roof type",
		value: "rooftype",
		title: "house"
	},
	{
		nepali: "साबुनपानीले हात धुने सम्बन्धी विवरण",
		english: "Soap HandWash",
		value: "soapHandWash",
		title: "house"
	},
	{
		nepali: "घरको स्वामित्वको विवरण",
		english: "House Used Living",
		value: "houseUsedLiving",
		title: "house"
	},
	{
		nepali: "परिवारमा मदिरा सेवन गर्नेको विवरण",
		english: "Alcohol Usages in Family",
		value: "alcoholUsage",
		title: "house"
	},
	{
		nepali: "मदिरा सेवन कार्डको विवरण",
		english: "Alcohol Card Detail",
		value: "alcoholCard",
		title: "house"
	},
	{
		nepali: "मदिरा सेवन कार्डको प्रयोगले प्रभाव पारेको विवरण",
		english: "Effective from alcohol card use",
		value: "effectiveFromCard",
		title: "house"
	},
	{
		nepali: "मदिरा सेवनले परिवारमा समस्या पारेको विवरण",
		english: "Issue in family from alcohol",
		value: "issueFromAlcohol",
		title: "house"
	},
	{
		nepali: "मदिरा सेवनले मृत्यू भएको विवरण",
		english: "Death from alcohol in family detail",
		value: "alcoholDeath",
		title: "house"
	},
	{
		nepali: "घरको सुविधाहरुको विवरण",
		english: "House Facility",
		value: "houseFacility",
		title: "house"
	},
	{
		nepali: "घर संख्याको विवरण",
		english: "House Count",
		value: "houseCount",
		title: "house"
	},
	{
		nepali: "पशु पालन सम्बन्धी विवरण",
		english: "Animal Livestock",
		value: "animalCount",
		title: "house"
	},
	{
		nepali: "पन्छी पालन सम्बन्धी विवरण",
		english: "Bird Livestock",
		value: "birdCount",
		title: "house"
	}
];

module.exports = {
	reportList
};
