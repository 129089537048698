const name = { nepali: "नाम", english: "Name" };
const category = { nepali: "कुन किसिम", english: "Category" };
const establish = { nepali: "स्थापना", english: "Establish" };
const description = { nepali: "विवरण", english: "description" };
const member = { nepali: "कर्मचारी संख्या", english: "Member" };
const address = { nepali: "ठेगाना", english: "Address" };
const phone = { nepali: "फोन नम्बर", english: "phone" };
const designation = { nepali: "पद", english: "designation" };
const count = { nepali: "संख्या", english: "count" };
const latitude = { nepali: "अक्षांश", english: "latitude" };
const longitude = { nepali: "देशान्तर", english: "longitude" };
const wardQuestion = { nepali: "वडा नम्बर", english: "ward number" };
const photo = { nepali: "फोटो", english: "photo" };
const specName = { nepali: "विशेषता", english: "photo" };
const specValue = { nepali: "विशेषता", english: "photo" };
const add = { nepali: "थप्नुहोस", english: "photo" };
const darta = { nepali: "दर्ता भयो", english: "darta" };
const itemTitle = { nepali: "", english: "" };
const area = { nepali: "क्षेत्रफल", english: "" };
const kachiCount = { nepali: "कच्ची संख्या", english: "" };
const pakkiCount = { nepali: "पक्की संख्या", english: "" };
const kachiRoomCount = { nepali: "कच्ची कोठा संख्या", english: "" };
const pakkiRoomCount = { nepali: "पक्की कोठा संख्या", english: "" };
const gharTitle = { nepali: "घर संख्या", english: "No of House" };
const roomTitle = { nepali: "कोठा संख्या", english: "No of room" };
const benchCapacity = { nepali: "बेन्चको क्षमता", english: "Bench Capacity" };
const ground = { nepali: "खेल मैदान", english: "Play ground" };
const compound = { nepali: "क्षेत्र", english: "Area" };
const childplayground = {
	nepali: "बाल मैत्री मैदान",
	english: "Children playground"
};
const toilet = { nepali: "शौचालय", english: "Toilet" };
const hastoilet = { nepali: "शौचालय छ/छैन", english: "Toilet yes / no" };
const maleCount = {
	nepali: "पुरुष शौचालय संख्या",
	english: "No of jeans toilet"
};
const femaleCount = {
	nepali: "महिला शौचालय संख्या",
	english: "No of female toilet"
};
const staffCount = {
	nepali: "कर्मचारी शौचालय संख्या",
	english: "No of staff toilet"
};
const staffTitle = { nepali: "कर्मचारी संख्या", english: "No of employee" };
const hasdrinking = {
	nepali: "खानेपानी छ/छैन",
	english: "Drinking water yes/no"
};
const appointAs = { nepali: "नियुक्ती", english: "Appoint" };
const staff = { nepali: "कर्मचारी", english: "Employee" };
const student = { nepali: "विद्यार्थी", english: "Student" };
const schoolClass = { nepali: "कक्षा", english: "Class" };
const malestudent = { nepali: "छात्र", english: "Male student" };
const femalestudent = { nepali: "छात्रा", english: "Female student" };
const website = { nepali: "वेबसाईट", english: "Website" };
const email = { nepali: "ईमेल", english: "Email" };
const schooltype = { nepali: "बिद्यालय प्रकार", english: "Type of school" };
const schoollevel = { nepali: "बिद्यालय तह", english: "School level" };
const specTitle = { nepali: "विशेषता भर्नुहोस", english: "" };
const position = { nepali: "पद", english: "Postiton" };
const emergencyNo = { nepali: "आकस्मिक नम्बर", english: "Emergency No" };
const instituteTitle = { nepali: "संस्थान प्रकार", english: "Instution type" };
const buildingCount = { nepali: "भवनको संख्या", english: "No of building" };
const consumedUser = {
	nepali: "लाभान्वित संख्या",
	english: "Profitable family"
};
const vehicleTitle = {
	nepali: "सवारी साधन",
	english: "Means of transportation"
};
const memberTitle = { nepali: "सदस्य विवरण", english: "Member detail" };
const clubActive = { nepali: "सकृय छ / छैन", english: "Active yes / no" };
const categoryTitle = { nepali: "वर्ग", english: "category" };
const suffixCategoryList = { english: "'s details", nepali: " को विवरण" };
const fiscal = { nepali: "आर्थिक बर्ष", english: "Fiscal Year" };
const dartachalani = { nepali: "दर्ता चलनी नं", english: "Darta Chalani No" };
const budget = { nepali: "बजेट /लागत", english: "Budget" };
const timespan = { nepali: "समय अवधी", english: "Timespan" };
const studentProd = { nepali: "वडगत लाभान्वित जन्संख्या", english: "Timespan" };
const staffProd = { nepali: "शिक्षक/शिक्षिका संख्या", english: "Timespan" };
const targetedCategory = {
	nepali: "लक्षित वर्ग",
	english: "Targeted Category"
};
const helper = { nepali: "सहयोगी संस्था", english: "Helping Organization" };
const effectedHouse = {
	nepali: "लाभान्वित घरधुरी",
	english: "Effected Household"
};
const pdf = { nepali: "पिडिएफ", english: "PDF" };
const categoryList = [
	{
		nepali: "स्कूल",
		english: "School",
		value: "school"
	},
	{
		nepali: "कलेज",
		english: "College",
		value: "college"
	},
	{
		nepali: "प्रौड शिक्षा",
		english: "Prod Education",
		value: "prodEducation"
	},
	{
		nepali: "सरकारी संस्थान / गैर सरकारी संस्थान",
		english: "NGO/INGO",
		value: "ngo"
	},
	{
		nepali: "प्रहरी चौकी",
		english: "Police Station",
		value: "police"
	},
	{
		nepali: "बैंक र वित्तीय संस्था",
		english: "Bank And Finance",
		value: "bank"
	},
	{
		nepali: "सामुदायिक भवन",
		english: "Community Hall",
		value: "communityHall"
	},
	{
		nepali: "हुलाक",
		english: "Post Office",
		value: "postOffice"
	},
	{
		nepali: "स्वास्थ्य चौकी",
		english: "Health Post",
		value: "healthPost"
	},
	{
		nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
		english: "Animal Veterinary",
		value: "animalVeterinary"
	},
	{
		nepali: "पर्यटकिय  क्षेत्र",
		english: "Tourist Place",
		value: "touristPlace"
	},
	{
		nepali: "ऐतिहासिक स्थल",
		english: "Ancient Place",
		value: "ancientPlace"
	},
	{
		nepali: "पार्क",
		english: "Park",
		value: "park"
	},
	{
		nepali: "पिकनिक स्पट",
		english: "Picnic",
		value: "picnic"
	},
	{
		nepali: "होटल र लज",
		english: "Hotel And Lodge",
		value: "hotel"
	},
	{
		nepali: "ताल",
		english: "Lake",
		value: "lake"
	},
	{
		nepali: "खानी",
		english: "Mine",
		value: "mine"
	},
	{
		nepali: "वनक्षेत्र",
		english: "Forest",
		value: "forest"
	},
	{
		nepali: "जडीबुटी",
		english: "Herbs",
		value: "herbs"
	},
	{
		nepali: "पुल",
		english: "Bridge",
		value: "bridge"
	},
	{
		nepali: "मैदान",
		english: "Ground",
		value: "ground"
	},
	{
		nepali: "हाट बजार मार्केट",
		english: "Market",
		value: "market"
	},
	{
		nepali: "वनस्पति",
		english: "Flora",
		value: "flora"
	},
	{
		nepali: "पानीको मुहान",
		english: "Water Resource",
		value: "waterResource"
	},
	{
		nepali: "संचार टावर",
		english: "Communication Tower",
		value: "communication"
	},
	{
		nepali: "एफ.एम",
		english: "Fm",
		value: "fm"
	},
	{
		nepali: "पत्र पत्रिका",
		english: "Reporter",
		value: "reporter"
	},
	{
		nepali: "मठ मन्दिर",
		english: "Temple",
		value: "temple"
	},
	{
		nepali: "गुम्बा",
		english: "Monastery",
		value: "monastery"
	},
	{
		nepali: "मुख्य व्यवसाय",
		english: "Main Business",
		value: "mainBusiness"
	},
	{
		nepali: "मुख्य आयात",
		english: "Main Import",
		value: "mainImport"
	},
	{
		nepali: "मुख्य निर्यात",
		english: "Main Export",
		value: "mainExport"
	},
	{
		nepali: "जात्रा",
		english: "Festival",
		value: "festival"
	},
	{
		nepali: "मुख्य पर्वहरु",
		english: "Main Festival",
		value: "mainFestival"
	},
	{
		nepali: "सवारी रुट (बसपार्क स्थल)",
		english: "Bus Park",
		value: "busPark"
	},
	{
		nepali: "सार्वजनिक शौचालय",
		english: "Public Toilet",
		value: "publicToilet"
	},
	{
		nepali: "समाधि स्थल",
		english: "Grave Yard",
		value: "graveYard"
	},
	{
		nepali: "सार्वजनिक जग्गा",
		english: "Public Land",
		value: "publicLand"
	},
	{
		nepali: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
		english: "Incident Loss",
		value: "incidentLoss"
	},
	{
		nepali: "विपद जोखिम व्यवस्थापन",
		english: "Risk Management",
		value: "riskManagement"
	},
	{
		nepali: "पशुपन्छी",
		english: "Animal",
		value: "animal"
	},
	{
		nepali: "चरण क्षेत्र",
		english: "Animal Place",
		value: "animalPlace"
	},
	{
		nepali: "सुकुम्बासी",
		english: "Slum",
		value: "slum"
	},
	{
		nepali: "तालिम केन्द्र",
		english: "Trainning Institute",
		value: "trainningInstitute"
	},
	{
		nepali: "विधुतिकरणको अवस्था",
		english: "Electricity Situation",
		value: "electricSituation"
	},
	{
		nepali: "विधुत गृह",
		english: "Power House",
		value: "powerHouse"
	},
	{
		nepali: "फुटबल क्लब",
		english: "Football Club",
		value: "footballClub"
	},
	{
		nepali: "युथ क्लब / युवा क्लब",
		english: "Youth Club",
		value: "youthClub"
	},
	{
		nepali: "कृषक समूह",
		english: "Farmer Club",
		value: "farmerClub"
	},
	{
		nepali: "आमा समूह",
		english: "Mothers Club",
		value: "mothersClub"
	},
	{
		nepali: "सामाजिक समूह",
		english: "Social Club",
		value: "socialClub"
	},
	{
		nepali: "उधमी समूह",
		english: "Business Club",
		value: "businessClub"
	},
	{
		nepali: "वचत समूह",
		english: "Saving Club",
		value: "savingClub"
	},
	{
		nepali: "व्यवसायिक कृषक समूह",
		english: "Farmer Business Club",
		value: "farmerBusiness"
	},
	{
		nepali: "परम्परागत समूह",
		english: "Traditional Club",
		value: "traditionalClub"
	},
	{
		nepali: "खानेपानी परियोजना",
		english: "Water Project",
		value: "waterProject"
	},
	{
		nepali: "सिचाई परियोजना",
		english: "Irrigation Project",
		value: "irrigationProject"
	}
];

const instituteType = [
	{
		nepali: "सरकारी",
		english: "government",
		value: "government"
	},
	{
		nepali: "निजी",
		english: "private",
		value: "private"
	},
	{
		nepali: "सामुदायिक",
		english: "community",
		value: "community"
	}
];

const schoolLevel = [
	{
		nepali: "बाल विकास कक्षा",
		english: "Kinder Garden",
		value: "kinder_garden"
	},
	{
		nepali: "पूर्व प्राथमिक",
		english: "Pre Primary",
		value: "pre_primary"
	},
	{
		nepali: "आधारभूत तह",
		english: "Primary",
		value: "primary"
	},
	{
		nepali: "माध्यमिक तह",
		english: "Secondary",
		value: "secondary"
	},
	{
		nepali: "प्राविधिक एस.एल.सी. (Overseer)",
		english: "SLC",
		value: "slc"
	},
	{
		nepali: "स्नातक तह",
		english: "Bachelor",
		value: "bachelor"
	}
];

const fieldType = { nepali: "कुन बिभाग", english: "Which depart" };

const instituteList = ["School"];

const deleteMsg = {
	nepali: "के तपाई प्रयोगकर्ता हटाउन चहानुहुन्छ ?",
	english: "Are you sure you want to delete the user?"
};

const agree = { nepali: "हुन्छ ", english: "Agree" };

const disagree = { nepali: "हुदैन", english: "Disagree" };

module.exports = {
	fieldType,
	name,
	categoryTitle,
	category,
	establish,
	description,
	member,
	address,
	phone,
	clubActive,
	designation,
	count,
	latitude,
	longitude,
	wardQuestion,
	photo,
	specName,
	specValue,
	add,
	darta,
	itemTitle,
	area,
	kachiCount,
	pakkiCount,
	kachiRoomCount,
	pakkiRoomCount,
	gharTitle,
	roomTitle,
	benchCapacity,
	staffTitle,
	ground,
	compound,
	childplayground,
	toilet,
	hastoilet,
	maleCount,
	femaleCount,
	staffCount,
	hasdrinking,
	appointAs,
	staff,
	student,
	schoolClass,
	malestudent,
	femalestudent,
	website,
	email,
	schooltype,
	schoollevel,
	specTitle,
	position,
	emergencyNo,
	instituteType,
	buildingCount,
	consumedUser,
	vehicleTitle,
	memberTitle,
	instituteTitle,
	schoolLevel,
	categoryList,
	suffixCategoryList,
	fiscal,
	budget,
	dartachalani,
	effectedHouse,
	pdf,
	timespan,
	studentProd,
	staffProd,
	targetedCategory,
	helper,
	deleteMsg,
	agree,
	disagree
};
