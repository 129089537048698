import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	agriCropIncome,
	agriDaalIncome,
	agriFilungeySaleQuantity,
	agriFruitIncome,
	agriMakaiSaleQuantity,
	agriOilIncome,
	agriVegetableIncome
} from "../../../../variable/agriculture";
import {
	aalashAreaQuestion,
	aalashProduction,
	aalashSale,
	aarubakhadaAreaQuestion,
	aarubakhadaProduction,
	aarubakhadaSale,
	agriBlackDaalSale,
	agriCropType,
	agriDaalTitle,
	agriDaalType,
	agriDhanAreaQuestion,
	agriDhanProduction,
	agriDhanSale,
	agriFaparAreaQuestion,
	agriFaparProduction,
	agriFaparSale,
	agriFruitTitle,
	agriFruitType,
	agriKodoAreaQuestion,
	agriKodoProduction,
	agriKodoSale,
	agriMakaiAreaQuestion,
	agriMakaiProduction,
	agriMakaiSale,
	agriOilTitle,
	agriOilType,
	agriProduction,
	agriProductionType,
	agriProductionTypeQuestion,
	agriTypeProduction,
	agriVegetableTitle,
	agriVegetableType,
	agriYesNo,
	alaichiAreaQuestion,
	alaichiProduction,
	alaichiSale,
	amrisoAreaQuestion,
	amrisoProduction,
	amrisoSale,
	appleAreaQuestion,
	appleProduction,
	appleSale,
	avogadoAreaQuestion,
	avogadoProduction,
	avogadoSale,
	badelMeatIncome,
	badelMeatProduction,
	badelNumber,
	bananaAreaQuestion,
	bananaProduction,
	bananaSale,
	beefishsilkQuestion,
	beefishsilkType,
	beefishsilkYes,
	beefishsilkYesNo,
	beeProduction,
	beeTitle,
	besaarAreaQuestion,
	besaarProduction,
	besaarSale,
	bhatmasAreaQuestion,
	bhatmasProduction,
	bhatmasSale,
	birdDiffType,
	birdTitle,
	blackDaalAreaQuestion,
	blackDaalProduction,
	buffalloMilkProduction,
	buffalloMilkSale,
	buffalloNumber,
	cabbageAreaQuestion,
	cabbageProductionQuantity,
	cabbageSaleQuantity,
	cashCropTitle,
	cashCropType,
	cauliAreaQuestion,
	cauliProductionQuantity,
	cauliSaleQuantity,
	chanaAreaQuestion,
	chanaProduction,
	chanaSale,
	chillyAreaQuestion,
	chillyProduction,
	chillySale,
	chyangraMeatIncome,
	chyangraNumber,
	chyangraProduction,
	coffeeProduction,
	coffeeSale,
	coffeeTreeNumber,
	cowMilkProduction,
	cowMilkSale,
	cowNumber,
	cucumberAreaQuestion,
	cucumberProductionQuantity,
	cucumberSaleQuantity,
	dhanyaAreaQuestion,
	dhanyaProductionQuantity,
	dhanyaSaleQuantity,
	duckEggSale,
	duckMeatIncome,
	duckMeatProduction,
	duckNumber,
	filungeAreaQuestion,
	filungeProduction,
	filungeSale,
	fishProduction,
	fishTitle,
	flowerAreaQuestion,
	flowerProduction,
	flowerSale,
	flowerSaleQuantity,
	gadhaMeatIncome,
	gadhaNumber,
	gadhaProduction,
	gahatAreaQuestion,
	gahatProduction,
	gahatSale,
	garlicAreaQuestion,
	garlicProduction,
	garlicSale,
	ghiraulaAreaQuestion,
	ghiraulaProductionQuantity,
	ghiraulaSaleQuantity,
	gingerAreaQuestion,
	gingerProduction,
	gingerSale,
	goatMeatIncome,
	goatNumber,
	guvaAreaQuestion,
	guvaProduction,
	guvaSale,
	henEggSale,
	henMeatIncome,
	henMeatProduction,
	henNumber,
	horseNumber,
	insecticideQuestion,
	insecticideType,
	insecticideYes,
	insecticideYesNo,
	irrigationForLand,
	irrigationForLandList,
	khasiMeatIncome,
	khasiMeatProduction,
	khasiNumber,
	kiwiAreaQuestion,
	kiwiProduction,
	kiwiSale,
	landforAgri,
	landforAgriList,
	landforAgriYes,
	landforAgriYesNo,
	laukaAreaQuestion,
	laukaProductionQuantity,
	laukaSaleQuantity,
	lemonAreaQuestion,
	lemonProduction,
	lemonSale,
	livestockDiffType,
	livestockQuestion,
	livestockTitle,
	livestockType,
	livestockYes,
	livestockYesNo,
	mangoAreaQuestion,
	mangoProduction,
	mangoSale,
	mashyamAreaQuestion,
	mashyamProduction,
	mashyamSale,
	matarkoshaAreaQuestion,
	matarkoshaProductionQuantity,
	matarkoshaSaleQuantity,
	mushroomAreaQuestion,
	mushroomProduction,
	mushroomSale,
	musuriAreaQuestion,
	musuriProduction,
	musuriSale,
	naspatiAreaQuestion,
	naspatiProduction,
	naspatiSale,
	nutAreaQuestion,
	nutProduction,
	nutSale,
	onionAreaQuestion,
	onionProduction,
	onionSale,
	orangeAreaQuestion,
	orangeProduction,
	orangeSale,
	ostrichMeatIncome,
	ostrichMeatProduction,
	ostrichNumber,
	otherLand,
	ownLand,
	oxMeatIncome,
	oxNumber,
	pegion,
	pegionMeatIncome,
	pigMeatIncome,
	pigMeatProduction,
	pigNumber,
	pondArea,
	potatoAreaQuestion,
	potatoProductionQuantity,
	potatoSaleQuantity,
	raharAreaQuestion,
	raharProduction,
	raharSale,
	rangaMeatIncome,
	rangaMeatProduction,
	rangaNumber,
	reshamProduction,
	reshamTitle,
	saagAreaQuestion,
	saagProductionQuantity,
	saagSaleQuantity,
	sheepMeatIncome,
	sheepMeatProduction,
	sheepNumber,
	simiAreaQuestion,
	simiProductionQuantity,
	simiSaleQuantity,
	sugarcaneAreaQuestion,
	sugarcaneProduction,
	sugarcaneSale,
	sunflowerAreaQuestion,
	sunflowerProduction,
	sunflowerSale,
	suryaAreaQuestion,
	suryaProduction,
	suryaSale,
	teaAreaQuestion,
	teaProduction,
	teaSale,
	tilAreaQuestion,
	tilProduction,
	tilSale,
	tomatoAreaQuestion,
	tomatoProductionQuantity,
	tomatoSaleQuantity,
	tooriAreaQuestion,
	tooriProduction,
	tooriSale,
	totalBeeFishSilkIncome,
	totallandAreaTitle,
	yakMeatIncome,
	yakMilkProduction,
	yakNumber,
	yakProduction
} from "../../../../variable/houseSurvey";
import BoxBorder from "../../../common/frequent/BoxBorder";
import CheckBox from "../../../common/frequent/CheckBox";
import RadioButton from "../../../common/frequent/RadioButton";
import TextField from "../../../common/frequent/TextField";

class AgriLivestock extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`agriLivestock.${name}`, value);
	};
	render() {
		const { agriLivestock } = this.props;

		return (
			<Grid container direction="column">
				<BoxBorder isVisible>
					<Grid container>
						<TextField
							question={totallandAreaTitle}
							name="agriTotalRopani"
							type="number"
							placeholder="रोपनी"
							value={agriLivestock.agriTotalRopani}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="agriTotalAana"
							type="number"
							placeholder="आना"
							value={agriLivestock.agriTotalAana}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid>
					<Grid xs={6}>
						<TextField
							name="agriTotalPaisa"
							type="number"
							placeholder="पैसा"
							value={agriLivestock.agriTotalPaisa}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid>
				</BoxBorder>
				<Grid container>
					<RadioButton
						question={landforAgri}
						handleChange={this.handleChange}
						isVisible
						name="landforAgriYesNo"
						radioLists={landforAgriYesNo}
						value={agriLivestock.landforAgriYesNo}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						agriLivestock.landforAgriYesNo &&
						agriLivestock.landforAgriYesNo.includes("land_used_yes")
					}
				>
					<Grid container>
						<RadioButton
							question={irrigationForLand}
							name="irrigationForLandList"
							value={agriLivestock.irrigationForLandList}
							radioLists={irrigationForLandList}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid>

					<Grid container>
						<CheckBox
							question={landforAgriYes}
							name="landforAgriList"
							value={agriLivestock.landforAgriList}
							checkList={landforAgriList}
							isVisible
							handleCompChange={this.handleChange}
						/>
					</Grid>

					<BoxBorder
						isVisible={
							agriLivestock.landforAgriList &&
							agriLivestock.landforAgriList.includes("own_land")
						}
					>
						<Grid container>
							<TextField
								question={ownLand}
								name="agriOwnTotalRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriOwnTotalRopani}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="agriOwnTotalAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriOwnTotalAana}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="agriOwnTotalPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriOwnTotalPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.landforAgriList &&
							agriLivestock.landforAgriList.includes("other_land")
						}
					>
						<Grid container>
							<TextField
								question={otherLand}
								name="agriOtherTotalRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriOtherTotalRopani}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="agriOtherTotalAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriOtherTotalAana}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid xs={6}>
							<TextField
								name="agriOtherTotalPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriOtherTotalPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>

				<Grid container>
					<RadioButton
						question={agriProduction}
						handleChange={this.handleChange}
						isVisible
						name="agriYesNo"
						radioLists={agriYesNo}
						value={agriLivestock.agriYesNo}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						agriLivestock.agriYesNo &&
						agriLivestock.agriYesNo.includes("agri_sales_yes")
					}
				>
					<Grid container>
						<CheckBox
							question={agriProductionTypeQuestion}
							name="agriProductionType"
							value={agriLivestock.agriProductionType}
							checkList={agriProductionType}
							isVisible
							handleCompChange={this.handleChange}
						/>
					</Grid>

					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("crops")
						}
					>
						<Grid container>
							<CheckBox
								question={agriTypeProduction}
								name="agriCropType"
								value={agriLivestock.agriCropType}
								checkList={agriCropType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriCropIncome}
								name="cropIncome"
								type="number"
								placeholder="अन्नवाली उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.cropIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriCropType &&
							agriLivestock.agriCropType.includes("paddy")
						}
					>
						<Grid container>
							<TextField
								question={agriDhanAreaQuestion}
								name="agriDhanRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriDhanRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriDhanAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriDhanPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriDhanProduction}
								name="agriDhanProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriDhanProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriDhanProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriDhanSale}
								name="agriDhanSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriDhanSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriDhanSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.agriCropType &&
							agriLivestock.agriCropType.includes("maize")
						}
					>
						<Grid container>
							<TextField
								question={agriMakaiAreaQuestion}
								name="agriMakaiRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMakaiRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMakaiAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMakaiAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMakaiPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMakaiPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriMakaiProduction}
								name="agriMakaiProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMakaiProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMakaiProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMakaiProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriMakaiSale}
								name="agriMakaiSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMakaiSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMakaiSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMakaiSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriMakaiSaleQuantity}
								name="agriMakaiSaleQuantity"
								type="number"
								placeholder="मकैखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.agriMakaiSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriCropType &&
							agriLivestock.agriCropType.includes("kodo")
						}
					>
						<Grid container>
							<TextField
								question={agriKodoAreaQuestion}
								name="agriKodoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriKodoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKodoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriKodoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKodoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriKodoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriKodoProduction}
								name="agriKodoProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriKodoProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKodoProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriKodoProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriKodoSale}
								name="agriKodoSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriKodoSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKodoSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriKodoSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriCropType &&
							agriLivestock.agriCropType.includes("fapar")
						}
					>
						<Grid container>
							<TextField
								question={agriFaparAreaQuestion}
								name="agriFaparRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriFaparRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFaparAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriFaparAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFaparPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriFaparPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriFaparProduction}
								name="agriFaparProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriFaparProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFaparProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriFaparProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriFaparSale}
								name="agriFaparSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriFaparSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFaparSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriFaparSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("daal")
						}
					>
						<Grid container>
							<CheckBox
								question={agriDaalTitle}
								name="agriDaalType"
								value={agriLivestock.agriDaalType}
								checkList={agriDaalType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriDaalIncome}
								name="daalIncome"
								type="number"
								placeholder="दालको उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.daalIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("black_daal")
						}
					>
						<Grid container>
							<TextField
								question={blackDaalAreaQuestion}
								name="agriBlackDaalRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriBlackDaalRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBlackDaalAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriBlackDaalAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBlackDaalPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriBlackDaalPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={blackDaalProduction}
								name="agriBlackDaalProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBlackDaalProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBlackDaalProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBlackDaalProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriBlackDaalSale}
								name="agriBlackDaalSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBlackDaalSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBlackDaalSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBlackDaalSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("rahar")
						}
					>
						<Grid container>
							<TextField
								question={raharAreaQuestion}
								name="agriRaharRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriRaharRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriRaharAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriRaharAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriRaharPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriRaharPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={raharProduction}
								name="agriRaharProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriRaharProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriRaharProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriRaharProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={raharSale}
								name="agriRaharSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriRaharSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriRaharSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriRaharSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("musuri")
						}
					>
						<Grid container>
							<TextField
								question={musuriAreaQuestion}
								name="agriMusuriRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMusuriRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMusuriAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMusuriAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMusuriPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMusuriPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={musuriProduction}
								name="agriMusuriProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMusuriProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMusuriProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMusuriProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={musuriSale}
								name="agriMusuriSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMusuriSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMusuriSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMusuriSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("chana")
						}
					>
						<Grid container>
							<TextField
								question={chanaAreaQuestion}
								name="agriChanaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriChanaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChanaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriChanaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChanaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriChanaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={chanaProduction}
								name="agriChanaProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriChanaProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChanaProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriChanaProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={chanaSale}
								name="agriChanaSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriChanaSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChanaSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriChanaSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("bhatmas")
						}
					>
						<Grid container>
							<TextField
								question={bhatmasAreaQuestion}
								name="agriBhatmasRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriBhatmasRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBhatmasAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriBhatmasAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBhatmasPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriBhatmasPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={bhatmasProduction}
								name="agriBhatmasProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBhatmasProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBhatmasProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBhatmasProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={bhatmasSale}
								name="agriBhatmasSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBhatmasSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBhatmasSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBhatmasSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("gahat")
						}
					>
						<Grid container>
							<TextField
								question={gahatAreaQuestion}
								name="agriGahatRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriGahatRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGahatAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriGahatAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGahatPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriGahatPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={gahatProduction}
								name="agriGahatProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGahatProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGahatProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGahatProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={gahatSale}
								name="agriGahatSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGahatSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGahatSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGahatSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriDaalType &&
							agriLivestock.agriDaalType.includes("mashyam")
						}
					>
						<Grid container>
							<TextField
								question={mashyamAreaQuestion}
								name="agriMashyamRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMashyamRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMashyamAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMashyamAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMashyamPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMashyamPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mashyamProduction}
								name="agriMashyamProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMashyamProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMashyamProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMashyamProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mashyamSale}
								name="agriMashyamSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMashyamSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMashyamSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMashyamSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("oilseed")
						}
					>
						<Grid container>
							<CheckBox
								question={agriOilTitle}
								name="agriOilType"
								value={agriLivestock.agriOilType}
								checkList={agriOilType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriOilIncome}
								name="oilIncome"
								type="number"
								placeholder="तेलहन उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.oilIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("tori")
						}
					>
						<Grid container>
							<TextField
								question={tooriAreaQuestion}
								name="agriToriRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriToriRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriToriAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriToriAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriToriPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriToriPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={tooriProduction}
								name="agriToriProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriToriProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriToriProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriToriProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={tooriSale}
								name="agriToriSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriToriSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriToriSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriToriSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("sarshu")
						}
					>
						<Grid container>
							<TextField
								question={suryaAreaQuestion}
								name="agriSarsiuRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriSarsiuRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSarsiuAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriSarsiuAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSarsiuPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriSarsiuPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={suryaProduction}
								name="agriSarsiuProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSarsiuProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSarsiuProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSarsiuProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={suryaSale}
								name="agriSarsiuSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSarsiuSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSarsiuSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSarsiuSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("aalash")
						}
					>
						<Grid container>
							<TextField
								question={aalashAreaQuestion}
								name="agriAalashRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriAalashRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAalashAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriAalashAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAalashPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriAalashPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={aalashProduction}
								name="agriAalashProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAalashProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAalashProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAalashProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={aalashSale}
								name="agriAalashSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAalashSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAalashSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAalashSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("filunge")
						}
					>
						<Grid container>
							<TextField
								question={filungeAreaQuestion}
								name="agriFilungeyRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriFilungeyRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFilungeyAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriFilungeyAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFilungeyPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriFilungeyPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={filungeProduction}
								name="agriFilungeyProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriFilungeyProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFilungeyProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriFilungeyProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={filungeSale}
								name="agriFilungeySaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriFilungeySaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFilungeySalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriFilungeySalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriFilungeySaleQuantity}
								name="agriFilungeySaleQuantity"
								type="number"
								placeholder="फिलुंगेखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.agriFilungeySaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("teel")
						}
					>
						<Grid container>
							<TextField
								question={tilAreaQuestion}
								name="agriTeelRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriTeelRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeelAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriTeelAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeelPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriTeelPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={tilProduction}
								name="agriTeelProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriTeelProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeelProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriTeelProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={tilSale}
								name="agriTeelSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriTeelSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeelSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriTeelSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriOilType &&
							agriLivestock.agriOilType.includes("sunflower")
						}
					>
						<Grid container>
							<TextField
								question={sunflowerAreaQuestion}
								name="agriSunflowerRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriSunflowerRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSunflowerAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriSunflowerAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSunflowerPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriSunflowerPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={sunflowerProduction}
								name="agriSunflowerProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSunflowerProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSunflowerProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSunflowerProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={sunflowerSale}
								name="agriSunflowerSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSunflowerSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSunflowerSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSunflowerSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("vegetable")
						}
					>
						<Grid container>
							<CheckBox
								question={agriVegetableTitle}
								name="agriVegetableType"
								value={agriLivestock.agriVegetableType}
								checkList={agriVegetableType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriVegetableIncome}
								name="vegitableIncome"
								type="number"
								placeholder="तरकारी उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.vegitableIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("potato")
						}
					>
						<Grid container>
							<TextField
								question={potatoAreaQuestion}
								name="agriPotatoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriPotatoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriPotatoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriPotatoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriPotatoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriPotatoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={potatoProductionQuantity}
								name="potatoProductionQuantity"
								type="number"
								placeholder="आलुखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.potatoProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={potatoSaleQuantity}
								name="potatoSaleQuantity"
								type="number"
								placeholder="आलुखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.potatoSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("cabbage")
						}
					>
						<Grid container>
							<TextField
								question={cabbageAreaQuestion}
								name="agriCabbageRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriCabbageRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCabbageAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriCabbageAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCabbagePaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriCabbagePaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={cabbageProductionQuantity}
								name="cabbageProductionQuantity"
								type="number"
								placeholder="बन्दाखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.cabbageProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={cabbageSaleQuantity}
								name="cabbageSaleQuantity"
								type="number"
								placeholder="बन्दाखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.cabbageSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("cauliflower")
						}
					>
						<Grid container>
							<TextField
								question={cauliAreaQuestion}
								name="agriCauliRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriCauliRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCauliAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriCauliAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCauliPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriCauliPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={cauliProductionQuantity}
								name="cauliProductionQuantity"
								type="number"
								placeholder="काउलीखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.cauliProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={cauliSaleQuantity}
								name="cauliSaleQuantity"
								type="number"
								placeholder="काउलीखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.cauliSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("simi")
						}
					>
						<Grid container>
							<TextField
								question={simiAreaQuestion}
								name="agriSimiRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriSimiRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSimiAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriSimiAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSimiPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriSimiPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={simiProductionQuantity}
								name="simiProductionQuantity"
								type="number"
								placeholder="सिमिखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.simiProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={simiSaleQuantity}
								name="simiSaleQuantity"
								type="number"
								placeholder="सिमिखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.simiSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("matarkosha")
						}
					>
						<Grid container>
							<TextField
								question={matarkoshaAreaQuestion}
								name="agriMatarkoshaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMatarkoshaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMatarkoshaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMatarkoshaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMatarkoshaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMatarkoshaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={matarkoshaProductionQuantity}
								name="matarkoshaProductionQuantity"
								type="number"
								placeholder="मटरकोश उत्पादन (के.जि.मा )"
								value={agriLivestock.matarkoshaProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={matarkoshaSaleQuantity}
								name="matarkoshaSaleQuantity"
								type="number"
								placeholder="मटरकोश विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.matarkoshaSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("tomato")
						}
					>
						<Grid container>
							<TextField
								question={tomatoAreaQuestion}
								name="agriTomatoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriTomatoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTomatoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriTomatoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTomatoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriTomatoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={tomatoProductionQuantity}
								name="tomatoProductionQuantity"
								type="number"
								placeholder="गोलभेडाखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.tomatoProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={tomatoSaleQuantity}
								name="tomatoSaleQuantity"
								type="number"
								placeholder="गोलभेडाखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.tomatoSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("cucumber")
						}
					>
						<Grid container>
							<TextField
								question={cucumberAreaQuestion}
								name="agriCucumberRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriCucumberRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCucumberAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriCucumberAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriCucumberPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriCucumberPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={cucumberProductionQuantity}
								name="cucumberProductionQuantity"
								type="number"
								placeholder="काक्रोखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.cucumberProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={cucumberSaleQuantity}
								name="cucumberSaleQuantity"
								type="number"
								placeholder="काक्रोखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.cucumberSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("lauka")
						}
					>
						<Grid container>
							<TextField
								question={laukaAreaQuestion}
								name="agriLaukaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriLaukaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLaukaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriLaukaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLaukaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriLaukaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={laukaProductionQuantity}
								name="laukaProductionQuantity"
								type="number"
								placeholder="लौकाखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.laukaProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={laukaSaleQuantity}
								name="laukaSaleQuantity"
								type="number"
								placeholder="लौकाखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.laukaSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("ghiraula")
						}
					>
						<Grid container>
							<TextField
								question={ghiraulaAreaQuestion}
								name="agriGhiraulaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriGhiraulaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGhiraulaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriGhiraulaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGhiraulaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriGhiraulaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={ghiraulaProductionQuantity}
								name="ghiraulaProductionQuantity"
								type="number"
								placeholder="घिरौलाखेति उत्पादन (के.जि.मा )"
								value={agriLivestock.ghiraulaProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={ghiraulaSaleQuantity}
								name="ghiraulaSaleQuantity"
								type="number"
								placeholder="घिरौलाखेति विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.ghiraulaSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("dhanya")
						}
					>
						<Grid container>
							<TextField
								question={dhanyaAreaQuestion}
								name="agriDhanyaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriDhanyaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanyaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriDhanyaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriDhanyaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriDhanyaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={dhanyaProductionQuantity}
								name="dhanyaProductionQuantity"
								type="number"
								placeholder="धन्या उत्पादन (के.जि.मा )"
								value={agriLivestock.dhanyaProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={dhanyaSaleQuantity}
								name="dhanyaSaleQuantity"
								type="number"
								placeholder="धन्या विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.dhanyaSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriVegetableType &&
							agriLivestock.agriVegetableType.includes("saag")
						}
					>
						<Grid container>
							<TextField
								question={saagAreaQuestion}
								name="agriSaagRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriSaagRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSaagAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriSaagAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSaagPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriSaagPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={saagProductionQuantity}
								name="saagProductionQuantity"
								type="number"
								placeholder="साँग उत्पादन (के.जि.मा )"
								value={agriLivestock.saagProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={saagSaleQuantity}
								name="saagSaleQuantity"
								type="number"
								placeholder="साँग विक्री परिमाण (के.जि.मा )"
								value={agriLivestock.saagSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("fruit")
						}
					>
						<Grid container>
							<CheckBox
								question={agriFruitTitle}
								name="agriFruitType"
								value={agriLivestock.agriFruitType}
								checkList={agriFruitType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={agriFruitIncome}
								name="fruitIncome"
								type="number"
								placeholder="फलफुल उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.fruitIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("mango")
						}
					>
						<Grid container>
							<TextField
								question={mangoAreaQuestion}
								name="agriMangoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMangoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMangoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMangoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMangoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMangoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mangoProduction}
								name="agriMangoProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMangoProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMangoProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMangoProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mangoSale}
								name="agriMangoSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMangoSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMangoSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMangoSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("aarubakhada")
						}
					>
						<Grid container>
							<TextField
								question={aarubakhadaAreaQuestion}
								name="agriAarubakhadaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriAarubakhadaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAarubakhadaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriAarubakhadaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAarubakhadaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriAarubakhadaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={aarubakhadaProduction}
								name="agriAarubakhadaProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAarubakhadaProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAarubakhadaProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAarubakhadaProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={aarubakhadaSale}
								name="agriAarubakhadaSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAarubakhadaSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAarubakhadaSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAarubakhadaSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("banana")
						}
					>
						<Grid container>
							<TextField
								question={bananaAreaQuestion}
								name="agriBananaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriBananaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBananaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriBananaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBananaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriBananaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={bananaProduction}
								name="agriBananaProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBananaProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBananaProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBananaProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={bananaSale}
								name="agriBananaSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBananaSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBananaSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBananaSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("orange")
						}
					>
						<Grid container>
							<TextField
								question={orangeAreaQuestion}
								name="agriOrangeRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriOrangeRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOrangeAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriOrangeAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOrangePaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriOrangePaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={orangeProduction}
								name="agriOrangeProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriOrangeProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOrangeProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriOrangeProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={orangeSale}
								name="agriOrangeSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriOrangeSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOrangeSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriOrangeSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("guva")
						}
					>
						<Grid container>
							<TextField
								question={guvaAreaQuestion}
								name="agriGuvaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriGuvaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGuvaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriGuvaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGuvaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriGuvaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={guvaProduction}
								name="agriGuvaProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGuvaProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGuvaProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGuvaProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={guvaSale}
								name="agriGuvaSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGuvaSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGuvaSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGuvaSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("lemon")
						}
					>
						<Grid container>
							<TextField
								question={lemonAreaQuestion}
								name="agriLemonRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriLemonRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLemonAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriLemonAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLemonPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriLemonPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={lemonProduction}
								name="agriLemonProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriLemonProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLemonProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriLemonProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={lemonSale}
								name="agriLemonSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriLemonSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriLemonSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriLemonSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("apple")
						}
					>
						<Grid container>
							<TextField
								question={appleAreaQuestion}
								name="agriAppleRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriAppleRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAppleAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriAppleAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriApplePaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriApplePaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={appleProduction}
								name="agriAppleProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAppleProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAppleProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAppleProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={appleSale}
								name="agriAppleSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAppleSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAppleSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAppleSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("kiwi")
						}
					>
						<Grid container>
							<TextField
								question={kiwiAreaQuestion}
								name="agriKiwiRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriKiwiRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKiwiAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriKiwiAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKiwiPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriKiwiPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={kiwiProduction}
								name="agriKiwiProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriKiwiProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKiwiProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriKiwiProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={kiwiSale}
								name="agriKiwiSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriKiwiSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriKiwiSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriKiwiSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("naspati")
						}
					>
						<Grid container>
							<TextField
								question={naspatiAreaQuestion}
								name="agriNaspatiRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriNaspatiRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNaspatiAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriNaspatiAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNaspatiPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriNaspatiPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={naspatiProduction}
								name="agriNaspatiProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriNaspatiProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNaspatiProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriNaspatiProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={naspatiSale}
								name="agriNaspatiSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriNaspatiSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNaspatiSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriNaspatiSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriFruitType &&
							agriLivestock.agriFruitType.includes("avogado")
						}
					>
						<Grid container>
							<TextField
								question={avogadoAreaQuestion}
								name="agriAvogadoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriAvogadoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAvogadoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriAvogadoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAvogadoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriAvogadoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={avogadoProduction}
								name="agriAvogadoProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAvogadoProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAvogadoProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAvogadoProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={avogadoSale}
								name="agriAvogadoSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAvogadoSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAvogadoSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAvogadoSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("cash_crops")
						}
					>
						<Grid container>
							<CheckBox
								question={cashCropTitle}
								name="cashCropType"
								value={agriLivestock.cashCropType}
								checkList={cashCropType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("alaichi")
						}
					>
						<Grid container>
							<TextField
								question={alaichiAreaQuestion}
								name="agriAlaichiRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.alaichiArea}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAlaichiAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.alaichiArea}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAlaichiPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.alaichiArea}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={alaichiProduction}
								name="agriAlaichiProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.alaichiProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAlaichiProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.alaichiProductionQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={alaichiSale}
								name="agriAlaichiSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.alaichiSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAlaichiSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.alaichiSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("amriso")
						}
					>
						<Grid container>
							<TextField
								question={amrisoAreaQuestion}
								name="agriAmrisoRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriAmrisoRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAmrisoAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriAmrisoAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAmrisoPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriAmrisoPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={amrisoProduction}
								name="agriAmrisoProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAmrisoProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAmrisoProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAmrisoProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={amrisoSale}
								name="agriAmrisoSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriAmrisoSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriAmrisoSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriAmrisoSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("coffee")
						}
					>
						<Grid container>
							<TextField
								question={coffeeTreeNumber}
								name="coffeeTreeNumber"
								type="number"
								placeholder="कफीखेति गरिएको बोट संख"
								value={agriLivestock.coffeeTreeNumber}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={coffeeProduction}
								name="coffeeProduction"
								type="number"
								placeholder="कफीखेति उत्पादन (के.जि.मा)"
								value={agriLivestock.coffeeProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={coffeeSale}
								name="coffeeSale"
								type="number"
								placeholder="कफीखेति विक्री परिमाण (के.जि.मा)"
								value={agriLivestock.coffeeSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("tea")
						}
					>
						<Grid container>
							<TextField
								question={teaAreaQuestion}
								name="agriTeaRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriTeaRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeaAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriTeaAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriTeaPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriTeaPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={teaProduction}
								name="teaProduction"
								type="number"
								placeholder="चियाखेति उत्पादन (के.जि.मा)"
								value={agriLivestock.teaProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={teaSale}
								name="teaSale"
								type="number"
								placeholder="चियाखेति विक्री परिमाण (के.जि.मा)"
								value={agriLivestock.teaSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("ginger")
						}
					>
						<Grid container>
							<TextField
								question={gingerAreaQuestion}
								name="agriGingerRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriGingerRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGingerAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriGingerAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGingerPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriGingerPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={gingerProduction}
								name="agriGingerProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGingerProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGingerProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGingerProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={gingerSale}
								name="agriGingerSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGingerSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGingerSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGingerSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("besaar")
						}
					>
						<Grid container>
							<TextField
								question={besaarAreaQuestion}
								name="agriBessarRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriBessarRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBessarAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriBessarAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBessarPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriBessarPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={besaarProduction}
								name="agriBessarProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBessarProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBessarProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBessarProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={besaarSale}
								name="agriBessarSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriBessarSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriBessarSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriBessarSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("garlic")
						}
					>
						<Grid container>
							<TextField
								question={garlicAreaQuestion}
								name="agriGarlicRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriGarlicRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGarlicAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriGarlicAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGarlicPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriGarlicPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={garlicProduction}
								name="agriGarlicProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGarlicProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGarlicProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGarlicProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={garlicSale}
								name="agriGarlicSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriGarlicSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriGarlicSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriGarlicSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("onion")
						}
					>
						<Grid container>
							<TextField
								question={onionAreaQuestion}
								name="agriOnionRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriOnionRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOnionAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriOnionAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOnionPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriOnionPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={onionProduction}
								name="agriOnionProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriOnionProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOnionProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriOnionProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={onionSale}
								name="agriOnionSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriOnionSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriOnionSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriOnionSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("mushroom")
						}
					>
						<Grid container>
							<TextField
								question={mushroomAreaQuestion}
								name="agriMushroomRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriMushroomRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMushroomAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriMushroomAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMushroomPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriMushroomPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mushroomProduction}
								name="agriMushroomProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMushroomProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMushroomProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMushroomProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={mushroomSale}
								name="agriMushroomSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriMushroomSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriMushroomSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriMushroomSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("sugarcane")
						}
					>
						<Grid container>
							<TextField
								question={sugarcaneAreaQuestion}
								name="agriSugarcaneRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriSugarcaneRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSugarcaneAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriSugarcaneAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSugarcanePaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriSugarcanePaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={sugarcaneProduction}
								name="agriSugarcaneProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSugarcaneProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSugarcaneProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSugarcaneProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={sugarcaneSale}
								name="agriSugarcaneSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriSugarcaneSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriSugarcaneSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriSugarcaneSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("nut")
						}
					>
						<Grid container>
							<TextField
								question={nutAreaQuestion}
								name="agriNutRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriNutRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNutAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriNutAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNutPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriNutPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={nutProduction}
								name="agriNutProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriNutProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNutProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriNutProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={nutSale}
								name="agriNutSaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriNutSaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriNutSalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriNutSalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.cashCropType &&
							agriLivestock.cashCropType.includes("chilly")
						}
					>
						<Grid container>
							<TextField
								question={chillyAreaQuestion}
								name="agriChillyRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriChillyRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChillyAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriChillyAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChillyPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriChillyPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={chillyProduction}
								name="agriChillyProductionMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriChillyProductionMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChillyProductionPathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriChillyProductionPathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={chillySale}
								name="agriChillySaleMuri"
								type="number"
								placeholder="मुरी"
								value={agriLivestock.agriChillySaleMuri}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriChillySalePathi"
								type="number"
								placeholder="पाथी"
								value={agriLivestock.agriChillySalePathi}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>

					<BoxBorder
						isVisible={
							agriLivestock.agriProductionType &&
							agriLivestock.agriProductionType.includes("flower")
						}
					>
						<Grid container>
							<TextField
								question={flowerAreaQuestion}
								name="agriFlowerRopani"
								type="number"
								placeholder="रोपनी"
								value={agriLivestock.agriFlowerRopani}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFlowerAana"
								type="number"
								placeholder="आना"
								value={agriLivestock.agriFlowerAana}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								// question={agriDhanAreaQuestion}
								name="agriFlowerPaisa"
								type="number"
								placeholder="पैसा"
								value={agriLivestock.agriFlowerPaisa}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={flowerProduction}
								name="flowerProduction"
								type="number"
								placeholder="फुलखेतीको उत्पादन"
								value={agriLivestock.flowerProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={flowerSale}
								name="flowerSale"
								type="number"
								placeholder="विक्री परिमाण (के.जी. मा)"
								value={agriLivestock.flowerSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={flowerSaleQuantity}
								name="flowerSaleQuantity"
								type="number"
								placeholder="फुलखेती उत्पादन वाट बार्षिक आम्दानी कति हो ?"
								value={agriLivestock.flowerSaleQuantity}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<Grid container>
						<RadioButton
							question={insecticideQuestion}
							name="insecticideYesNo"
							value={agriLivestock.insecticideYesNo}
							radioLists={insecticideYesNo}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid>

					<BoxBorder
						isVisible={
							agriLivestock.insecticideYesNo &&
							agriLivestock.insecticideYesNo.includes("chemical_yes")
						}
					>
						<Grid container>
							<RadioButton
								question={insecticideYes}
								name="insecticideType"
								value={agriLivestock.insecticideType}
								radioLists={insecticideType}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>
				<Grid container>
					<RadioButton
						question={livestockQuestion}
						handleChange={this.handleChange}
						isVisible
						name="livestockYesNo"
						radioLists={livestockYesNo}
						value={agriLivestock.livestockYesNo}
					/>
				</Grid>

				<BoxBorder
					isVisible={
						agriLivestock.livestockYesNo &&
						agriLivestock.livestockYesNo.includes("yes")
					}
				>
					<Grid container>
						<CheckBox
							question={livestockYes}
							name="livestockType"
							value={agriLivestock.livestockType}
							checkList={livestockType}
							isVisible
							handleCompChange={this.handleChange}
						/>
					</Grid>

					<BoxBorder
						isVisible={
							agriLivestock.livestockType &&
							agriLivestock.livestockType.includes("animal")
						}
					>
						<Grid container>
							<CheckBox
								question={livestockTitle}
								name="livestockDiffType"
								value={agriLivestock.livestockDiffType}
								checkList={livestockDiffType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("cow")
						}
					>
						<Grid container>
							<TextField
								question={cowNumber}
								name="cowNumber"
								type="number"
								placeholder="गाईको संख्या"
								value={agriLivestock.cowNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={cowMilkProduction}
								name="cowMilkProduction"
								type="number"
								placeholder="गाईको दैनिक दुध उत्पादन (लिटरमा )"
								value={agriLivestock.cowMilkProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={cowMilkSale}
								name="cowMilkSale"
								type="number"
								placeholder="दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.cowMilkSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("buffallo")
						}
					>
						<Grid container>
							<TextField
								question={buffalloNumber}
								name="buffalloNumber"
								type="number"
								placeholder="भैसीको संख्या"
								value={agriLivestock.buffalloNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={buffalloMilkProduction}
								name="buffalloMilkProduction"
								type="number"
								placeholder="भैसीको दैनिक दुध उत्पादन (लिटरमा )"
								value={agriLivestock.buffalloMilkProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={buffalloMilkSale}
								name="buffalloMilkSale"
								type="number"
								placeholder="दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.buffalloMilkSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("goat/boka")
						}
					>
						<Grid container>
							<TextField
								question={khasiNumber}
								name="khasiNumber"
								type="number"
								placeholder="खसी/बोकाक संख्या"
								value={agriLivestock.khasiNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={khasiMeatProduction}
								name="khasiMeatProduction"
								type="number"
								placeholder="खसी/बोका मासुबिक्रि (के जिमा )"
								value={agriLivestock.khasiMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={khasiMeatIncome}
								name="khasiMeatIncome"
								type="number"
								placeholder="मासु वा खसी/बोका बिक्रिबाट बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.khasiMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("baakhra")
						}
					>
						<Grid container>
							<TextField
								question={goatNumber}
								name="goatNumber"
								type="number"
								placeholder="बाख्राको संख्या"
								value={agriLivestock.goatNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={goatMeatIncome}
								name="goatMeatIncome"
								type="number"
								placeholder="बाख्राको मासु वा बाख्रा बिक्रीबाट बार्षिक आम्दानी"
								value={agriLivestock.goatMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("ranga")
						}
					>
						<Grid container>
							<TextField
								question={rangaNumber}
								name="rangaNumber"
								type="number"
								placeholder="राँगा/पाडा/पाडी संख्या"
								value={agriLivestock.rangaNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={rangaMeatProduction}
								name="rangaMeatProduction"
								type="number"
								placeholder="मासु छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )"
								value={agriLivestock.rangaMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={rangaMeatIncome}
								name="rangaMeatIncome"
								type="number"
								placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजार )"
								value={agriLivestock.rangaMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("pig")
						}
					>
						<Grid container>
							<TextField
								question={pigNumber}
								name="pigNumber"
								type="number"
								placeholder="सुँगुर/बंगुरको संख्या"
								value={agriLivestock.pigNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={pigMeatProduction}
								name="pigMeatProduction"
								type="number"
								placeholder="मासु बिक्रि बार्षिक (के .जीमा )"
								value={agriLivestock.pigMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={pigMeatIncome}
								name="pigMeatIncome"
								type="number"
								placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)"
								value={agriLivestock.pigMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("sheep")
						}
					>
						<Grid container>
							<TextField
								question={sheepNumber}
								name="sheepNumber"
								type="number"
								placeholder="भेडाको संख्या"
								value={agriLivestock.sheepNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={sheepMeatProduction}
								name="sheepMeatProduction"
								type="number"
								placeholder="भेडाको मासु बिक्रि दैनिक (के जीमा )"
								value={agriLivestock.sheepMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={sheepMeatIncome}
								name="sheepMeatIncome"
								type="number"
								placeholder="भेडाको उन उत्पादन तथा मासु बिक्रि बार्षिकरुपमा (रु हजारमा )"
								value={agriLivestock.sheepMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("bandel")
						}
					>
						<Grid container>
							<TextField
								question={badelNumber}
								name="badelNumber"
								type="number"
								placeholder="बँदेलको संख्या"
								value={agriLivestock.badelNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={badelMeatProduction}
								name="badelMeatProduction"
								type="number"
								placeholder="बँदेलको मासु दैनिक रुपमा उत्पादन (के जीमा )"
								value={agriLivestock.badelMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={badelMeatIncome}
								name="badelMeatIncome"
								type="number"
								placeholder="मासु वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.badelMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("garu")
						}
					>
						<Grid container>
							<TextField
								question={oxNumber}
								name="oxNumber"
								type="number"
								placeholder="गोरुको संख्या"
								value={agriLivestock.oxNumber}
								isVisible
								handleChange={this.handleChange}
							/>

							<TextField
								question={oxMeatIncome}
								name="oxMeatIncome"
								type="number"
								placeholder="पशुबिक्रिबाट बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.oxMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("gadha")
						}
					>
						<Grid container>
							<TextField
								question={gadhaNumber}
								name="gadhaNumber"
								type="number"
								placeholder="गधाको संख्या"
								value={agriLivestock.gadhaNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={gadhaProduction}
								name="gadhaProduction"
								type="number"
								placeholder="मासु बिक्रि बार्षिक (के .जीमा )"
								value={agriLivestock.gadhaProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={gadhaMeatIncome}
								name="gadhaMeatIncome"
								type="number"
								placeholder="मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)"
								value={agriLivestock.gadhaMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("horse")
						}
					>
						<Grid container>
							<TextField
								question={horseNumber}
								name="horseNumber"
								type="number"
								placeholder="घोडाको संख्या"
								value={agriLivestock.horseNumber}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("yak")
						}
					>
						<Grid container>
							<TextField
								question={yakNumber}
								name="yakNumber"
								type="number"
								placeholder="याकको संख्या"
								value={agriLivestock.yakNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={yakMilkProduction}
								name="yakMilkProduction"
								type="number"
								placeholder="दुध उत्पादन दैनिकरुपमा (लिटरमा )"
								value={agriLivestock.yakMilkProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={yakProduction}
								name="yakProduction"
								type="number"
								placeholder="छुर्पी उत्पादन गर्छ भने बार्षिक रुपमा कति केजी ?"
								value={agriLivestock.yakProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={yakMeatIncome}
								name="yakMeatIncome"
								type="number"
								placeholder="याकको दुध वा छुर्पीबाट बार्षिक रुपमा कति आम्दानी हुन्छ? ( रु हजारमा )"
								value={agriLivestock.yakMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockDiffType &&
							agriLivestock.livestockDiffType.includes("chyangra")
						}
					>
						<Grid container>
							<TextField
								question={chyangraNumber}
								name="chyangraNumber"
								type="number"
								placeholder="च्यांग्राको संख्याा"
								value={agriLivestock.chyangraNumber}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={chyangraProduction}
								name="chyangraProduction"
								type="number"
								placeholder="च्यांग्राको मासु वा छाला उत्पादन (के जीमा )"
								value={agriLivestock.chyangraProduction}
								isVisible
								handleChange={this.handleChange}
							/>
							<TextField
								question={chyangraMeatIncome}
								name="chyangraMeatIncome"
								type="number"
								placeholder="च्यांग्राको मासु / छाला वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.chyangraMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.livestockType &&
							agriLivestock.livestockType.includes("bird")
						}
					>
						<Grid container>
							<CheckBox
								question={birdTitle}
								name="birdDiffType"
								value={agriLivestock.birdDiffType}
								checkList={birdDiffType}
								isVisible
								handleCompChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.birdDiffType &&
							agriLivestock.birdDiffType.includes("chicken")
						}
					>
						<Grid container>
							<TextField
								question={henNumber}
								name="henNumber"
								type="number"
								placeholder="कुखुराको संख्या (कुखुरा फारम भए फारममा कति संख्या कुखुरा छ उल्लेख गर्नुस )"
								value={agriLivestock.henNumber}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={henMeatProduction}
								name="henMeatProduction"
								type="number"
								placeholder="कुखुराको मासुबिक्रि दैनिक रुपमा (के जीमा )"
								value={agriLivestock.henMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={henEggSale}
								name="henEggSale"
								type="number"
								placeholder="कुखुराको अन्डा बिक्रि दैनिक रुपमा (गोटा )"
								value={agriLivestock.henEggSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={henMeatIncome}
								name="henMeatIncome"
								type="number"
								placeholder="कुखुराको मासु,अन्डा वा कुखुरा बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा)"
								value={agriLivestock.henMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.birdDiffType &&
							agriLivestock.birdDiffType.includes("duck")
						}
					>
						<Grid container>
							<TextField
								question={duckNumber}
								name="duckNumber"
								type="number"
								placeholder="हाँसको संख्या"
								value={agriLivestock.duckNumber}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={duckMeatProduction}
								name="duckMeatProduction"
								type="number"
								placeholder="हाँसको मासु दैनिक रुपमा बिक्रि (के जिमा )"
								value={agriLivestock.duckMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={duckEggSale}
								name="duckEggSale"
								type="number"
								placeholder="हाँसको अन्डा बिक्रि दैनिक रुपमा (गोटा )"
								value={agriLivestock.duckEggSale}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={duckMeatIncome}
								name="duckMeatIncome"
								type="number"
								placeholder="हाँसको मासु वा हाँस बिक्रि बाट बार्षिक आम्दानी (रु हजारमा )"
								value={agriLivestock.duckMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.birdDiffType &&
							agriLivestock.birdDiffType.includes("austrich")
						}
					>
						<Grid container>
							<TextField
								question={ostrichNumber}
								name="ostrichNumber"
								type="number"
								placeholder="अस्ट्रिचको संख्या"
								value={agriLivestock.ostrichNumber}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={ostrichMeatProduction}
								name="ostrichMeatProduction"
								type="number"
								placeholder="अस्ट्रिचको मासु वा अस्ट्रिच बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा )"
								value={agriLivestock.ostrichMeatProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={ostrichMeatIncome}
								name="ostrichMeatIncome"
								type="number"
								placeholder="अस्ट्रिचको मासु वा अस्ट्रिच बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा )"
								value={agriLivestock.ostrichMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.birdDiffType &&
							agriLivestock.birdDiffType.includes("pegion")
						}
					>
						<Grid container>
							<TextField
								question={pegion}
								name="pegion"
								type="number"
								placeholder="परेवाको संख्या"
								value={agriLivestock.pegion}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={pegionMeatIncome}
								name="pegionMeatIncome"
								type="number"
								placeholder="परेवा वा मासु बिक्रिबाट बार्षिक आम्दानी"
								value={agriLivestock.pegionMeatIncome}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
				</BoxBorder>
				<Grid container>
					<RadioButton
						question={beefishsilkQuestion}
						handleChange={this.handleChange}
						isVisible
						name="beefishsilkYesNo"
						radioLists={beefishsilkYesNo}
						value={agriLivestock.beefishsilkYesNo}
					/>
				</Grid>
				<BoxBorder
					isVisible={
						agriLivestock.beefishsilkYesNo &&
						agriLivestock.beefishsilkYesNo.includes("yes")
					}
				>
					<Grid container>
						<CheckBox
							question={beefishsilkYes}
							name="beefishsilkType"
							value={agriLivestock.beefishsilkType}
							checkList={beefishsilkType}
							isVisible
							handleCompChange={this.handleChange}
						/>
					</Grid>

					<BoxBorder
						isVisible={
							agriLivestock.beefishsilkType &&
							agriLivestock.beefishsilkType.includes("fish")
						}
					>
						<Grid container>
							<TextField
								question={fishTitle}
								name="totalPond"
								type="number"
								placeholder="पोखरि संख्या"
								value={agriLivestock.totalPond}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={pondArea}
								name="pondArea"
								type="number"
								placeholder="पोखरी क्षेत्रफ़ल"
								value={agriLivestock.pondArea}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={fishProduction}
								name="fishProduction"
								type="number"
								placeholder="बार्षिक माछा उत्पादन (के.जी)"
								value={agriLivestock.fishProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.beefishsilkType &&
							agriLivestock.beefishsilkType.includes("bee")
						}
					>
						<Grid container>
							<TextField
								question={beeTitle}
								name="totalGhaar"
								type="number"
								placeholder="घार संख्या"
								value={agriLivestock.totalGhaar}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={beeProduction}
								name="beeProduction"
								type="number"
								placeholder="वार्षिक मह उत्पादन(के.जी)"
								value={agriLivestock.beeProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<BoxBorder
						isVisible={
							agriLivestock.beefishsilkType &&
							agriLivestock.beefishsilkType.includes("resham")
						}
					>
						<Grid container>
							<TextField
								question={reshamTitle}
								name="reshamGhaar"
								type="number"
								placeholder="कोयो संख्या"
								value={agriLivestock.reshamGhaar}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
						<Grid container>
							<TextField
								question={reshamProduction}
								name="reshamProduction"
								type="number"
								placeholder="उत्पादन(के.जी)"
								value={agriLivestock.reshamProduction}
								isVisible
								handleChange={this.handleChange}
							/>
						</Grid>
					</BoxBorder>
					<Grid xs={6}>
						<TextField
							question={totalBeeFishSilkIncome}
							name="agriSilkSaleQuantity"
							type="number"
							placeholder="माछा मौरी रेशम पालन बिक्रीबाट वार्षिक आम्दानी (रु)"
							value={agriLivestock.agriSilkSaleQuantity}
							isVisible={agriLivestock.beefishsilkYesNo == "yes"}
							handleChange={this.handleChange}
						/>
					</Grid>
				</BoxBorder>
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing.unit * 3
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
		display: "flex"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

AgriLivestock.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AgriLivestock);
