import {
  GET_SMS_ANALYSIS,
  GET_CAMPAIGN_ANALYSIS,
  SET_SMS_ANALYSIS_LOADING
} from "../actions/types";
import isEmpty from "../utils/validation/is-empty";

const initialState = {
  loading: false,
  smsanalysis: [],
  campaign: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SMS_ANALYSIS_LOADING:
      return {
        loading: true
      };
    case GET_SMS_ANALYSIS:
      return {
        ...state,
        loading: false,
        smsanalysis: action.payload
      };
    case GET_CAMPAIGN_ANALYSIS:
      return {
        ...state,
        loading: false,
        campaign: action.payload
      };
    default:
      return state;
  }
}
