import React, { Component, Fragment } from "react";
import InstitutionTable from "./InstitutionTable";

export default class index extends Component {
	state = {
		category: ""
	};

	componentDidMount() {
		console.log("mounted index institute");
	}

	componentDidUpdate(prevProps) {
		const { category } = this.props.match.params;
		if (prevProps.match.params.category !== this.props.match.params.category) {
			this.setState({ category: category });
		}
	}

	render() {
		const { category } = this.props.match.params;
		return (
			<Fragment>
				<InstitutionTable category={category} />
				{/* <Link to="/survey-by-institution/add">
					<button>Add</button>
				</Link> */}
			</Fragment>
		);
	}
}
