import {
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clearMemberList,
	getMemberByName
} from "../../../../../actions/searchAction";
import isEmpty from "../../../../../utils/validation/is-empty";
import {
	name,
	selectedLanguage,
	searchByName
} from "../../../../../variable/global";
import MySnackbarContentWrapper from "../../../../common/MySnackbarContentWrapper";
import GetFamilyMemberDetail from "./GetFamilyMemberDetail";

class SearchByName extends Component {
	constructor(props) {
		super(props);
		props.clearMemberList();
	}
	// for thirdGender houseNumber: 2631
	// 1221,
	// 2156 for insurance
	state = {
		name: "",
		snack: false,
		snackVariant: "",
		snackMessage: ""
	};

	fetchData = () => {
		this.props.getMemberByName({ name: this.state.name });
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};
	onSubmit = () => {
		// event.preventDefault();
		const name = this.state.name;
		if (!isEmpty(name)) this.fetchData();
		else {
			return this.setState({
				snack: true,
				snackVariant: "error",
				snackMessage: "कृपया घरको नम्बर भर्नुहोस्"
			});
		}
	};

	keyPress = e => {
		if (e.keyCode == 13) {
			this.onSubmit();
			// put the login here
		}
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ snack: false });
	};

	render() {
		const { classes, memberList, loading } = this.props;

		return (
			<Grid container className={classes.container}>
				<Paper className={classes.root} elevation={2}>
					<PersonIcon className={classes.homeIcon} />
					<InputBase
						onKeyDown={this.keyPress}
						name="name"
						value={this.state.name}
						onChange={this.handleChange}
						className={classes.input}
						placeholder={name[selectedLanguage]}
					/>

					<Divider className={classes.divider} />
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={this.state.snack}
						autoHideDuration={4000}
						onClose={this.handleClose}
					>
						<MySnackbarContentWrapper
							onClose={this.handleClose}
							variant={this.state.snackVariant}
							message={this.state.snackMessage}
						/>
					</Snackbar>
					<IconButton
						onClick={this.onSubmit}
						color="primary"
						className={classes.iconButton}
						aria-label="Search"
					>
						<SearchIcon />
					</IconButton>
				</Paper>

				{loading ? (
					<Grid
						container
						stretch
						direction="row"
						justify="center"
						style={{ height: "50vh" }}
						alignItems="center"
					>
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={loading}
							/>
						</Grid>
					</Grid>
				) : (
					memberList && (
						<GetFamilyMemberDetail
							title={searchByName[selectedLanguage]}
							memberList={memberList}
						/>
					)
				)}
			</Grid>
		);
	}
}

const styles = theme => ({
	container: {},
	root: {
		marginTop: theme.spacing.unit * 5,
		// padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto"
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4
	},
	homeIcon: {
		marginLeft: 5,
		marginRight: 5,
		color: "#673ab7"
	}
});

SearchByName.propTypes = {
	classes: PropTypes.object.isRequired,
	memberList: PropTypes.object.isRequired
};
const mapStateToProps = ({ search: { memberList, loading } }) => ({
	memberList,
	loading
});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getMemberByName, clearMemberList }
	)(SearchByName)
);
