import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
// const Handle = Slider.Handle;

const wrapperStyle = { width: 400 };

export default function CustomizedSlider({
	// min,
	// max,
	// defaultValue,
	// onChange,
	text,
	unit,
	...props
}) {
	return (
		<div>
			<div style={wrapperStyle}>
				<p>{text}</p>
				<Range
					{...props}
					// min={min}
					// max={max}
					// defaultValue={defaultValue}
					// onChange={onChange}
					tipFormatter={value => `${value} ${unit}`}
				/>
			</div>
		</div>
	);
}

CustomizedSlider.defaultProps = {
	unit: "",
	text: "",
	defaultValue: [20, 40]
};
