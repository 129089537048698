import axios from "axios";
import {
	GET_ANALYSIS,
	GET_ANALYSIS_OVERALL,
	GET_COUNT,
	GET_PROGRESS,
	SET_ANALYSIS_LOADING,
	SET_PROGRESS_LOAD,
	GET_SCHOOL_LEVEL,
	GET_SCHOOL_STUDENT
} from "./types";

export const getCount = wardData => dispatch => {
	// {
	//     "wardNumber": 1
	// }
	// to get data count for wardNumber 1
	// const ward = wardData.wardNumber ? wardData.wardNumber : 0;
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});
	axios
		.get(`/api/analysis/population/${wardData}`)
		.then(res => {
			dispatch({
				type: GET_COUNT,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getProgressData = (wardData, option) => dispatch => {
	// {
	//     "wardNumber": 1
	// }
	// to get data count for wardNumber 1
	dispatch({
		type: SET_PROGRESS_LOAD,
		payload: true
	});
	axios
		.post(`/api/analysis/population/dashboard/${option}`, wardData)
		.then(res => {
			dispatch({
				type: GET_PROGRESS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getAnalaysisFamilyDetailChartData = (
	wardData,
	option
) => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});
	axios
		.get(`/api/analysis/family-detail/${option}/${wardData}`)
		.then(res => {
			console.log("fetched");
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getAnalaysisHouseDetailChartData = option => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});

	axios
		.get(`/api/analysis/house-detail/${option}`)
		.then(res => {
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
		});
};

export const getAnalaysisFamilyChartData = (wardData, history) => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});

	axios
		.post(`/api/analysis/`, wardData)
		.then(res => {
			console.log("actiondata", res.data);
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getAnalaysisHomeChartData = (wardData, history) => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});

	axios
		.post(`/api/analysis/house/`, wardData)
		.then(res => {
			console.log("actiondata", res.data);
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getAnalaysisHouseReportDetailChartData = (
	wardData,
	option
) => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});
	console.log("actiondata");
	axios
		.post(`/api/analysis/report/detail/${option}`, wardData)
		.then(res => {
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
			// dispatch({
			// 	type: GET_ERRORS,
			// 	payload: err.response.data
			// });
		});
};

export const getAnalaysisInstituteChartData = (
	wardData,
	option
) => dispatch => {
	const ward = wardData.wardNumber ? wardData.wardNumber : 0;
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});

	axios
		.get(`/api/analysis/institute/detail/school/${option}/${ward}`)
		.then(res => {
			dispatch({
				type: option === "level" ? GET_SCHOOL_LEVEL : GET_SCHOOL_STUDENT,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
		});
};

export const getAnalaysisInstituteDetailData = () => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING,
		payload: true
	});

	axios
		.get(`/api/analysis/institute/detail/overall`)
		.then(res => {
			dispatch({
				type: GET_ANALYSIS_OVERALL,
				payload: res.data
			});
		})
		.catch(err => {
			console.error(err);
		});
};
