import {
	Fab,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PrintIcon from "@material-ui/icons/Print";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clearHouseDetail,
	getFamilyById,
	getHouseByHouseNumber
} from "../../../../../actions/searchAction";
import ConstructMessage from "../../../sms/message/ConstructMessage";
import getNepaliNumber from "../../../../../utils/getNepaliNumber";
import { house, selectedLanguage } from "../../../../../variable/global";
// import Table from "../../../../common/Table/Table.jsx";
import Card from "../../../../common/Card/Card.jsx";
import CardBody from "../../../../common/Card/CardBody.jsx";
import CardHeader from "../../../../common/Card/CardHeader.jsx";
import FullScreenDialog from "../../../../common/frequent/dialog/FullScreen";
import ReactToPrint from "../../../../common/frequent/print/ReactToPrint.jsx";
import GridContainer from "../../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../../common/Grid/GridItem.jsx";
import GetHouseDetail from "../houseNumber/GetHouseDetail";
import TablePaginationActionsWrapped from "./TablePagination";
class NamePagination extends Component {
	constructor(props) {
		super(props);
		props.clearHouseDetail();
	}
	state = {
		familyMemberOpen: false,
		sendSmsOpen: false,
		member: {},
		houseOpen: false,
		house: {},
		houseNumber: null,
		beforePrint: false,
		component: null
	};
	handleAfterPrint = () => {
		this.setState({ beforePrint: false });
	};

	handleBeforePrint = () => {
		this.setState({ beforePrint: true });
	};

	getContent = () => {
		return this.setState(
			{ beforePrint: true, component: this.printRefHouse },
			() => {
				return this.state.component;
			}
		);
	};
	getLivingType = livingType => {
		if (livingType === "permanent") return "स्थाई";
		else if (livingType === "temporary") return "अस्थाई";
		else return "सुकुम्बासी";
	};

	onNameClick = event => {
		const id = event.target.id;
		this.setState({ familyMemberOpen: true }, () => {
			this.props.getFamilyById(id);
		});
	};

	onHouseNumberClick = event => {
		const houseNumber = event.target.id;
		this.setState({ houseOpen: true, houseNumber }, () => {
			this.props.getHouseByHouseNumber(houseNumber);
		});
	};

	componentDidUpdate(prevProps) {
		console.log("row", this.props.rows);
		if (prevProps.member !== this.props.member) {
			this.setState({
				member: this.props.member
			});
		}

		if (prevProps.house !== this.props.house) {
			this.setState({
				house: this.props.house
			});
		}
	}

	handleClickOpen = name => {
		this.setState({ [name]: true });
	};

	handleCloseMember = name => {
		this.setState({ [name]: false, member: {} });
	};

	handleCloseHouse = name => {
		this.setState({ [name]: false, house: {} });
	};

	 isValidPhone = phoneNumber =>
    phoneNumber &&
    phoneNumber.toString().length === 10 &&
    phoneNumber.toString().startsWith("98");

  handleClose = name => {
    this.setState({ [name]: false });
  };

  getPhoneNumbers = ({ family }) => {
    var owner = family.find(each => each.answererRelation == "self_house_own");
    if (owner && this.isValidPhone(owner.contactNumber))
      return owner.contactNumber;
    const otherNumber = family.find(each =>
      this.isValidPhone(each.contactNumber)
    );
    return otherNumber ? otherNumber.contactNumber : null;
  };

  getFullScreen = event => {
    const data = event.target.id;
    this.setState({ sendSmsOpen: true, contacts: data });
  };


	render() {
		const {
			classes,
			title,
			subtitle,
			tableHeader,
			rows,
			rowsPerPage,
			page,
			handleChangePage,
			handleChangeRowsPerPage
		} = this.props;
const phoneNumbers = rows.map(this.getPhoneNumbers);
		// const count = this.getTotalCount(rows);

		const emptyRows =
			rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
							>
								<Grid item>
									<Grid item>
										<Typography variant="h6" className={classes.cardTitleWhite}>
											{title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											variant="body2"
											className={classes.cardCategoryWhite}
										>
											{subtitle}
										</Typography>
									</Grid>
								</Grid>

								<Grid item>
									<Typography variant="body2" className={classes.lengthFamily}>
										<b>प्रतिफल:</b> {rows.length} {house[selectedLanguage]}
									</Typography>
									 <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={classes.fab}
                      onClick={this.getFullScreen}
                    >
                      SMS
                    </Fab>

									{rows.length !== 0 && (
										<ReactToPrint
											style={{ marginTop: 200 }}
											onBeforePrint={this.handleBeforePrint}
											onAfterPrint={this.handleAfterPrint}
											trigger={() => (
												<Fab
													variant="extended"
													aria-label="Delete"
													className={classes.fab}
												>
													<PrintIcon className={classes.printIcon} />
												</Fab>
											)}
											data={this.state.component}
											content={this.getContent}
										/>
									)}
								</Grid>
								</Grid>
							</Grid>
						</CardHeader>
						 {this.state.sendSmsOpen && (
              <FullScreenDialog
                name="sendSmsOpen"
                open={this.state.sendSmsOpen}
                title={`Send Sms `}
                handleClose={this.handleClose}
              >
                {rows && (
                  <div className={classes.smsRoot}>
                    <ConstructMessage data={phoneNumbers} header={false} />
                  </div>
                )}
              </FullScreenDialog>
            )}

						{this.state.houseOpen && (
							<FullScreenDialog
								name="houseOpen"
								open={this.state.houseOpen}
								title={`House Detail `}
								handleClose={this.handleCloseHouse}
							>
								<Grid container spacing={2}>
									{this.props.load ? (
										<Grid
											style={{ marginTop: "40vh" }}
											container
											direction="row"
											justify="center"
											alignItems="center"
										>
											<Grid item>
												<ClipLoader
													sizeUnit={"px"}
													size={84}
													color={"#00ACC1"}
													loading={this.props.load}
												/>
											</Grid>
										</Grid>
									) : (
										<Grid
											container
											direction="row"
											justify="center"
											alignItems="flex-start"
										>
											{this.state.house && (
												<Grid item xs={9}>
													<GetHouseDetail
														houseNumber={this.state.houseNumber}
														house={this.state.house}
													/>
												</Grid>
											)}
										</Grid>
									)}
								</Grid>
							</FullScreenDialog>
						)}
						<CardBody>
							{/* Table here */}
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{tableHeader.map((header, key) => (
											<TableCell key={key}>
												{header[selectedLanguage]}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.length !== 0 &&
										rows
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((row, id) => (
												<TableRow key={id}>
													<TableCell component="th" scope="row">
														{getNepaliNumber(page * rowsPerPage + (id + 1))}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.wardNumber)}
													</TableCell>
													<TableCell align="left">
														<p
															className={classes.button}
															id={row.houseNumber}
															onClick={this.onHouseNumberClick}
														>
															{getNepaliNumber(row.houseNumber)}
														</p>
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.maleNumber)}
													</TableCell>
													<TableCell align="left">
														{this.getLivingType(row.livingType)}
													</TableCell>
													<TableCell align="left">
														{getNepaliNumber(row.yearSettled)}
													</TableCell>
												</TableRow>
											))}
									<TableRow />
									{/* {count.length > 0 &&
										count.map((each, i) => (
											<TableRow>
												{i === 0 && (
													<TableCell rowSpan={count.length} colSpan={4} />
												)}
												<TableCell colSpan={2} component="th" scope="row">
													{each.name}
												</TableCell>
												<TableCell align="left">{each.value}</TableCell>
											</TableRow>
										))} */}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
											colSpan={7}
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												native: true
											}}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * (3)
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	button: {
		cursor: "pointer",
		color: "#2196f3"
	},
	memberDialog: {
		marginTop: theme.spacing.unit * (5)
	},
	lengthFamily: {
		color: "white",
		fontSize: "1rem"
	}
});
NamePagination.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ search: { member, house, load } }) => ({
	member,
	house,
	load
});
export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getFamilyById, getHouseByHouseNumber, clearHouseDetail }
	)(NamePagination)
);
