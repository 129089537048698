import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
// import Table from "../../../common/Table/Table.jsx";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import GridContainer from "../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../common/Grid/GridItem.jsx";
import TablePaginationActionsWrapped from "./TablePaginationActions";

class DeathPagination extends Component {
	getGender = gender => {
		if (gender === "death_female") return "महिला";
		else if (gender === "dearh_male") return "पुरुष";
		else return "अन्य";
	};

	getDeathReson = reason => {
		const data = reason.map(each => {
			if (each === "epidimics") return "माहामारी";
			if (each === "tranferable") return "सर्नेरोग ( टीबी  जण्डिसआदि)";
			if (each === "aged") return "काल गति";
			if (each === "aids") return "HIVAIDS";
			if (each === "cancer") return "क्यान्सर";
			if (each === "diabetes") return "मधुमेह";
			if (each === "asthma") return "दम";
			if (each === "blood_pressure") return "रक्तचाप";
			if (each === "accident") return "दुर्घटना";
			if (each === "suicide") return "आत्महत्या";
			else return "अन्य";
		});
		return data.join(", ");
	};

	getTotalCount = rows => {
		if (rows.length > 0) {
			const { gender } = this.props || 0;
			const count = [];

			if (gender == 0) count.push({ name: "कुल", value: rows.length });
			if (gender == 0 || gender == 1) {
				count.push({
					name: "महिला",
					value: rows.filter(({ gender }) => gender === "death_female").length
				});
			}
			if (gender == 0 || gender == 2) {
				count.push({
					name: "पुरुष",
					value: rows.filter(({ gender }) => gender == "dearh_male").length
				});
			}
			if (gender == 0 || gender == 3) {
				count.push({
					name: "तेस्रो लिङ्गी",
					value: rows.filter(
						({ gender }) => gender !== "dearh_male" && gender !== "death_female"
					).length
				});
			}

			return count;
		}
		return [];
	};

	render() {
		const {
			classes,
			title,
			subtitle,
			tableHeader,
			rows,
			rowsPerPage,
			page,
			gender,
			handleChangePage,
			handleChangeRowsPerPage
		} = this.props;

		const count = this.getTotalCount(rows);

		const emptyRows =
			rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								{title}
							</Typography>
							<Typography variant="body2" className={classes.cardCategoryWhite}>
								{subtitle}
							</Typography>
						</CardHeader>
						<CardBody>
							{/* Table here */}
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{tableHeader.map(header => (
											<TableCell>{header}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, id) => (
											<TableRow key={id}>
												<TableCell component="th" scope="row">
													{getNepaliNumber(page * rowsPerPage + (id + 1))}
												</TableCell>
												<TableCell align="left">
													{getNepaliNumber(row.wardNumber)}
												</TableCell>
												<TableCell align="left">
													{getNepaliNumber(row.houseNumber)}
												</TableCell>
												<TableCell align="left">{row.name}</TableCell>
												<TableCell align="left">
													{this.getGender(row.gender)}
												</TableCell>
												<TableCell align="left">
													{getNepaliNumber(row.age)}
												</TableCell>
												<TableCell align="left">
													{this.getDeathReson(row.reason)}
												</TableCell>
											</TableRow>
										))}
									<TableRow />
									{count.length > 0 &&
										count.map((each, i) => (
											<TableRow>
												{i === 0 && (
													<TableCell rowSpan={count.length} colSpan={4} />
												)}
												<TableCell colSpan={2} component="th" scope="row">
													{each.name}
												</TableCell>
												<TableCell align="left">{each.value}</TableCell>
											</TableRow>
										))}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
											colSpan={7}
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												native: true
											}}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActionsWrapped}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	}
});

export default withStyles(styles)(DeathPagination);
