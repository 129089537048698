import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AddHouseHold from "./AddHouseHold";
import EditHouseHold from "./EditHouseHold";
import Home from "./Home";

class index extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path={`${this.props.match.url}/add`}
						render={props => <AddHouseHold {...props} />}
					/>
					<Route
						path={`${this.props.match.url}/edit/:houseId`}
						render={props => <EditHouseHold {...props} />}
					/>

					<Route path={`${this.props.match.url}`} component={Home} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(index);
