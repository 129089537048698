import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import { selectedLanguage } from "../../../../../variable/global";
import {
	facilityCount,
	facilityType,
	houseFacilityTitle
} from "../../../../../variable/houseSurvey";
import { Title } from "../../../../common/frequent/QuestionAnswer";

function mapValue(value, obj) {
	return obj[value];
}

class SearchByHouseNumber extends Component {
	render() {
		const { classes, houseFacility } = this.props;
		return (
			<Fragment>
				{houseFacility !== undefined && houseFacility.length > 0 && (
					<Grid item container direction="column" className={classes.container}>
						<Title title={houseFacilityTitle} />

						<Grid item container>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell>{facilityType[selectedLanguage]}</TableCell>
										<TableCell align="right">
											{facilityCount && facilityCount[selectedLanguage]}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{houseFacility.map((row, key) => (
										<TableRow key={key}>
											<TableCell scope="row">
												{mapValue(row.title, {
													TV: "टि.भी",
													Radio: "रेडियो",
													Telephone: "टेलिफोन",
													Mobile: "मोबाइल",
													Laptop: "ल्यापटप",
													Computer: "कम्पुटर",
													Motorcycle: "मोटरसाइकल",
													Scooter: "स्कुटर",
													Car: "कार",
													Jeep: "जिप",
													Tractor: "ट्राकटर",
													Truck: "ट्रक",
													Auto: "अटो",
													Tempo: "टेम्पो",
													Dozar: "डोज़र",
													Tipper: "टिप्पर",
													Skavater: "स्काभेटर"
												})}
											</TableCell>
											<TableCell align="right">
												{row && row.count && getNepalNumber(row.count)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
		overflowX: "auto"
	},
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	eachQnAns: {
		marginTop: theme.spacing.unit * 1
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
