import { SAVE_HOUSE, SHOW_HOUSE, GET_SURVEY_ERRORS } from "../actions/types";

const initialState = {
	house: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SAVE_HOUSE:
			return {
				...state,
				submitLoading: false,
				submit: action.payload.success
			};
		case SHOW_HOUSE:
			return {
				...state,
				data: action.payload
			};
		case GET_SURVEY_ERRORS:
			return {
				...state,
				errors: action.payload
			};
		default:
			return state;
	}
}
