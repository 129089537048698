import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { selectedLanguage, yes, no } from "../../../../../variable/global";
import getNepalNumber from "../../../../../utils/getNepaliNumber";
import {
	beefishTitle,
	beefishType,
	beefishTotal,
	beefishProduction,
	fish,
	silk,
	bee,
	beefishYesNo,
} from "../../../../../variable/houseSurvey";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";

class SearchByHouseNumber extends Component {
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		//Gobal Variable

		const { classes, houseNumber, beefishsilk } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={beefishTitle} />
				<Grid item container>
					{beefishsilk.fishBeeSilk ? (
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>{beefishType[selectedLanguage]}</TableCell>
									<TableCell align="right">
										{beefishTotal[selectedLanguage]}
									</TableCell>
									<TableCell align="right">
										{beefishProduction[selectedLanguage]}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{beefishsilk.fishBeeSilk === true &&
									beefishsilk.fish !== undefined && (
										<TableRow>
											<TableCell component="th" scope="row">
												{fish[selectedLanguage]}
											</TableCell>
											<TableCell align="right">
												{beefishsilk &&
													beefishsilk.fish &&
													beefishsilk.fish.count &&
													getNepalNumber(beefishsilk.fish.count)}
											</TableCell>
											<TableCell align="right">
												{beefishsilk &&
													beefishsilk.fish &&
													beefishsilk.fish.production &&
													getNepalNumber(beefishsilk.fish.production)}
											</TableCell>
										</TableRow>
									)}
								{beefishsilk.fishBeeSilk === true &&
									beefishsilk.bee !== undefined && (
										<TableRow>
											<TableCell component="th" scope="row">
												{bee[selectedLanguage]}
											</TableCell>
											<TableCell align="right">
												{beefishsilk &&
													beefishsilk.bee &&
													beefishsilk.bee.count &&
													getNepalNumber(beefishsilk.bee.count)}
											</TableCell>
											<TableCell align="right">
												{beefishsilk &&
													beefishsilk.bee &&
													beefishsilk.bee.production &&
													getNepalNumber(beefishsilk.bee.production)}
											</TableCell>
										</TableRow>
									)}
								{beefishsilk.fishBeeSilk === true &&
									beefishsilk.silk !== undefined && (
										<TableRow>
											<TableCell component="th" scope="row">
												{silk[selectedLanguage]}
											</TableCell>
											<TableCell align="right">
												{getNepalNumber(beefishsilk.silk.count)}
											</TableCell>
											<TableCell align="right">
												{getNepalNumber(beefishsilk.silk.production)}
											</TableCell>
										</TableRow>
									)}
							</TableBody>
						</Table>
					) : (
						<QuestionAnswer
							question={beefishYesNo[selectedLanguage]}
							answer={this.getYesNo(beefishsilk.fishBeeSilk)}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		width: "auto"
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
