import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Button,
	Divider,
	Form,
	Icon,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Typography,
	Upload
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addClubSurvey } from "../../../actions/instituteAction";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import {
	add,
	address,
	clubActive,
	darta,
	description,
	designation,
	establish,
	latitude,
	longitude,
	member,
	name,
	phone,
	photo,
	specName,
	specTitle,
	specValue,
	staff,
	wardQuestion
} from "../../../variable/institute";

const { TextArea } = Input;
const { Option } = Select;
const RadioGroup = Radio.Group;

class Club extends Component {
	random = (low, high) => {
		return Math.random() * (high - low) + low;
	};

	state = {
		errors: {},
		// image upload state
		uniqueIdentifier:
			this.props.match.params.category +
			"_" +
			this.random(10000, 99999) +
			this.random(1000, 9999),
		previewVisible: false,
		previewImage: "",
		fileList: {
			clubImage: []
		},
		loaded: 0,
		// other
		name: "",
		address: "",
		desc: "",
		memberCount: "",
		photo: "",
		hasRegistered: "",
		category: "",
		ward: "",
		latitude: "",
		longitude: "",
		establishedAt: "",
		staff: [],
		maxStaffIndex: 1,
		maxSpecIndex: 1,
		isActive: "",
		spec: [],
		isView: this.props.location.view
	};

	// image functions uplocad
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};

	// imageUpload
	handleChangeFile = (name, value, imageName) => {
		//name ma house and value ma list of filelist
		console.log("CF", value);
		this.setState(
			prevState => ({
				...prevState,
				fileList: {
					...prevState.fileList,
					[name]: {
						image: value.fileList,
						fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
					}
				}
			}),
			() => {
				if (value.fileList.length > 0) this.handleUpload(name, imageName);
				if (value.fileList.length === 0) this.removeUpload(name, imageName);
			}
		);
	};

	// upload image
	handleUpload = (name, imageName) => {
		const fileList = this.state.fileList[name];
		const data = new FormData();
		const imageObject = fileList.image[0].originFileObj;

		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
		data.append("file", imageObject, fileName);

		Axios.post("/api/imageUpload/upload", data, {
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
				});
			}
		})
			.then(res => {
				// then print response status
				// message.success("upload successfully.");
				console.log(res.statusText);
			})
			.catch(err => false && message.error("upload failed."));
	};

	// remove uploaded image
	removeUpload = imageName => {
		let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

		Axios.get(`/api/imageUpload/delete/${fileName}`)
			.then(res => {
				// then print response status
				false && message.success("deleted successfully.");
				console.log(res.statusText);
			})
			.catch(err => false && message.error("upload failed."));
	};

	componentDidMount() {
		let clubId = this.props.match.params.id;
		if (clubId)
			Axios.get(`/api/club/fetch/${clubId}`)
				.then(res => {
					this.setState({
						name: res.data.name,
						address: res.data.address,
						category: res.data.category,
						establishedAt: moment(res.data.establishedAt).format("YYYY-MM-DD"),
						hasRegistered: res.data.hasRegistered,
						ward: res.data.ward,
						memberCount: res.data.memberCount,
						spec: res.data.specification,
						maxSpecIndex:
							res.data.specification && res.data.specification.length,
						staff: res.data.staff,
						latitude: res.data.geo && res.data.geo.lat,
						longitude: res.data.geo && res.data.geo.lng,
						isActive: res.data.isActive,
						desc: res.data.desc,
						// image
						fileList: {
							clubImage: {
								fileName: res.data.uniqueIdentifier + "_club.jpg",
								image: [
									{
										uid: "-1",
										name: `${res.data.uniqueIdentifier}_club.jpg`,
										status: "done",
										url: `/api/imageUpload/image/${
											res.data.uniqueIdentifier
										}_club.jpg`
									}
								]
							}
						}
					});
				})
				.catch(err => {});
	}

	onSubmit = event => {
		event.preventDefault();

		let newClub = {
			name: this.state.name,
			ward: this.state.ward,
			uniqueIdentifier: this.state.uniqueIdentifier,
			establishedAt: this.state.establishedAt,
			hasRegistered: this.state.hasRegistered,
			address: this.state.address,
			lng: this.state.longitude,
			lat: this.state.latitude,
			memberCount: this.state.memberCount,
			desc: this.state.desc,
			category: this.props.category,
			specification: this.state.spec,
			isActive: this.state.isActive,
			staff: this.state.staff
		};

		if (this.props.isUpdate) newClub.id = this.props.match.params.id;
		this.props
			.addClubSurvey(newClub)
			.then(res => {
				if (res.type === "SAVE_CLUB") {
					message.success("Sucessfully saved");
					this.props.history.push(
						`/survey-by-institution/show/${this.props.match.params.category}`
					);
				}
			})
			.catch(err => {
				message.error("Cannot Saved at the moment");
				console.log("err", err.response.data);
				if (err) this.setState({ errors: err.response.data });
			});
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	handleSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	addSpec = () => {
		this.setState(prevState => ({
			maxSpecIndex: prevState.maxSpecIndex + 1
		}));
	};

	addStaff = () => {
		this.setState(prevState => ({
			maxStaffIndex: prevState.maxStaffIndex + 1
		}));
	};

	handleNameSpec = index => event => {
		// arr.splice(2, 1, "Lene");
		// event.persist();

		const name = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.name = name;
			data.value = isEmpty(spec[index]) ? "" : spec[index].value;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleValueSpec = index => event => {
		const value = event.target.value;
		this.setState(prevState => {
			const spec = [...prevState.spec];

			const data = {};
			data.value = value;
			data.name = isEmpty(spec[index]) ? "" : spec[index].name;
			spec.splice(index, 1, data);
			return { spec };
		});
	};

	handleNameStaff = index => event => {
		const name = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.name = name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleAddressStaff = index => event => {
		const address = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.address = address;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handlePhoneStaff = index => event => {
		const phone = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.phone = phone;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.designation = isEmpty(staff[index]) ? "" : staff[index].designation;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	handleDesignationStaff = index => event => {
		const designation = event.target.value;
		this.setState(prevState => {
			const staff = [...prevState.staff];

			const data = {};
			data.designation = designation;
			data.name = isEmpty(staff[index]) ? "" : staff[index].name;
			data.address = isEmpty(staff[index]) ? "" : staff[index].address;
			data.phone = isEmpty(staff[index]) ? "" : staff[index].phone;
			staff.splice(index, 1, data);
			return { staff };
		});
	};

	removeSpec = index => () => {
		this.setState(prevState => {
			const spec = [...prevState.spec];
			spec.splice(index, 1);
			return { spec, maxSpecIndex: prevState.maxSpecIndex - 1 };
		});
	};

	removeStaff = index => () => {
		this.setState(prevState => {
			const staff = [...prevState.staff];
			staff.splice(index, 1);
			return { staff, maxStaffIndex: prevState.maxStaffIndex - 1 };
		});
	};

	getSpecUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxSpecIndex, spec } = this.state;
		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxSpecIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={6}>
								<Form.Item label={specName[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={spec && spec[each] && spec[each].name}
										onChange={this.handleNameSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={6}>
								<Form.Item label={specValue[selectedLanguage]}>
									<Input
										defaultValue="hello"
										name="value"
										type="text"
										value={spec && spec[each] && spec[each].value}
										onChange={this.handleValueSpec(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton
										onClick={this.removeSpec(each)}
										disabled={this.state.isView}
									>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addSpec}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	getStaffUI = () => {
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { maxStaffIndex, staff } = this.state;

		return (
			<Grid
				container
				item
				xs={12}
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxStaffIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid xs={6}>
								<Form.Item label={name[selectedLanguage]}>
									<Input
										name="name"
										type="text"
										value={staff && staff[each] && staff[each].name}
										onChange={this.handleNameStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>
							<Grid xs={6}>
								<Form.Item label={phone[selectedLanguage]}>
									<Input
										name="phone"
										type="number"
										value={staff && staff[each] && staff[each].phone}
										onChange={this.handlePhoneStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={address[selectedLanguage]}>
									<Input
										name="address"
										type="text"
										value={staff && staff[each] && staff[each].address}
										onChange={this.handleAddressStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							<Grid xs={6}>
								<Form.Item label={designation[selectedLanguage]}>
									<Input
										name="designation"
										type="text"
										value={staff && staff[each] && staff[each].designation}
										onChange={this.handleDesignationStaff(each)}
										margin="normal"
										variant="outlined"
										disabled={this.state.isView}
									/>
								</Form.Item>
							</Grid>

							{each > 0 && (
								<Grid item>
									<IconButton onClick={this.removeStaff(each)}>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button
						type="dashed"
						onClick={this.addStaff}
						disabled={this.state.isView}
					>
						<Icon type="plus" />
						{add[selectedLanguage]}
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	render() {
		const { classes, title } = this.props;
		const { previewVisible, previewImage, errors } = this.state;
		const fileList = this.state.fileList;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		return (
			<Paper className={classes.root} elevation={1}>
				<Typography variant="h5">{title}</Typography>
				<Form {...formItemLayout} onSubmit={this.onSubmit}>
					<Grid xs={6}>
						<Form.Item
							validateStatus={errors.name ? "error" : ""}
							help={errors.name || ""}
							label={name[selectedLanguage]}
						>
							<Input
								required
								onChange={this.handleChange("name")}
								value={this.state.name}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={address[selectedLanguage]}>
							<Input
								onChange={this.handleChange("address")}
								value={this.state.address}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={description[selectedLanguage]}>
							<TextArea
								onChange={this.handleChange("desc")}
								value={this.state.desc}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={establish[selectedLanguage]}>
							<Input
								type="date"
								name="establishedAt"
								onChange={this.handleChange("establishedAt")}
								value={this.state.establishedAt}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={clubActive[selectedLanguage]}>
							<RadioGroup
								onChange={this.handleChange("isActive")}
								value={this.state.isActive ? "yes" : "no"}
								// disabled={this.state.isView == true}
							>
								<Radio value="yes" disabled={this.state.isView}>
									{yes[selectedLanguage]}
								</Radio>
								<Radio value="no" disabled={this.state.isView}>
									{no[selectedLanguage]}
								</Radio>
							</RadioGroup>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={member[selectedLanguage]}>
							<Input
								type="number"
								value={this.state.memberCount}
								onChange={this.handleChange("memberCount")}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={latitude[selectedLanguage]}>
							<Input
								type="number"
								value={this.state.latitude}
								onChange={this.handleChange("latitude")}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={longitude[selectedLanguage]}>
							<Input
								type="number"
								value={this.state.longitude}
								onChange={this.handleChange("longitude")}
								disabled={this.state.isView}
							/>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={photo[selectedLanguage]}>
							<Fragment>
								{!this.state.isView ? (
									<Upload
										name="file"
										// beforeUpload={this.props.beforeUpload}
										multiple={false}
										// action="/api/imageUpload/upload"
										accept="image/*"
										listType="picture-card"
										fileList={fileList.clubImage && fileList.clubImage.image}
										onPreview={this.handlePreview}
										onChange={fileList =>
											this.handleChangeFile("clubImage", fileList, "club")
										}
										disabled={this.state.isView}
									>
										{fileList &&
										fileList.clubImage &&
										fileList.clubImage.image &&
										fileList.clubImage.image.length === 1
											? null
											: uploadButton}
									</Upload>
								) : (
									<Modal
										visible={previewVisible}
										footer={null}
										onCancel={this.handleCancel}
									>
										<img
											alt="example"
											style={{ width: "100%" }}
											src={previewImage}
										/>
									</Modal>
								)}
							</Fragment>
						</Form.Item>
					</Grid>

					<Grid xs={6}>
						<Form.Item
							validateStatus={errors.ward ? "error" : ""}
							help={errors.ward || ""}
							label={wardQuestion[selectedLanguage]}
						>
							<Select
								required
								placeholder="------------"
								onChange={val => this.handleSelectChange("ward", val)}
								value={this.state.ward}
								disabled={this.state.isView}
							>
								{ward.map(woda => (
									<Option value={woda.value}>{woda[selectedLanguage]}</Option>
								))}
							</Select>
						</Form.Item>
					</Grid>
					<Grid xs={6}>
						<Form.Item label={darta[selectedLanguage]}>
							<RadioGroup
								onChange={this.handleChange("hasRegistered")}
								value={this.state.hasRegistered ? "yes" : "no"}
							>
								<Radio value="yes" disabled={this.state.isView}>
									{yes[selectedLanguage]}
								</Radio>
								<Radio value="no" disabled={this.state.isView}>
									{no[selectedLanguage]}
								</Radio>
							</RadioGroup>
						</Form.Item>
					</Grid>
					<Divider orientation="left">{specTitle[selectedLanguage]}</Divider>
					{this.getSpecUI()}
					<Divider orientation="left">{staff[selectedLanguage]}</Divider>
					{this.getStaffUI()}

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							disabled={this.state.isView}
						>
							{this.props.isUpdate ? "update" : add[selectedLanguage]}
						</Button>
					</Form.Item>
				</Form>
			</Paper>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ addClubSurvey }
	)(withRouter(Club))
);
