import {
	Grid,
	Typography,
	FormControlLabel,
	OutlinedInput,
	MenuItem,
	Fab,
	TextField,
	IconButton
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardFooter from "../../../common/Card/CardFooter.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import TextFields from "../../../common/frequent/TextField";
import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import CardAvatar from "../../../common/Card/CardAvatar.jsx";
import RadioButton from "../../../common/frequent/RadioButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Input, Select, Icon, Form, Radio, Modal, Button } from "antd";
import Axios from "axios";
import UploadImage from "./UploadImage";
import "antd/dist/antd.css";

const Option = Select.Option;
class EditProfile extends Component {
	state = {
		designations: []
	};

	componentDidMount() {
		Axios.get("/api/sms/designation/list")
			.then(res => {
				this.setState({ designations: res.data });
			})
			.catch(err => {});
	}

	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.value);
	};

	handleChangeAnt = (name, value) => this.props.handleChange(name, value);

	getPhoneUI = () => {
		const {
			addPhone,
			handlePhoneContact,
			handlePhoneType,
			handlePrimaryPhone,
			removePhone,
			maxPhoneIndex,
			primaryPhone,
			phone,
			errors,
			clearError
		} = this.props;

		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 }
			}
		};
		const { getFieldDecorator } = this.props.form;
		return (
			<Grid
				container
				direction="column"
				justify="flex-start"
				alignItems="flex-start"
			>
				{[...Array(maxPhoneIndex).keys()].map(each => {
					return (
						<Grid
							container
							direction="row"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid item>
								<Form.Item>
									{getFieldDecorator(`phone${each}`, {
										rules: [
											{
												required: true,
												message: "Provide valid phone number "
											}
										],
										initialValue: phone && phone[each] && phone[each].contact
									})(
										<Input
											addonBefore="Phone"
											onFocus={clearError}
											id="contact"
											name="contact"
											type="number"
											min={0}
											error={errors[`contact${each}`]}
											placeholder="contact"
											// value={phone && phone[each] && phone[each].contact}
											onChange={handlePhoneContact(each)}
											margin="normal"
											variant="outlined"
											addonAfter={
												<Select
													showSearch
													value={
														(phone && phone[each] && phone[each].phoneType) ||
														"Phone"
													}
													style={{ width: 150 }}
													placeholder="Select a person"
													optionFilterProp="children"
													error={errors[`phoneType${each}`]}
													helperText={errors[`phoneType${each}`]}
													onChange={val => handlePhoneType(each, val)}
													// onFocus={handleFocus}
													// onBlur={handleBlur}
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
												>
													{[
														{ value: "Phone", label: "Phone" },
														{ value: "Landline", label: "Landline" },
														{ value: "Home", label: "Home" },
														{ value: "Office", label: "Office" }
													].map((each, i) => (
														<Option key={i} value={each.value}>
															{each.label}
														</Option>
													))}
												</Select>
											}
										/>
									)}
								</Form.Item>
							</Grid>
							<Grid item />
							<Grid item>
								<IconButton onClick={handlePrimaryPhone(each)}>
									{primaryPhone === each ? (
										<Icon type="check-circle" theme="filled" />
									) : (
										<Icon type="check-circle" />
									)}
								</IconButton>
							</Grid>
							{each != 0 && (
								<Grid item>
									<IconButton onClick={removePhone(each)}>
										<RemoveIcon style={{ color: "red" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);
				})}
				<Form.Item {...formItemLayoutWithOutLabel}>
					<Button type="dashed" onClick={addPhone}>
						<Icon type="plus" />
						Add Phone
					</Button>
				</Form.Item>
			</Grid>
		);
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log("Received values of form: ", values);
				this.props.onSubmit(values);
			}
		});
	};

	render() {
		const {
			classes,
			name,
			email,
			designation,
			gender,
			address,
			phone,
			handleChange,
			onSubmit,
			submitText,
			errors
		} = this.props;
		const { designations } = this.state;
		console.log("phone edited", phone);
		const { getFieldDecorator } = this.props.form;

		return (
			<Card profile>
				{/* <CardAvatar profile>
					
				</CardAvatar> */}
				<CardHeader color="primary" profile>
					<Typography variant="h5" className={classes.cardTitleWhite}>
						{this.props.title}
					</Typography>
					<Typography variant="h6" className={classes.cardCategoryWhite}>
						Complete your profile
					</Typography>
				</CardHeader>
				<CardBody>
					<form className={classes.form} onSubmit={this.handleSubmit}>
						<Grid container spacing={24}>
							<Grid item xs={12}>
								<Form layout="vertical">
									<Grid container direction="row">
										<Grid item xs={6}>
											<Form.Item label="Name" style={{ width: 250 }}>
												{getFieldDecorator("name", {
													rules: [
														{
															required: true,
															message: "Please input your name"
														}
													],
													initialValue: name
												})(<Input placeholder="Full name" />)}
											</Form.Item>
											<Form.Item label="E-mail" style={{ width: 250 }}>
												{getFieldDecorator("email", {
													rules: [
														{
															type: "email",
															message: "The input is not valid E-mail!"
														},
														{
															required: true,
															message: "Please input your E-mail!"
														}
													],
													initialValue: email
												})(
													<Input
														onChange={handleChange}
														placeholder="your valid email"
													/>
												)}
											</Form.Item>
											<Form.Item label="Address" style={{ width: 250 }}>
												{getFieldDecorator("address", {
													initialValue: address
												})(
													<Input
														onChange={handleChange}
														placeholder="Address"
													/>
												)}
											</Form.Item>
											<Form.Item label="Designation" style={{ width: 250 }}>
												{getFieldDecorator("designation", {
													rules: [
														{
															required: true,
															message: "Please select one"
														}
													],
													initialValue: designation
												})(
													<Select
														showSearch
														name="designation"
														optionFilterProp="children"
														onChange={val =>
															this.handleChangeAnt("designation", val)
														}
														// onFocus={handleFocus}
														// onBlur={handleBlur}
														filterOption={(input, option) =>
															option.props.children
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														}
													>
														<Option value="none">None</Option>
														{designations &&
															designations.length !== 0 &&
															designations.map((each, i) => (
																<Option key={i} value={each._id}>
																	{each.name}
																</Option>
															))}
													</Select>
												)}
											</Form.Item>

											<Form.Item label="Gender" style={{ width: 250 }}>
												{getFieldDecorator("gender", {
													rules: [
														{
															required: true,
															message: "Select your gender"
														}
													],
													initialValue: gender
												})(
													<Radio.Group>
														{[
															{ value: "male", label: "पुरुष" },
															{ value: "female", label: "महिला" },
															{ value: "other", label: "अन्य" }
														].map((each, i) => (
															<Radio key={i} value={each.value}>
																{each.label}
															</Radio>
														))}
													</Radio.Group>
												)}
											</Form.Item>
										</Grid>
										{/* <Grid item xs={5}>
											<ImagesUploader
												url="/api/profileUpload"
												optimisticPreviews
												multiple={false}
												onLoadEnd={err => {
													if (err) {
														console.error(err);
													}
												}}
												label="Upload a picture"
											/>
										</Grid> */}
									</Grid>
								</Form>
							</Grid>
							{/* <UploadImage /> */}
						</Grid>

						{this.getPhoneUI()}

						{/* <Grid item xs={8} className={classes.textSize} alignItems="center">
							
						</Grid> */}
						{/* <Grid item xs={8}>
							
						</Grid> */}
					</form>
				</CardBody>
				<CardFooter>
					<Button
						type="primary"
						icon="send"
						size="large"
						onClick={this.handleSubmit}
					>
						Save
					</Button>
				</CardFooter>
			</Card>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	textSize: {
		display: "flex"
	}
});

EditProfile.defaultProps = {
	isPasswordVisible: true
};

const EditProfileDemo = Form.create()(EditProfile);

export default withStyles(styles)(EditProfileDemo);
