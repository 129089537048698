import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LeafletMap from "../../../../common/frequent/map/LeafletMap";
import { Title } from "../../../../common/frequent/QuestionAnswer";

class HouseMap extends Component {
	render() {
		const mapHeader = { nepali: "नक्सा विवरण", english: "" };
		const { gps, houseNumber, classes } = this.props;
		if (gps == null || gps.lat == null || gps.lng == null) return null;
		return (
			<Grid item container direction="column">
				<Title title={mapHeader} />
				<Grid item container className={classes.map}>
					<LeafletMap lat={gps && gps.lat} lng={gps && gps.lng} zoom={12}>
						<Typography>घर न. {houseNumber}</Typography>
						<Typography>अक्षांश: {gps.lat}</Typography>
						<Typography>देशान्तर: {gps.lng}</Typography>
					</LeafletMap>
				</Grid>
			</Grid>
		);
	}
}

HouseMap.propTypes = {
	classes: PropTypes.object.isRequired
};

const styles = theme => ({
	map: {
		marignTop: theme.spacing.unit * 3
	}
});

export default withStyles(styles)(HouseMap);
