import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { selectedLanguage } from "../../../variable/global";
import { categoryList } from "../../../variable/institute";
import { formControlData } from "./config";

class InstitutionForm extends Component {
	getForms = () => {
		const category = this.props.category;
		if (category < 0) return <p>Please Select a Category</p>;
		var data = formControlData;

		return data.map((each, i) => {
			if (i === category)
				return (
					<each.comp
						isUpdate={this.props.isUpdate}
						title={categoryList[category][selectedLanguage]}
						category={categoryList[category].value}
						index={category}
					/>
				);
		});
	};
	render() {
		return this.getForms();
	}
}

const styles = theme => ({});

export default withStyles(styles)(InstitutionForm);
