import { Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { searchOption } from "../../../../../variable/advanceSearch";
import CheckBox from "../../../../common/frequent/CheckBox";
import RadioButton from "../../../../common/frequent/RadioButton";
import AutoSuggest from "./AutoSuggest";
import Slider from "./Range";
class SearchByHouseNumber extends Component {
	state = {
		tableType: 0, // 0 for family and 1 for house
		snack: false,
		snackVariant: "",
		snackMessage: ""
	};

	handleChange = event => {
		var data = this.props.state[event.target.name];
		if (data && data.length > 0 && !event.target.checked) {
			data.splice(data.indexOf(event.target.value), 1);
		} else if (data === undefined || data.length === 0) {
			data = [];
			data.push(event.target.value);
		} else data.push(event.target.value);

		this.props.handleChange(`option.${event.target.name}`, data);
	};

	// handleSliderChange = val => {
	// 	var data = this.props.state["age"];
	// 	if (data && data.length > 0 && !event.target.checked) {
	// 		data.splice(data.indexOf(event.target.value), 1);
	// 	} else if (data === undefined || data.length === 0) {
	// 		data = [];
	// 		data.push(val);
	// 	} else data.push(event.target.value);

	// 	this.props.handleChange(`option.age`, data);
	// };

	clearOnCloseTag = matchValue => {
		var data = "";
		if (Array.isArray(this.props.state[matchValue])) data = [];
		this.props.handleChange(`option.${matchValue}`, data);
	};

	handleCompChange = (name, value) => {
		this.props.handleChange(`option.${name}`, value);
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ snack: false });
	};

	setSnack = (msg, variant) => {
		this.setState({
			snack: true,
			snackVariant: variant,
			snackMessage: msg
		});
	};

	getFilteredUI = () => {
		const {
			state,
			classes: { eachOption, flexColumn }
		} = this.props;

		const age = (
			<Slider
				text="उमेर"
				style={{ width: "250px" }}
				name="age"
				min={0}
				max={120}
				color="#bf4040"
				defaultValue={state.age}
				range
				onChange={newValue => {
					// console.log("age", newValue);
					this.handleCompChange("age", newValue);
				}}
			/>
		);

		const totalIncome = (
			<Grid>
				<Grid item xs={6} sm={3} style={{ padding: "0px 30px 0px 10px" }}>
					<Slider
						text="वार्षिक आम्दानी"
						style={{ width: "250px" }}
						name="totalIncome"
						min={0}
						max={500000}
						color="#bf4040"
						defaultValue={state.totalIncome}
						range
						onChange={value => {
							this.handleCompChange("totalIncome", value);
						}}
					/>
				</Grid>
			</Grid>
		);

		const totalExpenditure = (
			<Grid>
				<Grid item xs={6} sm={3} style={{ padding: "0px 30px 0px 10px" }}>
					<Slider
						text="वार्षिक खर्च"
						style={{ width: "250px" }}
						name="totalExpenditure"
						min={0}
						max={500000}
						color="#bf4040"
						defaultValue={state.totalExpenditure}
						range
						onChange={value => {
							this.handleCompChange("totalExpenditure", value);
						}}
					/>
				</Grid>
			</Grid>
		);

		const ethnics = (
			<div>
				<p>जात/जात</p>

				<CheckBox
					isVisible
					checkList={searchOption.ethnics}
					value={state.ethnics}
					name="ethnics"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const answererRelation = (
			<div>
				<p>घरमुली संगको नाता</p>

				<CheckBox
					isVisible
					checkList={searchOption.answererRelation}
					value={state.answererRelation}
					name="answererRelation"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const educationLevel = (
			<div>
				<p>educationLevel</p>

				<CheckBox
					isVisible
					checkList={searchOption.educationLevel}
					value={state.educationLevel}
					name="educationLevel"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const religion = (
			<div>
				<p>Religion</p>

				<CheckBox
					isVisible
					checkList={searchOption.religion}
					value={state.religion}
					name="religion"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const wardNumber = (
			<div>
				<p>वडा न.</p>
				<CheckBox
					isVisible
					question={"वडा न."}
					checkList={searchOption.wardNumber}
					value={state.wardNumber}
					name="wardNumber"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const agricultureDetail_agricultureProductionSalesType = (
			<div>
				<p>खेतिवाली को किसिम </p>

				<CheckBox
					isVisible
					question={"खेतिवाली को किसिम "}
					checkList={
						searchOption.agricultureDetail_agricultureProductionSalesType
					}
					value={state.agricultureDetail_agricultureProductionSalesType}
					name="agricultureDetail_agricultureProductionSalesType"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const abroadReason = (
			<div>
				<p>विदेशिएको कारण</p>

				<CheckBox
					isVisible
					question={"विदेशिएको कारण"}
					checkList={searchOption.abroadReason}
					value={state.abroadReason}
					name="abroadReason"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const foreignCountry = (
			<div>
				<p>विदेशिएको देश</p>

				<CheckBox
					isVisible
					question={"विदेशिएको कारण"}
					checkList={searchOption.foreignCountry}
					value={state.foreignCountry}
					name="foreignCountry"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const item_title = (
			<div>
				<p>घरको सुबिधा</p>

				<CheckBox
					isVisible
					question={"घरको सुबिधा"}
					checkList={searchOption.item_title}
					value={state.item_title}
					name="item_title"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const detail_name = (
			<div>
				<p>पशुपंक्षी</p>

				<CheckBox
					isVisible
					question={"पशुपंक्षी"}
					checkList={searchOption.detail_name}
					value={state.detail_name}
					name="detail_name"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const home_homeBusinessName = (
			<div>
				<p>घरेलु व्यवसाया</p>

				<CheckBox
					isVisible
					question={"घरेलु व्यवसाय"}
					checkList={searchOption["home_homeBusinessName"]}
					value={state["home_homeBusinessName"]}
					name="home_homeBusinessName"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const herb_herbBusinessName = (
			<div>
				<p>घरेलु जडिबुटी व्यवसाया</p>

				<CheckBox
					isVisible
					question={"घरेलु व्यवसाय"}
					checkList={searchOption["herb_herbBusinessName"]}
					value={state["herb_herbBusinessName"]}
					name="herb_herbBusinessName"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const gender = (
			<div>
				<p>लिङ्ग</p>

				<CheckBox
					isVisible
					question={"लिङ्ग"}
					checkList={searchOption.gender}
					value={state.gender}
					name="gender"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const livingType = (
			<div>
				<p>बसोवाश को अवस्था</p>

				<CheckBox
					isVisible
					checkList={searchOption.livingType}
					value={state.livingType}
					name="livingType"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const permanentLivingAddress = (
			<div>
				<p>इस्थाइ बसोवाशा</p>

				<CheckBox
					isVisible
					checkList={searchOption.permanentLivingAddress}
					value={state.permanentLivingAddress}
					name="permanentLivingAddress"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const waterSource = (
			<div>
				<p>खानेपानीको श्रोत</p>

				<CheckBox
					isVisible
					checkList={searchOption.waterSource}
					value={state.waterSource}
					name="waterSource"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const distanceToWaterSource = (
			<div>
				<p>खानेपानीको उपलब्ध स्थान</p>

				<CheckBox
					isVisible
					checkList={searchOption.distanceToWaterSource}
					value={state.distanceToWaterSource}
					name="distanceToWaterSource"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const cookingSource = (
			<div>
				<p>खानापकाउने प्रमुख इन्धन/चुल</p>

				<CheckBox
					isVisible
					checkList={searchOption.cookingSource}
					value={state.cookingSource}
					name="cookingSource"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const electricSource = (
			<div>
				<p>खानापकाउने प्रमुख इन्धन/चुल</p>

				<CheckBox
					isVisible
					checkList={searchOption.electricSource}
					value={state.electricSource}
					name="electricSource"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const toilet = (
			<div>
				<p>शौचालयको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption.toilet}
					value={state.toilet}
					name="toilet"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const road = (
			<div>
				<p>सडक/बाटोको अवास्था</p>

				<CheckBox
					isVisible
					checkList={searchOption.road}
					value={state.road}
					name="road"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const wasteDisposal = (
			<div>
				<p>फोहरमहिलाको व्यवस्थापन</p>

				<CheckBox
					isVisible
					checkList={searchOption.wasteDisposal}
					value={state.wasteDisposal}
					name="wasteDisposal"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const saltUsed = (
			<div>
				<p>नून प्रयोग</p>

				<CheckBox
					isVisible
					checkList={searchOption.saltUsed}
					value={state.saltUsed}
					name="saltUsed"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const bankAccount = (
			<div>
				<p>बैंक तथा वित्तीय सस्थामा खाता</p>

				<RadioButton
					isVisible
					radioLists={searchOption.bankAccount}
					value={state.bankAccount}
					name="bankAccount"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const illTreament = (
			<div>
				<p>बिरामी जाचा</p>

				<CheckBox
					isVisible
					checkList={searchOption.illTreament}
					value={state.illTreament}
					name="illTreament"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const welfare_title = (
			<div>
				<p>भात्ता को किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["welfare_title"]}
					value={state["welfare_title"]}
					name="welfare_title"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const agricultureProductionSalesType_crops = (
			<div>
				<p>दालको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_crops"]}
					value={state["agricultureProductionSalesType_crops"]}
					name="agricultureProductionSalesType_crops"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const agricultureProductionSalesType_daal = (
			<div>
				<p>अन्नवाली को किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_daal"]}
					value={state["agricultureProductionSalesType_daal"]}
					name="agricultureProductionSalesType_daal"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const agricultureProductionSalesType_oilseed = (
			<div>
				<p>तेलहनको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_oilseed"]}
					value={state["agricultureProductionSalesType_oilseed"]}
					name="agricultureProductionSalesType_oilseed"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);

		const agricultureProductionSalesType_vegetable = (
			<div>
				<p>तरकारीको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_vegetable"]}
					value={state["agricultureProductionSalesType_vegetable"]}
					name="agricultureProductionSalesType_vegetable"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const agricultureProductionSalesType_fruit = (
			<div>
				<p>फलफुलको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_fruit"]}
					value={state["agricultureProductionSalesType_fruit"]}
					name="agricultureProductionSalesType_fruit"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const agricultureProductionSalesType_cash_crops = (
			<div>
				<p>नगदेवालीको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption["agricultureProductionSalesType_cash_crops"]}
					value={state["agricultureProductionSalesType_cash_crops"]}
					name="agricultureProductionSalesType_cash_crops"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const landUsedLiving = (
			<div>
				<p>जग्गाको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption.landUsedLiving}
					value={state.landUsedLiving}
					name="landUsedLiving"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const houseType = (
			<div>
				<p>घरको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption.houseType}
					value={state.houseType}
					name="houseType"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const roofType = (
			<div>
				<p>घरको छानाको किसिम</p>

				<CheckBox
					isVisible
					checkList={searchOption.roofType}
					value={state.roofType}
					name="roofType"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const hasLandDocument = (
			<div>
				<p>जग्गाको कागजपत्</p>

				<RadioButton
					isVisible
					radioLists={searchOption.hasLandDocument}
					value={state.hasLandDocument}
					name="hasLandDocument"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const doHouseCriteriaFullfill = (
			<div>
				<p>घर मापदण्ड</p>

				<RadioButton
					isVisible
					radioLists={searchOption.doHouseCriteriaFullfill}
					value={state.doHouseCriteriaFullfill}
					name="doHouseCriteriaFullfill"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const hasHouseMapPass = (
			<div>
				<p>घरको नक्सा पास </p>

				<RadioButton
					isVisible
					radioLists={searchOption.hasHouseMapPass}
					value={state.hasHouseMapPass}
					name="hasHouseMapPass"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const detail = (
			<div>
				<p>शिप र तालिम्ड</p>

				<CheckBox
					isVisible
					checkList={searchOption.detail}
					value={state.detail}
					name="detail"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const birthCertificate = (
			<div>
				<p>जन्म दर्ता्</p>

				<RadioButton
					isVisible
					radioLists={searchOption.birthCertificate}
					value={state.birthCertificate}
					name="birthCertificate"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const childVaccine = (
			<div>
				<p>बालबालिकालाई खोप</p>

				<RadioButton
					isVisible
					radioLists={searchOption.childVaccine}
					value={state.childVaccine}
					name="childVaccine"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const deliveryFromDoctor = (
			<div>
				<p>स्वस्थाकर्मी बाट सुत्केरी</p>

				<RadioButton
					isVisible
					radioLists={searchOption.deliveryFromDoctor}
					value={state.deliveryFromDoctor}
					name="deliveryFromDoctor"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const pregnantNutrition = (
			<div>
				<p>गर्भवतीलाई पोषणयुक्त खाना</p>

				<RadioButton
					isVisible
					radioLists={searchOption.pregnantNutrition}
					value={state.pregnantNutrition}
					name="pregnantNutrition"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const pregnanctCheck = (
			<div>
				<p>गर्भवती महिलाको जाँच</p>

				<RadioButton
					isVisible
					radioLists={searchOption.pregnanctCheck}
					value={state.pregnanctCheck}
					name="pregnanctCheck"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const childNutrition = (
			<div>
				<p>पोषण सम्बन्धी समस्या</p>

				<RadioButton
					isVisible
					radioLists={searchOption.childNutrition}
					value={state.childNutrition}
					name="childNutrition"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const childLabour = (
			<div>
				<p>वालवालिका श्रम</p>

				<RadioButton
					isVisible
					radioLists={searchOption.childLabour}
					value={state.childLabour}
					name="childLabour"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const earlyMarriage = (
			<div>
				<p>वाल बिवाह</p>

				<RadioButton
					isVisible
					radioLists={searchOption.earlyMarriage}
					value={state.earlyMarriage}
					name="earlyMarriage"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const pregnantTetanus = (
			<div>
				<p>टिटानस खोप</p>

				<RadioButton
					isVisible
					radioLists={searchOption.pregnantTetanus}
					value={state.pregnantTetanus}
					name="pregnantTetanus"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const prePrimaryDetails = (
			<div>
				<p>पूर्व प्राथमिक</p>
				<CheckBox
					isVisible
					checkList={searchOption.prePrimaryDetails}
					value={state.prePrimaryDetails}
					name="prePrimaryDetails"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const primaryDetails = (
			<div>
				<p>आधारभूत तह</p>
				<CheckBox
					isVisible
					checkList={searchOption.primaryDetails}
					value={state.primaryDetails}
					name="primaryDetails"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const secondaryDetails = (
			<div>
				<p>माध्यमिक तह</p>
				<CheckBox
					isVisible
					checkList={searchOption.secondaryDetails}
					value={state.secondaryDetails}
					name="secondaryDetails"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const levelOneJob = (
			<div>
				<p>पेशा</p>
				<CheckBox
					isVisible
					checkList={searchOption.levelOneJob}
					value={state.levelOneJob}
					name="levelOneJob"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const levelTwoJob = (
			<div>
				<p>नोकरी जागिर</p>
				<CheckBox
					isVisible
					checkList={searchOption.levelTwoJob}
					value={state.levelTwoJob}
					name="levelTwoJob"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const levelThreeJob = (
			<div>
				<p>शिक्षक/सुरक्षा निकाय</p>
				<CheckBox
					isVisible
					checkList={searchOption.levelThreeJob}
					value={state.levelThreeJob}
					name="levelThreeJob"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const healthCondition = (
			<div>
				<p>स्वास्थ्य</p>
				<CheckBox
					isVisible
					checkList={searchOption.healthCondition}
					value={state.healthCondition}
					name="healthCondition"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const unhealthyDisease = (
			<div>
				<p>दिर्घ रोग</p>
				<CheckBox
					isVisible
					checkList={searchOption.unhealthyDisease}
					value={state.unhealthyDisease}
					name="unhealthyDisease"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const type = (
			<div>
				<p>अपांगताको किसिम</p>
				<CheckBox
					isVisible
					checkList={searchOption.type}
					value={state.type}
					name="type"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const condition = (
			<div>
				<p>अपांगताको स्थिति</p>
				<CheckBox
					isVisible
					checkList={searchOption.condition}
					value={state.condition}
					name="condition"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const hasCard = (
			<div>
				<p>अपांगताको कार्ड</p>
				<RadioButton
					isVisible
					radioLists={searchOption.hasCard}
					value={state.hasCard}
					name="hasCard"
					handleChange={this.handleCompChange}
				/>
			</div>
		);
		const cardType = (
			<div>
				<p>कार्डको किसिम</p>
				<CheckBox
					isVisible
					checkList={searchOption.cardType}
					value={state.cardType}
					name="cardType"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const motherTongue = (
			<div>
				<p>मातृभाषा</p>
				<CheckBox
					isVisible
					checkList={searchOption.motherTongue}
					value={state.motherTongue}
					name="motherTongue"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const bloodGroup = (
			<div>
				<p>रगत समुह</p>
				<CheckBox
					isVisible
					checkList={searchOption.bloodGroup}
					value={state.bloodGroup}
					name="bloodGroup"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const martialStatus = (
			<div>
				<p>बैवाहिक स्थिति</p>
				<CheckBox
					isVisible
					checkList={searchOption.martialStatus}
					value={state.martialStatus}
					name="martialStatus"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const caste = (
			<div>
				<p>कुन जनजाती</p>
				<CheckBox
					isVisible
					checkList={searchOption.caste}
					value={state.caste}
					name="caste"
					handleCompChange={this.handleCompChange}
				/>
			</div>
		);
		const component = {
			age,
			gender,
			religion,
			ethnics,
			answererRelation,
			educationLevel,
			livingType,
			permanentLivingAddress,
			waterSource,
			distanceToWaterSource,
			cookingSource,
			electricSource,
			toilet,
			road,
			wasteDisposal,
			saltUsed,
			bankAccount,
			illTreament,
			welfare_title,
			landUsedLiving,
			houseType,
			roofType,
			hasLandDocument,
			doHouseCriteriaFullfill,
			hasHouseMapPass,
			detail,
			birthCertificate,
			childVaccine,
			deliveryFromDoctor,
			pregnantNutrition,
			pregnanctCheck,
			childNutrition,
			childLabour,
			earlyMarriage,
			pregnantTetanus,
			prePrimaryDetails,
			primaryDetails,
			secondaryDetails,
			levelOneJob,
			levelTwoJob,
			levelThreeJob,
			healthCondition,
			unhealthyDisease,
			type,
			condition,
			hasCard,
			cardType,
			motherTongue,
			bloodGroup,
			martialStatus,
			caste,
			wardNumber,
			agricultureDetail_agricultureProductionSalesType,
			item_title,
			home_homeBusinessName,
			herb_herbBusinessName,
			totalIncome,
			totalExpenditure,
			agricultureProductionSalesType_crops,
			agricultureProductionSalesType_daal,
			agricultureProductionSalesType_oilseed,
			agricultureProductionSalesType_vegetable,
			agricultureProductionSalesType_fruit,
			agricultureProductionSalesType_cash_crops,
			detail_name,
			abroadReason,
			foreignCountry
		};
		return this.props.selectedOption ? (
			<Paper className={eachOption}>
				{component[this.props.selectedOption]}
			</Paper>
		) : null;
	};

	render() {
		const { classes, loading, state, selection } = this.props;
		return (
			<Grid item container direction="row" spacing={2}>
				<Grid item xs={11}>
					<AutoSuggest
						clearOnCloseTag={this.clearOnCloseTag}
						selection={selection}
						state={state}
						handleSelectChange={this.props.handleSelectChange}
						onOptionSelected={this.props.onOptionSelected}
					/>
				</Grid>
				<Grid item xs={1}>
					<Button
						type="primary"
						icon="search"
						onClick={this.props.handleSearch}
					>
						Search
					</Button>
				</Grid>

				<Grid item container direction="row">
					{this.getFilteredUI()}
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	container: {},
	eachOption: {
		padding: theme.spacing.unit * 2,
		margin: theme.spacing.unit * 1
	},
	flexColumn: {
		flexDirection: "column"
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
