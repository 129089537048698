import {
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getHouseDetail } from "../../../../../actions/searchAction";
import isEmpty from "../../../../../utils/validation/is-empty";
import { gharNumber, selectedLanguage } from "../../../../../variable/global";
import MySnackbarContentWrapper from "../../../../common/MySnackbarContentWrapper";
import GetHouseDetail from "./GetHouseDetail";

class SearchByHouseNumber extends Component {
	// for thirdGender houseNumber: 2631
	// 1221,
	// 2156 for insurance
	// 2605 for disability
	state = {
		houseNumber: "",
		snack: false,
		snackVariant: "",
		snackMessage: "",
		finalHouseNumber: ""
	};

	fetchData = () => {
		this.props.getHouseDetail(this.state.houseNumber);
	};

	handleHouseNumberChange = event => {
		this.setState({ houseNumber: event.target.value });
	};

	onSubmit = () => {
		// event.preventDefault();

		const houseNumber = this.state.houseNumber;

		if (isEmpty(houseNumber))
			return this.setState({
				snack: true,
				snackVariant: "error",
				snackMessage: "कृपया घरको नम्बर भर्नुहोस्"
			});
		// else if (!isNumber(houseNumber))
		// 	return this.setState({
		// 		snack: true,
		// 		snackVariant: "error",
		// 		snackMessage: "कृपया नम्बरमा लेख्नुहोला"
		// 	});
		else {
			this.setState(
				{
					finalHouseNumber: houseNumber
				},
				() => this.fetchData()
			);
		}
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ snack: false });
	};

	setSnack = (msg, variant) => {
		this.setState({
			snack: true,
			snackVariant: variant,
			snackMessage: msg
		});
	};

	keyPress = e => {
		if (e.keyCode == 13) {
			this.onSubmit();
			// put the login here
		}
	};

	render() {
		const { classes, house, loading } = this.props;
		const { houseNumber } = this.state;

		return (
			<Grid container className={classes.container}>
				<Paper className={classes.root} elevation={2}>
					<HomeIcon className={classes.homeIcon} />
					<InputBase
						onKeyDown={this.keyPress}
						name="houseNumber"
						value={this.state.houseNumber}
						onChange={this.handleHouseNumberChange}
						className={classes.input}
						placeholder={gharNumber[selectedLanguage]}
					/>

					<Divider className={classes.divider} />
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right"
						}}
						open={this.state.snack}
						autoHideDuration={4000}
						onClose={this.handleClose}
					>
						<MySnackbarContentWrapper
							onClose={this.handleClose}
							variant={this.state.snackVariant}
							message={this.state.snackMessage}
						/>
					</Snackbar>
					<IconButton
						onClick={this.onSubmit}
						color="primary"
						className={classes.iconButton}
						aria-label="Search"
					>
						<SearchIcon />
					</IconButton>
				</Paper>

				{loading ? (
					<Grid
						container
						stretch
						direction="row"
						justify="center"
						style={{ height: "50vh" }}
						alignItems="center"
					>
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={loading}
							/>
						</Grid>
					</Grid>
				) : (
					house && (
						<GetHouseDetail
							houseNumber={this.state.finalHouseNumber}
							house={house}
						/>
					)
				)}
			</Grid>
		);
	}
}

const styles = theme => ({
	container: {},
	root: {
		marginTop: theme.spacing.unit * 5,
		// padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto"
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4
	},
	homeIcon: {
		marginLeft: 5,
		marginRight: 5,
		color: "#673ab7"
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired,
	house: PropTypes.object.isRequired
};
const mapStateToProps = ({ search: { house, loading } }) => ({
	house,
	loading
});

export default withStyles(styles)(
	connect(
		mapStateToProps,
		{ getHouseDetail }
	)(SearchByHouseNumber)
);
