import { Grid, withStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandIcon from "@material-ui/icons/ExpandMore";

import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import BoxBorderArray from "./frequent/BoxBorderArray";

class ExapnsionLink extends Component {
	handleChange = (event, expanded) => {
		this.props.handleChange(this.props.unique);
	};
	render() {
		const {
			classes,

			expanded,
			handleChange,
			unique,
			error,
			componentChildren
		} = this.props;
		console.log("expanded", componentChildren);

		return (
			<ExpansionPanel
				style={{ padding: 0 }}
				expanded={expanded}
				onChange={handleChange(unique)}
			>
				<ExpansionPanelSummary
					className={classes.summary}
					expandIcon={<ExpandIcon className={classes.white} />}
				>
					<Grid
						container
						className={error ? classes.headerError : classes.header}
					/>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.details}>
					{componentChildren}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

const styles = theme => ({
	header: {
		height: 20,
		backgroundColor: "#00A97c",
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5
	},
	headerError: {
		height: 20,
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5,
		backgroundColor: "#FB3E44"
	},
	container: {
		backgroundColor: "transparent",
		// padding: 0,
		// margin: 0,
		border: "none"
	},
	summary: {
		paddingLeft: 0,
		paddingRight: 0
	},
	category: {
		// minHeight: 30,
		color: "#FFF"
	},
	details: {
		padding: 0,
		margin: 0,
		flexWrap: "nowrap"
		// border: "none",
		// marginTop: theme.spacing.unit * 1
	},
	noteContainer: {
		// margin: theme.spacing.unit
		flexWrap: "nowrap"
	},
	white: {
		color: "#FFF"
	},
	eachLink: {
		marginTop: 1
	}
});

ExapnsionLink.propTypes = {
	classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ExapnsionLink);
