import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getAnalaysisFamilyChartData } from "../../../../actions/analysisAction";
import isEmpty from "../../../../utils/validation/is-empty";
import {
	familyDetails,
	gaupalikaWard,
	selectedLanguage,
	wadaBibaranName,
	ward
} from "../../../../variable/global";
import WardInfo from "../../home/WardInfo";
import ChartList from "./charts/ChartList";
import Can from "../../../casl/Can";
import Infinite from "../gharBibaran/infinite";
import Axios from "axios";

class Index extends Component {
	constructor(props) {
		super(props);

		const role = parseInt(sessionStorage.getItem("role"));
		const ward = parseInt(sessionStorage.getItem("ward"));
		var wardData = ward === 0 ? {} : { wardNumber: ward };
		this.state = {
			fetched: false,
			wardData,
			role,
			ward,
			charts: [],
			items: Array.from({ length: 20 }),
			per: 4,
			page: 1,
			hasMore: true,
			total: 11
		};
	}

	componentDidMount() {
		this.getAnalysisFamilyChartData(1);
	}

	getTitle = (ward, object) => {
		if (isEmpty(ward)) return object[0][selectedLanguage];
		else return object[ward.wardNumber][selectedLanguage];
	};

	onWardClick = wardData => {
		this.setState(
			{
				wardData,
				fetched: false,
				total: 11,
				charts: [],
				hasMore: true,
				page: 0
			},
			() => {
				this.getAnalysisFamilyChartData(1);
			}
		);
	};

	getAnalysisFamilyChartData = page => {
		const ward = this.state.wardData.wardNumber
			? this.state.wardData.wardNumber
			: 0;
		Axios.get(`/api/analysis/family/${page}/${this.state.per}/${ward}`)
			.then(res => {
				this.setState(prevState => {
					return {
						fetched: true,
						total: res.data.total,
						charts:
							page === 1
								? res.data.data
								: prevState.charts.concat(res.data.data),
						hasMore: res.data.has_more,
						page: res.data.has_more ? prevState.page + 1 : prevState.page
					};
				});
			})
			.catch(err => {
				console.error(err);
			});
	};

	render() {
		const gaupalika = gaupalikaWard[selectedLanguage].split(" ");
		const { loading } = this.props;
		const { charts, wardData } = this.state;
		return (
			<div>
				<Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
					<Typography variant="h4" gutterBottom>
						{wadaBibaranName[selectedLanguage]}
					</Typography>

					<div style={{ marginBottom: 25, marginTop: 20 }}>
						<Grid container spacing={1}>
							{ward.map((each, key) => (
								<Grid item>
									<WardInfo
										isActive={key + 1 == this.state.wardData.wardNumber}
										backgroundColor={each.backgroundColor}
										onWardClick={this.onWardClick}
										unique={key + 1}
										key={key}
										wardNumber={each[selectedLanguage]}
										wardName={each.ward}
									/>
								</Grid>
							))}
							<Grid item>
								<WardInfo
									isActive={undefined == this.state.wardData.wardNumber}
									backgroundColor={"#00ACC1"}
									unique={0}
									wardNumber={gaupalika[0]}
									wardName={gaupalika[1]}
									onWardClick={this.onWardClick}
								/>
							</Grid>
						</Grid>
					</div>
				</Can>
				<Typography variant="h4" gutterBottom>
					{this.getTitle(wardData, familyDetails)}
				</Typography>
				<Grid container spacing={2}>
					{!this.state.fetched ? (
						<Grid
							container
							direction="row"
							justify="center"
							alignItems="center"
						>
							<Grid item>
								<ClipLoader
									sizeUnit={"px"}
									size={84}
									color={"#00ACC1"}
									loading={loading}
								/>
							</Grid>
						</Grid>
					) : (
						<Infinite
							loading={
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={loading}
										/>
									</Grid>
								</Grid>
							}
							total={this.state.total}
							loadMore={this.getAnalysisFamilyChartData}
							hasMore={this.state.hasMore}
						>
							<Grid container spacing={2}>
								{charts ? (
									charts.map((chart, key) => (
										<Grid item className="fixWidthofColumn" key={key}>
											<ChartList
												chartData={{
													title: chart.title,
													route: chart.route,
													label: chart.data.map(each => each.label),
													data: chart.data.map(each => each.value)
												}}
												key={key}
												match={this.props.match}
											/>
										</Grid>
									))
								) : (
									<p>Loading...</p>
								)}
							</Grid>
						</Infinite>
					)}
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = ({ analysis: { loading, chart } }) => ({
	loading,
	chart
});

export default connect(
	mapStateToProps,
	{ getAnalaysisFamilyChartData }
)(Index);
