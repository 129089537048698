import React, { Component } from "react";
import Error1 from "./ListHouse";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { Grid } from "@material-ui/core/es";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import CardIcon from "../../common/Card/CardIcon";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

class Index extends Component {
	state = { house1: [] };
	componentDidMount() {
		Axios.get("/api/house/error1")
			.then(res => {
				this.setState({
					houses1: res.data,
					loading1: false
				});
			})
			.catch(err => {});
	}

	render() {
		const { houses1, loading1 } = this.state;
		const { classes } = this.props;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								घर सर्वेक्षण
							</Typography>
						</CardHeader>
						<CardBody>
							{loading1 ? (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={loading1}
										/>
									</Grid>
								</Grid>
							) : (
								houses1 && <Error1 houses={houses1} />
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		// float: "right"
	}
});

export default withStyles(styles)(Index);
