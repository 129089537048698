import { Grid } from "@material-ui/core/es";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { searchOptionTab, selectedLanguage } from "../../../../variable/global";
import Advance from "./custom/";
import SearchByHouseNumber from "./houseNumber";
import SearchByName from "./name";
import SearchByPhone from "./phoneNumber";

function TabContainer(props) {
	return <Grid container>{props.children}</Grid>;
}
export class Index extends Component {
	state = {
		value: 3
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { classes } = this.props;
		const { value } = this.state;

		return (
			<Grid container direction="column">
				<Grid item container>
					<Tabs
						value={value}
						onChange={this.handleChange}
						classes={{
							root: classes.tabsRoot,
							indicator: classes.tabsIndicator
						}}
					>
						{searchOptionTab.map((each, key) => (
							<Tab
								key={key}
								disableRipple
								classes={{
									root: classes.tabRoot,
									selected: classes.tabSelected
								}}
								label={
									<p
										style={
											key === value
												? { color: "#ff9100", fontSize: "1rem" }
												: { fontSize: "1rem" }
										}
									>
										{each[selectedLanguage]}
									</p>
								}
							/>
						))}
					</Tabs>
				</Grid>
				{/* <Paper className={classes.root}> */}
				<Grid container>
					{value === 0 && (
						<TabContainer>
							<SearchByHouseNumber />
						</TabContainer>
					)}
					{value === 1 && (
						<TabContainer>
							<SearchByName />
						</TabContainer>
					)}
					{value === 2 && (
						<TabContainer>
							<SearchByPhone />
						</TabContainer>
					)}
					{value === 3 && (
						<TabContainer>
							<Advance />
						</TabContainer>
					)}
					{value === 4 && <TabContainer>Item Five</TabContainer>}
					{value === 5 && <TabContainer>Item Six</TabContainer>}
					{value === 6 && <TabContainer>Item Seven</TabContainer>}
				</Grid>
				{/* </Paper> */}
			</Grid>
		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Index);

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: "#FFF"
	},
	tabsRoot: {
		borderBottom: "1px solid #e8e8e8"
	},
	tabsIndicator: {
		backgroundColor: "#1890ff"
	},
	tabRoot: {
		textTransform: "initial",
		minWidth: 72,
		fontSize: "1rem !important",
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing.unit * 4,
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(","),
		"&:hover": {
			color: "#40a9ff",
			opacity: 1
		},
		"&$tabSelected": {
			backgroundColor: "#FFF",
			color: "#1890ff",
			fontWeight: theme.typography.fontWeightMedium
		},
		"&:focus": {
			color: "#40a9ff"
		}
	},
	tabSelected: {
		color: "#ff9100",
		opacity: 1
	},
	typography: {
		padding: theme.spacing.unit * 3
	},
	selectedTabText: { color: "#ff9100", fontSize: "1rem" }
});
