import React, { Component } from "react";
import L from "leaflet";
import {
	Map,
	TileLayer,
	Marker,
	Popup,
	LayersControl,
	GeoJSON
} from "react-leaflet";
import GeoJsonLayer from "./GeoJSONWithLayer";
import iconUrl from "../../../image/pin24.png";
import { getHouseByHouseNumber } from "../../../actions/searchAction";
import { connect } from "react-redux";

const { BaseLayer, Overlay } = LayersControl;

var myIcon = L.icon({
	iconUrl,
	// iconSize: [25, 41],
	iconAnchor: [22, 94],
	popupAnchor: [-10, -90]
});

class Maps extends Component {
	state = {
		location: {
			lat: 27.505,
			lng: 85.09
		},
		haveUserLocation: false,
		zoom: 12,
		baseLayers: {
			"No Basemap": "Empty",
			Streets: "streets",
			Satellite: "satellite",
			Grayscale: "grayscale"
		}
	};

	MouseOverFeature = (feature, layer, name) => {
		layer.bindPopup(name);
	};

	MouseOutFeature = (feature, layer) => {
		layer.unbindPopup();
	};

	onEachFeature = name => (feature, layer) => {
		return layer.on({
			mouseover: e => this.MouseOverFeature(feature, layer, name),
			mouseout: e => this.MouseOutFeature(feature, layer)
		});
		return layer.bindPopup(name);
		if (feature.properties && feature.properties.name) {
			layer.bindPopup(feature.properties.name);
		} else layer.bindPopup(name);
	};

	onEachFeatures = (feature, map) => {
		return map.on({
			mouseover: e =>
				window.setTimeout(
					map.scrollWheelZoom.enable.bind(map.scrollWheelZoom),
					2000
				),
			mouseout: e => map.scrollWheelZoom.disable.bind(map.scrollWheelZoom)
		});
	};

	onHouseNumberClick = houseNumber => {
		this.setState({ houseOpen: true, houseNumber }, () => {
			this.props.getHouseByHouseNumber(houseNumber);
		});
	};

	render() {
		// const position = [this.state.location.lat, this.state.location.lng];
		const {
			geoJsonDataWard,
			geoJsonDataInfastructure,
			position,
			showGeoJSON
		} = this.props;
		return (
			<div className="map" style={{ width: "100%" }}>
				<Map
					id="map"
					className="map"
					center={position}
					zoom={this.state.zoom}
					// zoomControl={false}
					// onEachFeature={this.onEachFeatures}
					scrollWheelZoom={false}
				>
					<LayersControl position="bottomright">
						<BaseLayer checked name="OpenStreetMap.Mapnik">
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
						</BaseLayer>
						<BaseLayer name="OpenStreetMap.BlackAndWhite">
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
							/>
						</BaseLayer>
					</LayersControl>

					{showGeoJSON.length > 0 &&
						showGeoJSON.map(each => {
							return (
								each.length > 0 &&
								each.map(eachGeoJSON => {
									console.log("eachGeoJSON", eachGeoJSON);
									// if(eachGeoJSON.type == 'house'){
									// 	const maps = L.map("map", {
									// 		center: [27.0937722, 86.6203392],
									// 		zoom: 6
									// 	  });
									// 	const houses = eachGeoJSON.geoJson.features;
									// 	houses.map(each =>{
									// 		L.geoJSON(each, {
									// 			pointToLayer: (feature, latlng) => {
									// 			  return L.marker(latlng, { icon: iconUrl });
									// 			}
									// 		  }).addTo(maps);
									// 	})
									// }
									if (eachGeoJSON)
										return (
											<GeoJsonLayer
												type={eachGeoJSON.type}
												key={eachGeoJSON.name}
												style={eachGeoJSON.style}
												data={eachGeoJSON.geoJson}
												popupContent={eachGeoJSON.name}
												houseClick={this.onHouseNumberClick}
											/>
										);
								})
							);
						})}
					{geoJsonDataInfastructure.length > 0 &&
						geoJsonDataInfastructure.map(each => {
							return (
								<GeoJsonLayer
									type={each.type}
									key={each.name}
									style={each.style}
									data={each.geoJson}
									popupContent={each.name}
								/>
							);
						})}
					{/* <Overlay name="Marker with popup">
					<Marker position={position} icon={myIcon}>
						<Popup>
							A pretty CSS3 popup. <br /> Easily customizable.
						</Popup>
					</Marker>
					</Overlay> */}
				</Map>
			</div>
		);
	}
}

const mapStateToProps = ({ search: { house, load } }) => ({
	house,
	load
});
export default connect(
	mapStateToProps,
	{ getHouseByHouseNumber }
)(Maps);
// export default Maps;
