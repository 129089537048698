import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { selectedLanguage, yes, no } from "../../../../../variable/global";
import {
	QuestionAnswer,
	Title
} from "../../../../common/frequent/QuestionAnswer";
import getNepalNumber from "../../../../../utils/getNepaliNumber";

class SearchByHouseNumber extends Component {
	getGender = gender => {
		if (gender === "death_female") return "महिला";
		else if (gender === "dearh_male") return "पुरुष";
		else return "अन्य";
	};
	getYesNo = data => (data ? yes[selectedLanguage] : no[selectedLanguage]);
	render() {
		const deathTitle = { nepali: "मृत्यु सम्बन्धी", english: "" };
		const deathName = { nepali: "नाम थर", english: "" };
		const deathgender = { nepali: "लिङ", english: "" };
		const deathAge = { nepali: "उमेर", english: "" };
		const deathReason = { nepali: "मृत्यु कारण", english: "" };
		const deathYes = {
			nepali: "गत १ बर्ष भित्र मृत्यु भएको",
			english: ""
		};

		const { classes, death } = this.props;
		return (
			<Grid item container direction="column" className={classes.container}>
				<Title title={deathTitle} />
				<Grid item container>
					{death.anyDeath ? (
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>{deathName[selectedLanguage]}</TableCell>
									<TableCell align="left">
										{deathgender[selectedLanguage]}
									</TableCell>
									<TableCell align="left">
										{deathAge[selectedLanguage]}
									</TableCell>
									<TableCell align="left">
										{deathReason[selectedLanguage]}
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{death.detail.map((row, key) => (
									<TableRow key={key}>
										<TableCell align="left">{row.deathName}</TableCell>
										<TableCell align="left">
											{this.getGender(row.deathGender)}
										</TableCell>
										<TableCell align="left">
											{getNepalNumber(row.deathAge)}
										</TableCell>
										<TableCell align="left">
											{row.deathReason.join(", ")}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						<QuestionAnswer
							question={deathYes[selectedLanguage]}
							answer={this.getYesNo(false)}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 700
	},
	container: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 2
	},
	eachQnAns: {
		marginTop: theme.spacing.unit * 1
	}
});

SearchByHouseNumber.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchByHouseNumber);
