import {
  Grid,
  Typography
  // RadioGroup,
  // Radio,
  // FormControlLabel,
  // Select,
  // OutlinedInput,
  // MenuItem
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Form, Input, message, Button, notification } from "antd";
// import Icon from "@material-ui/core/Icon";
// import classNames from "classnames";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import "react-images-uploader/font.css";
// import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardFooter from "../../../common/Card/CardFooter.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import CSVUploader from "../../../common/csv";
import SearchOption from "./SearchOption.jsx";
class GroupProfile extends Component {
  state = {
    data: false,
    errors: []
  };

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  handleChange = event => {
    this.props.handleChange(event.target.name, event.target.value);
  };

  isCustomNumberWrong = () => {
    let errors = [];
    this.props.customPhone &&
      this.props.customPhone.split(",").findIndex(each => {
        each = each.trim();
        if (!(each.startsWith("98") || each.startsWith("97")))
          errors.push("Number must starts with '98' or '97'");
        if (each.length !== 10) errors.push("Number must have length of 10");
        this.setState({ errors }, () => {
          if (errors.length > 0) {
            errors.forEach(error =>
              this.openNotificationWithIcon(
                "error",
                "Custom Phone Number ",
                error
              )
            );
          }
        });
        return errors.length > 0;
      });
    return errors.length > 0;
  };

  hasAtleastOneNumber = () =>
    this.props.customPhone.split(",").filter(each => each.trim()).length +
      this.props.selectedMember.length >
      0 || this.state.data;

  handleSubmit = e => {
    e.preventDefault();
    if (this.isCustomNumberWrong()) return;

    this.props.form.validateFields((err, values) => {
      console.log(Boolean(this.state.data));
      if (this.hasAtleastOneNumber() || this.state.data);
      else
        return this.openNotificationWithIcon(
          "error",
          "Empty Member",
          "Please select or input atleast one member"
        );
      if (!err) {
        this.props.onSubmit(
          values,
          this.state.data,
          this.openNotificationWithIcon
        );
      }
    });
  };

  onDataFetched = data => {
    console.log(data);
    this.setState({
      data: data
    });
  };

  // getJSONData = () => {
  // 	return [["a", "v"], ["a", "v"]];
  // };

  getJSONData = () => {
    let tile = [];
    let header = ["name", "phone", "address", "email", "gender", "designation"];
    tile.push(header);
    this.props.selectedMember.forEach(
      ({
        value: { address, gender, designation, email, name, primaryPhone }
      }) => {
        let row = [
          name || "",
          primaryPhone,
          address || "",
          email || "",
          gender || "",
          designation.name || ""
        ];
        tile.push(row);
      }
    );
    this.props.customPhone.split(",").map(each => {
      let row = ["", each.trim(), "", "", "", ""];
      tile.push(row);
    });

    return tile;
  };

  getSample = i =>
    [
      [
        ["name", "phone", "address", "email", "gender", "designation"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"]
      ],
      [
        ["name", "phone", "address", "email", "gender", "designation"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["Sanjay Adhikari", "9804959939", "sanjay@example.com", "male", "ITO"],
        ["", "9804959939", "", "", ""],
        ["", "9804959939", "", "", ""]
      ]
    ][i];

  success = msg => {
    message.success(msg);
  };

  error = msg => {
    message.error(msg);
  };

  getJSONData = () => {
    return [
      ["a", "v"],
      ["a", "v"]
    ];
  };
  success = msg => {
    message.success(msg);
  };

  error = msg => {
    message.error(msg);
  };

  render() {
    const {
      classes,
      name,
      photoUrl,
      // person,
      isAdd,
      handleChange,
      onSubmit,

      submitText
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Card style={{ height: "100%" }}>
        <CardHeader color="primary" profile>
          <Typography variant="h5" className={classes.cardTitleWhite}>
            {this.props.title}
          </Typography>
          <Typography variant="h6" className={classes.cardCategoryWhite}>
            Add Group
          </Typography>
          {!isAdd && (
            <div className={classes.right}>
              {
                <CSVLink
                  data={this.getJSONData()}
                  filename={`group_${name}_${moment().format(
                    "MMMM_Do_YYYY,__h:mm:ss_a"
                  )}_export.csv`}
                >
                  <Button
                    filename={"my-file.csv"}
                    type="primary"
                    icon="download"
                    size={"default"}
                    style={{ marginRight: 10 }}
                  >
                    download CSV
                  </Button>
                </CSVLink>
              }
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Grid container spacing={24}>
            <Grid item md={6}>
              <Form layout="vertical">
                <Form.Item label="Group's Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Group's name"
                      }
                    ],
                    initialValue: name
                  })(<Input placeholder="Full name" />)}
                </Form.Item>
              </Form>
            </Grid>
          </Grid>
          <Grid item style={{ float: "left", display: "flex" }}>
            <CSVUploader
              showFileName
              onDataFetched={this.onDataFetched}
              onError={this.error}
            />
            <CSVLink data={this.getSample(0)} filename={`sample1_group.csv`}>
              <Button
                type="link"
                icon="download"
                size={"default"}
                style={{ marginLeft: 10 }}
              >
                Sample 1
              </Button>
            </CSVLink>
            <CSVLink data={this.getSample(1)} filename={`sample2_group.csv`}>
              <Button
                type="link"
                icon="download"
                size={"default"}
                style={{ marginLeft: 10 }}
              >
                Sample 2
              </Button>
            </CSVLink>
          </Grid>
          <SearchOption
            submitText={submitText}
            handleSubmit={this.handleSubmit}
            handleChange={this.props.handleChange}
            handleDeleteCustomNumber={this.props.handleDeleteCustomNumber}
            handleDeleteSelectedMember={this.props.handleDeleteSelectedMember}
            selectedMember={this.props.selectedMember}
            customPhone={this.props.customPhone}
            handlePhoneNumberInput={this.props.handlePhoneNumberInput}
          />
          <Button
            color="primary"
            style={{ fontSize: "1rem" }}
            onClick={this.handleSubmit}
          >
            {submitText}
          </Button>
        </CardBody>
        <CardFooter>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              {/*  */}
            </Grid>
          </Grid>
        </CardFooter>
      </Card>
    );
  }
}

const styles = theme => ({
  right: {
    float: "right"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  textSize: {
    display: "flex"
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  avatarGrid: {
    cursor: "pointer"
  }
});
const GroupProfileDemo = Form.create()(GroupProfile);
export default withStyles(styles)(GroupProfileDemo);

// <Grid container>
// 							{[
// 								avatar,
// 								avatar1,
// 								avatar2,
// 								avatar3,
// 								avatar4,
// 								avatar5,
// 								avatar6,
// 								avatar7,
// 								avatar8
// 							].map((each, key) => (
// 								<Grid
// 									key={key}
// 									item
// 									className={classes.avatarGrid}
// 									onClick={() => handleChange("photoUrl", key)}
// 								>
// 									{/* <Icon>add_circle</Icon> */}
// 									{/* <CheckCircle /> */}
// 									<Badge
// 										className={classes.margin}
// 										badgeContent={<CheckCircle />}
// 										invisible={photoUrl !== key}
// 									>
// 										<Avatar
// 											alt="avatar"
// 											src={each}
// 											className={classes.bigAvatar}
// 										/>
// 									</Badge>

// 									{/* <img src={each} alt="av" height="40" width="40" /> */}
// 								</Grid>
// 							))}
// 						</Grid>
