import {
	SHOW_DATA,
	GET_INSTITUTE_ERROR,
	GET_SCHOOL_LEVEL,
	GET_SCHOOL_STUDENT
} from "../actions/types";

const initialState = {
	institute: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SHOW_DATA:
			return {
				...state,
				institute: action.payload && action.payload,
				loading: true
			};
		case GET_INSTITUTE_ERROR:
			return {
				...state,
				errors: action.payload
			};

		default:
			return state;
	}
}
