import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { addcategoryList } from "./config";
import InstitutionForm from "./FormController";

class AddInstitutionSurvey extends Component {
	state = {
		category: -1
	};

	handleSelectChange = (name, value) => {
		console.log("handle ", name, value);
		this.setState({
			[name]: value
		});
	};

	render() {
		const { classes } = this.props;
		const category = this.props.match.params.category;
		const categoryList = addcategoryList;
		return (
			<div>
				{/* <InstitutionSurvey
					handleSelectChange={this.handleSelectChange}
					category={this.state.category}
				/> */}
				{!category ? (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={!!category}
							/>
						</Grid>
					</Grid>
				) : (
					<InstitutionForm
						category={categoryList.findIndex(
							each => each == this.props.match.params.category
						)}
						isUpdate={false}
					/>
				)}
			</div>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

export default withStyles(styles)(AddInstitutionSurvey);
