import React, { Component } from "react";
import { Checkbox, Col } from "antd";
import Typography from "@material-ui/core/Typography";
import {
	Grid,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails
} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import {
	gaupalika,
	gaupalikaWard,
	ward,
	selectedLanguage,
	infastructure,
	disaster
} from "../../../variable/global";

export default class BoundaryOption extends Component {
	state = {
		index: -1,
		activeIndex: 0,
		expanded: "boundary"
	};

	handleExpansion = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};

	handleChange = event => {
		this.props.handleChange(event.target.name, event.target.checked);
	};

	render() {
		const {
			wardSelected,
			infastructureSelected,
			disasterSelected,
			wardCheckAll,
			indeterminateWard,
			boundaryChecked,
			otherCheckbox
		} = this.props;

		return (
			<div>
				<ExpansionPanel
					style={{ padding: 0, backgroundColor: "transparent" }}
					expanded={this.state.expanded === `boundary`}
					onChange={this.handleExpansion(`boundary`)}
				>
					<ExpansionPanelSummary expandIcon={<ExpandIcon />}>
						{" "}
						<Typography
							variant="body2"
							style={{ fontWeight: 500 }}
							gutterBottom
						>
							सिमाना
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid conatiner direction="column">
							<Checkbox
								name="boundary"
								onChange={this.handleChange}
								checked={otherCheckbox.boundary}
							>
								{gaupalika[selectedLanguage]} सिमाना
							</Checkbox>
							<Checkbox
								indeterminate={indeterminateWard}
								onChange={this.props.onCheckAllWardChange}
								checked={wardCheckAll}
							>
								{gaupalika[selectedLanguage]}
							</Checkbox>
							<Checkbox.Group
								style={{ width: "100%" }}
								onChange={this.props.handleWardChange}
								value={wardSelected}
							>
								{ward.map(each => (
									<Col key={each.value}>
										<Checkbox value={each.value}>
											{each[selectedLanguage]}
										</Checkbox>
									</Col>
								))}
							</Checkbox.Group>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}
