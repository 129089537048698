import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { privilege, selectedLanguage } from "../../../../variable/global";
import View from "../../../dashboard/user/View";

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

const styles = theme => ({
	paper: {
		position: "absolute",
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: "none"
	}
});

class Preivew extends React.Component {
	state = {
		open: false,
		email: "",
		username: "",
		firstname: "",
		lastname: "",
		gender: "",
		privilege: 5,
		wardRole: 1,
		users: {}
	};

	handleOpen = () => {
		this.setState({ open: true });
	};

	componentDidUpdate(prevProp) {
		if (this.props.userId !== prevProp.userId)
			Axios.get(`/api/users/user/${this.props.userId}`)
				.then(res => {
					this.setState({
						email: res.data.pri.user.email,
						username: res.data.pri.user.username,
						tempUsername: res.data.pri.user.username,
						firstname: res.data.pri.user.name.first,
						lastname: res.data.pri.user.name.last,
						gender: res.data.pri.user.gender,
						privilege: res.data.pri.role,
						wardRole: res.data.pri.ward,
						open: true
					});
				})
				.catch(err => {});
	}

	getPrivilegeView = () => {
		return privilege.filter(each => each.value === this.state.privilege)[0][
			selectedLanguage
		];
	};

	render() {
		const { classes, open } = this.props;

		return (
			<div>
				{this.state.email && (
					<Modal
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open={open}
						onClose={this.props.handleClose}
					>
						<div style={getModalStyle()} className={classes.paper}>
							{
								<View
									email={this.state.email}
									username={this.state.username}
									firstname={this.state.firstname}
									lastname={this.state.lastname}
									gender={this.state.gender}
									privilege={this.state.privilege}
									getPrivilege={this.getPrivilegeView}
								/>
							}
						</div>
					</Modal>
				)}
			</div>
		);
	}
}

Preivew.propTypes = {
	classes: PropTypes.object.isRequired
};

// We need an intermediary variable for handling the recursive nesting.
const PreivewWrapped = withStyles(styles)(Preivew);

export default PreivewWrapped;
