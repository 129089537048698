import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	businessListQuestion,
	businessLists,
	businessRadio,
	businesssQn,
	herbListQuestion,
	herbLists,
	herbQn,
	herbRadio
} from "../../../../variable/houseSurvey";
import MultipleSelect from "../../../common/frequent/MultipleSelect";
import RadioButton from "../../../common/frequent/RadioButton";

class Business extends Component {
	handleChange = (name, value) => {
		this.props.handleChange(`business.${name}`, value);
	};
	render() {
		const { business } = this.props;
		const errors = this.props.errors && this.props.errors.errors;

		return (
			<Grid container direction="column">
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={businesssQn}
						name="anyBusiness"
						radioLists={businessRadio}
						value={business.anyBusiness}
					/>
				</Grid>

				<Grid container>
					<MultipleSelect
						error={errors && errors.business}
						question={businessListQuestion}
						name="business"
						value={business.business}
						tagLists={businessLists}
						isVisible={business.anyBusiness === "home_busi_yes"}
						handleChange={this.handleChange}
						other={business.businessOther}
					/>
				</Grid>

				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={herbQn}
						name="anyHerb"
						radioLists={herbRadio}
						value={business.anyHerb}
					/>
				</Grid>

				<Grid container>
					<MultipleSelect
						error={errors && errors.herbs}
						question={herbListQuestion}
						name="herbs"
						value={business.herbs}
						tagLists={herbLists}
						isVisible={business.anyHerb === "herbs_yes"}
						handleChange={this.handleChange}
						other={business.herbsOther}
					/>
				</Grid>

				{/* <Grid container>
					<CheckBox
						question={herbListQuestion}
						name="herbs"
						checkValue={business.check}
						textValue={business.text}
						checkList={loan}
						textList={rate}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid> */}

				{/* <Grid container>
					<TextField
						question={herbListQuestion}
						name="text"
						require
						placeholder="19"
						value={text}
						isVisible={anyHerb === "herbs_yes"}
						handleChange={this.handleChange}
					/>
				</Grid>

				<Grid container>
					<Selection
						question={herbListQuestion}
						name="text"
						selectList={herbLists}
						value={text}
						isVisible={anyHerb === "herbs_yes"}
						handleChange={this.handleChange}
					/>
				</Grid> */}
			</Grid>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing.unit * 3
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
		display: "flex"
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	noLabel: {
		marginTop: theme.spacing.unit * 3
	},
	flexColumn: {
		flexDirection: "column"
	}
});

Business.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Business);
